import React, { useEffect, useRef, useContext, useState, forwardRef } from "react";
import "../widgets/widgetcss/styles.css";
import { MapContext } from "../../../../MapContext";

import { infoLayer } from "../../../../Services/serviceLayers";

import bikeInactive from "../icons/BikeInactive.png";
import carInactive from "../icons/CarInactive.png";
import busInactive from "../icons/BusInactive.png";
import humanInactive from "../icons/HumanInactive.png";


export default function InfoCard () {

    const na = 'N/A'
    const collumn = [ na, na, na, na, na]
    const table = {"Car": collumn, "Bike":collumn, "Pedestrian":collumn, "Bus":collumn}
    const mapContext = useContext(MapContext);
    const [showCard, setShowCard] = useState(true);
    const [infoTableData, setInfoTableData] = useState(table)

    const [currentDate, setCurrentDate] = useState("MMMM.MM.DD")
    const [weekday, setWeekday] = useState("-")

    const infoTitle = [
    "Centrinė zona",
    "Vidurinioji zona",
    "Vilniaus miestas",
    "Periferija ir algomeracija",
    "Vilniaus miestas su aglomeracija"
    ]


    const infoKeys = {
        "{F5D0FB27-9962-4D62-844C-F4DE80C7559D}" : "Periferija ir algomeracija",
        "{4C9A06CC-A857-40A7-BCAF-C4375CE4B44F}" : "Vilniaus miestas",
        "{0BBD4D2A-23F7-4F6C-A808-945C9FCE738E}" : "Vidurinioji zona",
        "{53A3B397-5FFA-48B0-B3BC-CBB4C1E31D5F}":  "Centrinė zona",
        "{05F8EFA6-E42B-4B8F-835D-1AE47157E646}":  "Vilniaus miestas su aglomeracija"
    }

    const infoData = {
        "Centrinė zona" : 0,
        "Vidurinioji zona" : 1,
        "Vilniaus miestas": 2,
        "Periferija ir algomeracija": 3,
        "Vilniaus miestas su aglomeracija": 4
    }


    
    const infoKeysTransport = {
        "0" :"Car",
        "1" :"Bike",
        "2" : "Pedestrian",
        "3" :"Bus"
    }

    const infoCardRef = useRef(null);

    const handleClickOutside = (event) => {
        if (infoCardRef.current && !infoCardRef.current.contains(event.target)) {
        setShowCard(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
        document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        let query = infoLayer.createQuery(); 
        query.where = '1=1'
        query.outFields = ['timestamp']
        query.returnDistinctValues = true
        query.orderByFields = ['timestamp DESC']

        infoLayer.queryFeatures(query).then((result) => {

            const timestamps = result.features.map(feature => feature.attributes.timestamp);
      
            const largestTimestamp = Math.max(...timestamps);

            const date = new Date(largestTimestamp);

            const year = date.getFullYear();
            const month = (date.getMonth() + 1).toString().padStart(2, '0'); 
            const day = date.getDate().toString().padStart(2, '0');
            const formattedDate = `${year}.${month}.${day}`;

            const weekdays = ['Sekmadienis', 'Pirmadienis', 'Antradienis', 'Trečiadienis', 'Ketvirtadienis', 'Penktadienis', 'Šeštadienis'];
            const weekday = weekdays[date.getDay()];

            console.log('Largest timestamp:', largestTimestamp);
            console.log('Formatted date:', formattedDate);
            console.log('Weekday:', weekday);
            setCurrentDate(formattedDate)
            setWeekday(weekday)

            const formattedDatequery = `${year}-${month}-${day}`;
            let timestampQuery = infoLayer.createQuery(); 
            timestampQuery.where =  `timestamp = date'${formattedDatequery}'`
            timestampQuery.outFields = ['*']


            const tableTemp = {"Car": ['N/A', 'N/A', 'N/A', 'N/A', 'N/A'], "Bike":['N/A', 'N/A', 'N/A', 'N/A', 'N/A'], "Pedestrian":['N/A', 'N/A', 'N/A', 'N/A', 'N/A'], "Bus":['N/A', 'N/A', 'N/A', 'N/A', 'N/A']}
            console.log(tableTemp)
            infoLayer.queryFeatures(timestampQuery).then((result) => {
                console.log('timestamp:', result.features);
                let tempTable = tableTemp
                console.log(tableTemp)
                result.features.forEach(element => {
                    const usageType = element.attributes.usageType;
                    const zoneId = element.attributes.zoneId;
                    const usageCount = element.attributes.usageCount;

                    console.log(`Element:`, element);
                    console.log(`Usage Type: ${usageType}`);
                    console.log(`Zone ID: ${zoneId}`);
                    console.log(`Usage Count: ${usageCount}`);

                    if (!tableTemp[usageType]) {
                        tableTemp[usageType] = {};
                    }
                    
                    const infoKey = infoKeys[zoneId];
                    const infoValue = infoData[infoKey];
                    const infoKeyTransport = infoKeysTransport[usageType]

                    console.log(`Info Key: ${infoKey}`);
                    console.log(`Info Value: ${infoValue}`);

                    
                    tableTemp[infoKeyTransport][infoValue] = usageCount;
                    console.log(infoKeyTransport)
                    console.log(tableTemp[infoKeyTransport])
                    console.log(tableTemp)
                });
                console.log(tableTemp)
                setInfoTableData(tableTemp)
                console.log(infoTableData)
            })


        })

        
    },[])
      


    return (
        <>
        {
            showCard === false ? <> </> :
          <div id = "InfoCard"  ref={infoCardRef}>
            <div id = "InfoCardClose" onClick={() => {setShowCard(false)}}>X</div>
            <div id = "InfoCardTitle">JUDUMAS</div>
            <div id = "InfoCardDate">{currentDate
            }
            <br/>
            {weekday}
            </div>
            <table id = "InfoTable">
                <tr>
                    <th>
                    </th>
                    <th>
                        <div id="CarIconInfoCard" className="IconContainer Icon IconTable">
                            <img
                            id="CarIconImgInfoCard"
                            className="ready3 img padding imgInfo"
                            src={carInactive}
                            alt="car"
                            ></img>
                        </div>
                    </th>
                    <th>
                        <div id="BusIconInfoCard" className="IconContainer Icon IconTable">
                            <img
                            id="BusIconImgInfoCard"
                            className="ready3 img padding imgInfo"
                            src={busInactive}
                            alt="bus"
                            ></img>
                        </div>
                    </th>
                    <th>
                        <div id="HumanIconInfoCard" className="IconContainer Icon IconTable">
                            <img
                            id="HumanIconImgInfoCard"
                            className="ready3 img padding imgInfo "
                            src={humanInactive}
                            alt="pedestrian"
                            ></img>
                        </div>
                    </th>
                    <th>
                        <div id="BikeIconInfoCard" className="IconContainer Icon IconTable">
                            <img
                            id="BikeIconImgInfoCard"
                            className="ready3 img padding imgInfo "
                            src={bikeInactive}
                            alt="bike"
                            ></img>
                        </div>
                    </th>
                </tr>
                <tr>
                    <td>
                        {infoTitle[0]}
                    </td>
                    <td className="InfoTableCol">
                        {infoTableData["Car"][0]}
                    </td>
                    <td className="InfoTableCol">
                        {infoTableData["Bus"][0]}
                    </td>
                    <td className="InfoTableCol">
                        {infoTableData["Pedestrian"][0]}
                    </td>
                    <td className="InfoTableCol">
                        {infoTableData["Bike"][0]}
                    </td>
                </tr>
                <tr>
                    <td >
                        {infoTitle[1]}
                    </td>
                    <td className="InfoTableCol">
                        {infoTableData["Car"][1]}
                    </td>
                    <td className="InfoTableCol">
                        {infoTableData["Bus"][1]}
                    </td>
                    <td className="InfoTableCol">
                        {infoTableData["Pedestrian"][1]}
                    </td>
                    <td className="InfoTableCol">
                        {infoTableData["Bike"][1]}
                    </td>
                </tr>
                <tr>
                    <td>
                        {infoTitle[2]}
                    </td>
                    <td className="InfoTableCol">
                        {infoTableData["Car"][2]}
                    </td>
                    <td className="InfoTableCol">
                        {infoTableData["Bus"][2]}
                    </td>
                    <td className="InfoTableCol">
                        {infoTableData["Pedestrian"][2]}
                    </td>
                    <td className="InfoTableCol">
                        {infoTableData["Bike"][2]}
                    </td>
                </tr>
                <tr>
                    <td>
                        {infoTitle[3]}
                    </td>
                    <td className="InfoTableCol">
                        {infoTableData["Car"][3]}
                    </td>
                    <td className="InfoTableCol">
                        {infoTableData["Bus"][3]}
                    </td>
                    <td className="InfoTableCol">
                        {infoTableData["Pedestrian"][3]}
                    </td>
                    <td className="InfoTableCol">
                        {infoTableData["Bike"][3]}
                    </td>
                </tr>
                <tr>
                    <td>
                        {infoTitle[4]}
                    </td>
                    <td className="InfoTableCol">
                        {infoTableData["Car"][4]}
                    </td>
                    <td className="InfoTableCol">
                        {infoTableData["Bus"][4]}
                    </td>
                    <td className="InfoTableCol">
                        {infoTableData["Pedestrian"][4]}
                    </td>
                    <td className="InfoTableCol">
                        {infoTableData["Bike"][4]}
                    </td>
                </tr>
            </table>
          </div>

        } 
        </>
    )
}