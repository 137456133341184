import React from 'react'
import './button.css'
import  './__mocks__/@arcgis/core/widgets/widgetcss/styles.css'
import './Judumas.css'
const dict = {
  "First" : 1,
  "Second" : 2,
  "Third" : 3,
  "Fourth" : 4
}

export default function ButtonApp({HeatMap,DetailedMap,CompareMap,GridMap}) {
  function waitForElm(selector) {
    return new Promise(resolve => {
        if (document.querySelector(selector)) {
            return resolve(document.querySelectorAll(selector));
        }

        const observer = new MutationObserver(mutations => {
            if (document.querySelector(selector)) {
                resolve(document.querySelectorAll(selector));
                observer.disconnect();
            }
        });

        observer.observe(document.body, {
            childList: true,
            subtree: true
        });
    });
}
waitForElm('.MapOption').then((button) => {
    button.forEach(element => {
        element.addEventListener("click",function(){
          button.forEach(nav => 
            {
            console.log(nav.classList[1], this.classList[1])
            if (nav.classList[1] == "Active")
              {
                nav.classList.remove("Active")
                nav.classList.add(this.classList[1])
              }
            })
          this.classList.remove(this.classList[1])
          this.classList.add("Active")
        })
      })
});
  return (
    <div className='s'>
        <button className='MapOption Active' onClick={HeatMap}><p className='MapOptionText'>HEATMAP</p></button>
        <button className='MapOption Second' onClick={DetailedMap}><p className='MapOptionText'>GATVĖS ANALITIKA</p></button>
        <button className='MapOption Third' onClick={CompareMap}><p className='MapOptionText'>PALYGINIMAS</p></button>
        <button className='MapOption Fourth' onClick={GridMap}><p className='MapOptionText'>PASIEKIAMUMAS</p></button>
    </div>

    
  )
}
