import React, {useContext, useState, useEffect} from 'react'
import "../../../../Judumas.css"
import { MapContext } from '../../../../MapContext';
import { queryFeaturesOverLimit } from '../../../../helpers/helper';
import { Readable } from 'stream';
import { read, utils, writeFile } from 'xlsx';
import * as featureService from "../../../../Services/serviceLayers";

export default function ExcelExport() {
  const mapContext = useContext(MapContext);  
  const [excelData, setExcelData] = useState(null);

  function createQuery(idArray, compareDate = 0, osmLineQuer = 0 ) {
    let queryWhere
    if (osmLineQuer === 1) {
        queryWhere = "GlobalID IN ('"
    } else {
        queryWhere = "parent_global_id IN ('"
    }
    idArray.forEach(element => {

      console.log(element, typeof element)
      if (element !== undefined && element !== null){
        queryWhere += element + "','"
      }   
    })
    queryWhere = queryWhere.slice(0,-2)
    if (osmLineQuer === 1 ) {
        queryWhere += ")"
    }
    else if (compareDate === 0) {
      queryWhere += ") and " + mapContext.state.dateRange;
    } else {
      queryWhere += ") and " + mapContext.state.compareDate;
    }
   

    var query = mapContext.state.dataFeatureLayer.createQuery();
    query.where = queryWhere;

    return query 
  }

  function mergeArr(dataArr, osmArr) {
    dataArr.forEach(element => {
        osmArr.forEach(osmElement => {
            if (element.parent_global_id === osmElement.GlobalID) {
                Object.assign(element, osmElement);
            }
        })
    });
  }

  function addTransportType (layer, element) {
    if (layer === 101) {
        element.transport_type = "Automobilis"
        return element
    } else if (layer === 102) {
        element.transport_type = "Dviratis"
        return element
    } else if (layer === 104) {
        element.transport_type = "Viešasis transportas"
        return element
    } else if (layer === 103) {
        element.transport_type = "Pestieji"
        return element
    }
}
  
async  function formatDataTer() {
    let l1Query
    let l2Query
    let l1osmQuery
    let l2osmQuery
    console.log(mapContext.state.selectedStreetArray[0])
    if (mapContext.state.selectedStreetArray.length > 1) {
      l1Query = createQuery(mapContext.state.selectedStreetArray[1])
      l2Query = createQuery(mapContext.state.selectedStreetArray[1])
      l1osmQuery = createQuery(mapContext.state.selectedStreetArray[1], 0, 1)
      l2osmQuery = l1osmQuery
      console.log(l1Query.where)
      console.log(l2Query.where)
   } else {
     return;
   }

    let arrDataAll = []
    let arrL1data = []
    let arrL2data = []
    let arrL1osm = []
    let arrL2osm = []
    let arrL1 = []
    let arrL2 = []
    let osmLayer = featureService.operator
    // support for all types
    let featuresOSM = await queryFeaturesOverLimit(osmLayer, l1osmQuery)
        //console.log(features)
        featuresOSM.forEach(element => {
            delete element.attributes.created_at
            delete element.attributes.updated_at
            arrL1osm.push(element.attributes)
        });
        
        //console.log(arrA1data)
        
    
    for ( let i = 0; i < mapContext.state.compareLayers.length; i++) {
        let features = await queryFeaturesOverLimit(mapContext.state.compareLayers[i], l1Query)
            if (features.length === 0) {
              return
            }
            arrDataAll.push([])
            features.forEach(element => {
            
                arrDataAll[i].push(addTransportType(element.layer.layerId, element.attributes))
                //arrL1data.push()
            });
        mergeArr( arrDataAll[i], arrL1osm)
        // Pabaigt perdaryt supporta visiems tipams
    }
    setExcelData(arrDataAll)
    // support for all types
    /*
    queryFeaturesOverLimit(mapContext.state.compareLayers[1], l1Query).then((features) => {
        console.log(features)
        features.forEach(element => {
            
            arrL1data.push(addTransportType(element.layer.layerId, element.attributes))
            //arrL1data.push()
        });
        queryFeaturesOverLimit(mapContext.state.compareLayers[0], l2Query).then((features) => {
            console.log(features)
            features.forEach(element => {
                //addTransportType(element.layer.layerId, element)
                arrL2data.push(addTransportType(element.layer.layerId, element.attributes))
                
            });
            queryFeaturesOverLimit(osmLayer, l1osmQuery).then((features) => {
                //console.log(features)
                features.forEach(element => {
                    delete element.attributes.created_at
                    delete element.attributes.updated_at
                    arrL1osm.push(element.attributes)
                });
                mergeArr(arrL1data, arrL1osm)
                //console.log(arrA1data)
                queryFeaturesOverLimit(osmLayer, l2osmQuery).then((features) => {
                    //console.log(features)
                    features.forEach(element => {
                        delete element.attributes.created_at
                        delete element.attributes.updated_at
                        arrL2osm.push(element.attributes)
                    });
                    mergeArr(arrL2data, arrL2osm)
                    
                    arrL1 = arrL1data
                    arrL2 = arrL2data
                    setExcelData([arrL1, arrL2])
                })
            })
        })
    }) */
  }
  function formatDataTime()
  {
    
    let l1Query
    let l2Query
    let l1osmQuery
    let l2osmQuery
    console.log(mapContext.state.selectedStreetArray[0])
    if (mapContext.state.selectedStreetArray.length > 1) {
      l1Query = createQuery(mapContext.state.selectedStreetArray[1])
      l2Query = createQuery(mapContext.state.selectedStreetArray[1], 1)
      l1osmQuery = createQuery(mapContext.state.selectedStreetArray[1], 0, 1)
      l2osmQuery = l1osmQuery
      console.log(l1Query.where)
      console.log(l2Query.where)
   } else {
     return;
   }

    let arrL1data = []
    let arrL2data = []
    let arrL1osm = []
    let arrL2osm = []
    let arrL1 = []
    let arrL2 = []
    let osmLayer = featureService.operator
    queryFeaturesOverLimit(mapContext.state.dataFeatureLayer, l1Query).then((features) => {
        console.log(features)
        features.forEach(element => {
            arrL1data.push(element.attributes)
        });
        queryFeaturesOverLimit(mapContext.state.dataFeatureLayer, l2Query).then((features) => {
            console.log(features)
            features.forEach(element => {
                arrL2data.push(element.attributes)
            });
            queryFeaturesOverLimit(osmLayer, l1osmQuery).then((features) => {
                //console.log(features)
                features.forEach(element => {
                    delete element.attributes.created_at
                    delete element.attributes.updated_at
                    arrL1osm.push(element.attributes)
                });
                mergeArr(arrL1data, arrL1osm)
                //console.log(arrA1data)
                queryFeaturesOverLimit(osmLayer, l2osmQuery).then((features) => {
                    //console.log(features)
                    features.forEach(element => {
                        delete element.attributes.created_at
                        delete element.attributes.updated_at
                        arrL2osm.push(element.attributes)
                    });
                    mergeArr(arrL2data, arrL2osm)
                    
                    arrL1 = arrL1data
                    arrL2 = arrL2data
                    setExcelData([arrL1, arrL2])
                })
            })
        })
    })
  }

  async function formatDataStr()
  {
    let a1Query
    let a2Query
    let a1osmQuery
    let a2osmQuery
    if (mapContext.state.selectedStreetArray.length > 1) {
       a1Query = createQuery(mapContext.state.selectedStreetArray[0])
       a2Query = createQuery(mapContext.state.selectedStreetArray[1])
       a1osmQuery = createQuery(mapContext.state.selectedStreetArray[0], 0, 1)
       a2osmQuery = createQuery(mapContext.state.selectedStreetArray[1], 0, 1)
       console.log(a1Query)
       console.log(a2Query)
    } else {
      return;
    }

    let arrA1data = []
    let arrA2data = []
    let arrA1osm = []
    let arrA2osm = []
    let arrA1 = []
    let arrA2 = []
    let osmLayer = featureService.operator
    queryFeaturesOverLimit(mapContext.state.dataFeatureLayer, a1Query).then((features) => {
        console.log(features)
        features.forEach(element => {
            arrA1data.push(element.attributes)
        });
        queryFeaturesOverLimit(mapContext.state.dataFeatureLayer, a2Query).then((features) => {
            console.log(features)
            features.forEach(element => {
                arrA2data.push(element.attributes)
            });
            queryFeaturesOverLimit(osmLayer, a1osmQuery).then((features) => {
                //console.log(features)
                features.forEach(element => {
                    delete element.attributes.created_at
                    delete element.attributes.updated_at
                    arrA1osm.push(element.attributes)
                });
                mergeArr(arrA1data, arrA1osm)
                //console.log(arrA1data)
                queryFeaturesOverLimit(osmLayer, a2osmQuery).then((features) => {
                    //console.log(features)
                    features.forEach(element => {
                        delete element.attributes.updated_at
                        delete element.attributes.created_at
                        arrA2osm.push(element.attributes)
                    });
                    mergeArr(arrA2data, arrA2osm)
                    
                    arrA1 = arrA1data
                    arrA2 = arrA2data
                    setExcelData([arrA1, arrA2])
                })
            })
        })
    })
}

function download() {
    
    // Prepare data for excel.You can also use html tag for create table for excel.
    if (mapContext.state.compareTask === 3) {
        formatDataTer()
    } else if (mapContext.state.compareTask === 2) {
        formatDataTime()
    } else  {
        formatDataStr().then((queriedData) => {
            console.log(queriedData)
        })
    }

    
  }

  function calculateDate(dateStr) {
    console.log(dateStr)
    if (parseInt(dateStr.substring(2,4)) < 30) {
        if (parseInt(dateStr.substring(2,4)) !== 28) {
            return dateStr
        } else if (parseInt(dateStr.substring(2,4)) === 28 && parseInt(dateStr.substring(0,2)) === 2 ) {
            return "0301" + dateStr.substring(4,8)
        }
    } else {
        if (parseInt(dateStr.substring(2,4)) === 30) {
            let parsedMonth = parseInt(dateStr.substring(0,2))
            if ([4,6,9,11].includes(parsedMonth)) {
                parsedMonth++
                if (parsedMonth  < 10) {
                    return "0" + parsedMonth  + "01" + dateStr.substring(4,8)
                } else {
                    return parsedMonth  + "01" + dateStr.substring(4,8)
                }
            }
        } else if (parseInt(dateStr.substring(2,4)) === 31) {
            let parsedMonth = parseInt(dateStr.substring(0,2))
            console.log(parsedMonth)
            if ([1,3,5,7,8,10].includes(parsedMonth)) {
                if ((parsedMonth + 1) < 10) {
                    return "0" + (parsedMonth + 1) + "01" + dateStr.substring(4,8)
                } else {
                    return (parsedMonth + 1) + "01" + dateStr.substring(4,8)
                }
            } else if ((parsedMonth + 1) === 13) {
                return "01" + "01" + (parseInt(dateStr.substring(4,8)) + 1)
            }
        }
    }
  }

  useEffect(() => {
    if (excelData === null) {
        return
    }
    let data
    if (mapContext.state.compareTask === 3) {
        data = [
            [ 'Atkarpos unikalus ID', 'Gatvės pavadinimas', 'Tipas', 'Srauto dydis', 'Transporto tipas' , 'Duomenų užkelimo data', 'Duomenų atnaujinimo data', 'Srauto fiksavimo data ir laikas' ] // This is your header.
            
        ];
    } else {
        data = [
            [ 'Atkarpos unikalus ID', 'Gatvės pavadinimas', 'Tipas', 'Srauto dydis', 'Duomenų užkelimo data', 'Duomenų atnaujinimo data', 'Srauto fiksavimo data ir laikas' ] // This is your header.
            
        ];
    }
    let excelDataFoo = '';

    if (mapContext.state.compareTask === 3) {
        for (let i = 0; i < mapContext.state.compareLayers.length ; i ++) {
            excelData[i].forEach((element) => {
                let date =  new Date(element.datetime)
                data.push([element.OBJECTID, element.name, element.highway, element.usage, element.transport_type, new Date(element.created_at), new Date(element.updated_at), date.toString()])
            })
        }
    } else 

    {
        excelData[0].forEach((element) => {
        console.log(element)

            let date =  new Date(element.datetime)
            data.push([element.OBJECTID, element.name, element.highway, element.usage, new Date(element.created_at), new Date(element.updated_at), date.toString()])

       
        })
        excelData[1].forEach((element) => {

            let date =  new Date(element.datetime)
            data.push([element.OBJECTID, element.name, element.highway, element.usage, new Date(element.created_at), new Date(element.updated_at), date.toString()])

        })
    }

    data.forEach(( rowItem, rowIndex ) => {   
        
        if (0 === rowIndex) {
            // This is for header.
        rowItem.forEach((colItem, colIndex) => {
            excelDataFoo += colItem + ',';
        });
        excelDataFoo += "\r\n";
        } else {
            // This is data.
            rowItem.forEach((colItem, colIndex) => {
                excelDataFoo += colItem + ',';   
        })
        excelDataFoo += "\r\n";       
        }
    });

    excelDataFoo = "data:text/xlsx," + encodeURI(excelDataFoo);
    // Download the xlsx file.
    let a = document.createElement("A");
    a.setAttribute("href", excelDataFoo);
    a.setAttribute("download", "filename.csv");
    document.body.appendChild(a);
    //a.click();

    var ws = utils.aoa_to_sheet(data)
    var wb = utils.book_new();
    
    utils.book_append_sheet(wb, ws, "Palyginimas");
    let transport = ""
    if (mapContext.state.compareTask === 3) {
        for ( let i = 0; i < mapContext.state.compareLayers.length; i++) {
            if (mapContext.state.compareLayers[i].layerId === 101) {
                transport += "_AUTO"
              }
              if (mapContext.state.compareLayers[i].layerId === 102) {
                transport += "_DV"
              }
              if (mapContext.state.compareLayers[i].layerId === 104) {
                transport += "_VT"
              }
              if (mapContext.state.compareLayers[i].layerId === 103) {
                transport += "_PST"
              }
        }
    }
    else if (mapContext.state.transportation === "Car") {
        transport = "_AUTO"
    } else if (mapContext.state.transportation === "Bike") {
        transport = "_DV"
    } else if (mapContext.state.transportation === "Pedestrian") {
        transport = "_PST"
    } else if (mapContext.state.transportation === "Bus") {
        transport = "_VT"
    }
    //"datetime >= timestamp'09/04/2023 23:59:59' and datetime < timestamp'09/09/2023 23:59:59'"
    let dateStartIncorrect = mapContext.state.dateRange.substring(22, 26).replaceAll('/', '') +  (mapContext.state.dateRange.substring(26, 27)) + mapContext.state.dateRange.substring(27, 32).replaceAll('/', '')
    let dateStart = calculateDate(dateStartIncorrect)
    let date = dateStart + "_" + mapContext.state.dateRange.substring(68, 78).replaceAll('/', '')
    let nameStr = "export_" + date + transport + ".csv"
    writeFile(wb, nameStr);
  }, [excelData])
  return (
    <button className="NonActive LengthBtn MarginBtn" id="exportBtn" onClick={() => {download()}}>Atsisiųsti</button>
  )
}
