import React, { useContext } from "react";

import bikeInactive from "../icons/BikeInactive.png";
import bikeActive from "../icons/BikeActive.png";
import bikeDisabled from "../icons/BikeDisabled.png";

import carInactive from "../icons/CarInactive.png";
import carActive from "../icons/CarActive.png";
import carDisabled from "../icons/CarDisabled.png";

import busInactive from "../icons/BusInactive.png";
import busActive from "../icons/BusActive.png";
import busDisabled from "../icons/BusDisabled.png";

import humanInactive from "../icons/HumanInactive.png";
import humanActive from "../icons/HumanActive.png";
import humanDisabled from "../icons/HumanDisabled.png";

import "../widgets/widgetcss/styles.css";
import "../../../../Judumas.css"
import { existingHeatmapDataSource } from "../../../../config";

import { MapContext } from "../../../../MapContext";

export default function IconWidget({ handleBike, handleCar, handleBus, handleHuman, availData, heatmapOp }) {
  const mapContext = useContext(MapContext);

  function waitForElm(selector) {
    return new Promise((resolve) => {
      if (document.querySelector(selector)) {
        return resolve(document.querySelectorAll(selector));
      }

      const observer = new MutationObserver((mutations) => {
        if (document.querySelector(selector)) {
          resolve(document.querySelectorAll(selector));
          observer.disconnect();
        }
      });

      observer.observe(document.body, {
        childList: true,
        subtree: true,
      });
    });
  }
  waitForElm(".ready3").then((button) => {
    button.forEach((element) => {
      element.addEventListener("click", function () {
        console.log(element.classList.contains("IconTable"))
        if (element.classList.contains("IconTable") === true) {
          return
        }
        button.forEach((nav) => nav.classList.remove("active"));
        this.classList.add("active");
      });
    });
  });

  waitForElm(".Icon").then((button) => {
    button.forEach((element) => {
      element.addEventListener("click", function () {
          console.log(element.classList.contains("IconTable"))
          if (element.classList.contains("IconTable") === true) {
            return
          }
        // Ismest kai turesim autobusus
       // if (element.id !== "BusIcon") {
          button.forEach((nav) => nav.classList.remove("Active"));
          this.classList.add("Active");

      //  }
      });
    });
  });

  function transportationChange(transportationContext) {
    if (mapContext.state.transportation == transportationContext || transportationContext === null) return;
    mapContext.state.featureLayer.visible = false;

    mapContext.dispatch({
      transportation: transportationContext,
    });
    
    if (heatmapOp === true) {
      mapContext.dispatch({
        data: "operator",
      });
      return 
    }
    for (let i = 0; i < existingHeatmapDataSource.length; i++) {
      console.log("test", transportationContext.toLowerCase(), existingHeatmapDataSource[i][0]);
      if (transportationContext.toLowerCase() === existingHeatmapDataSource[i][0].toLowerCase()) {
        if (existingHeatmapDataSource[i].includes(mapContext.state.data)) {
        } else {
          mapContext.dispatch({
            data: existingHeatmapDataSource[i][1],
          });
        }
      }
    }
    
  }

  function isActive(transport) {
    //console.log(mapContext.state.transportation);
    let transportObject = {
      Bike: [bikeActive, bikeInactive],
      Car: [carActive, carInactive],
      Bus: [busActive, busInactive],
      Pedestrian: [humanActive, humanInactive],
    };
    return mapContext.state.transportation === transport ? transportObject[transport][0] : transportObject[transport][1];
  }

  return (
    <div className="IconWidget">
      {"Pasirinkite judėjimo būdą:"}
      <br />
      <div id="BikeIcon" className={availData.includes("Bike") ? "IconContainer Icon" : "IconContainer Icon Disabled"} onClick={() => {
        transportationChange(handleBike);
      }}>
        <img
          id="BikeIconImg"
          className={availData.includes("Bike") ? "ready3 img padding  " : "notready3 img padding  "}
          src={availData.includes("Bike") ? isActive("Bike") : bikeDisabled}
          alt="bike"

        ></img>
      </div>

      <div id="BusIcon" className={availData.includes("Bus") ? "IconContainer Icon" : "IconContainer Icon Disabled"}>
        <img
          id="BusIconImg"
          className={availData.includes("Bus") ? "ready3 img padding  " : "notready3 img padding  "}
          src={availData.includes("Bus") ? isActive("Bus") : busDisabled}
          alt="bus"
          onClick={() => {
            transportationChange(handleBus);
          }}
        ></img>
      </div>
      <br />
      <div id="CarIcon" className="IconContainer Icon Active" onClick={() => {
        transportationChange(handleCar);
      }}>
        <img
          id="CarIconImg"
          className={availData.includes("Car") ? "ready3 img padding  " : "notready3 img padding  "}
          src={availData.includes("Car") ? isActive("Car") : bikeDisabled}
          alt="car"

        ></img>
      </div>

      <div id="HumanIcon" className={availData.includes("Human") ? "IconContainer Icon" : "IconContainer Icon Disabled"} onClick={() => {
        transportationChange(handleHuman);
      }}>
        <img
          id="HumanIconImg"
          className={availData.includes("Human") ? "ready3 img padding  " : "notready3 img padding  "}
          src={availData.includes("Human") ? isActive("Pedestrian") : humanDisabled}
          alt="pedestrian"

        ></img>
      </div>
    </div>
  );
}
