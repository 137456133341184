import React, { useEffect, useContext, useState} from "react";
import { MapContext } from "../../../../MapContext";
import "../../../../Judumas.css"
import legend from "../icons/legend.png"


export default function Legend () {
    const mapContext = useContext(MapContext);
    const [isExpanded, setIsExpanded] = useState(0)
    const [dateEnd, setDateEnd] = useState(null);
/*
{ value: 0, color: "#FFFFb2" },
          { value: localMax * 0.1, color: "#fecc5c" },
          { value: localMax * 0.3, color: "#fd8d3c" },
          { value: localMax * 0.6, color: "#f03b20" },
          { value: localMax * 0.9, color: "#bd0026" },
*/

    useEffect(() => {
        if (isExpanded === true){
            let canvas = document.getElementById("LegendCanvasId1");
            let ctx = canvas.getContext("2d");

            ctx.fillStyle  = "#FFFFb2";  // t1
            ctx.fillRect(10, 12, 250, 9);

            let canvas2 = document.getElementById("LegendCanvasId2");
            let ctx2 = canvas2.getContext("2d");

            ctx2.fillStyle  = "#fecc5c";  // t2
            ctx2.fillRect(10, 12, 250, 13);

            let canvas3 = document.getElementById("LegendCanvasId3");
            let ctx3 = canvas3.getContext("2d");

            ctx3.fillStyle  = "#fd8d3c";  // t3
            ctx3.fillRect(10, 12, 250, 18);

            let canvas4 = document.getElementById("LegendCanvasId4");
            let ctx4 = canvas4.getContext("2d");

            ctx4.fillStyle  = "#f03b20";  // t4
            ctx4.fillRect(10, 12, 250, 22);

            let canvas5 = document.getElementById("LegendCanvasId5");
            let ctx5 = canvas5.getContext("2d");

            ctx5.fillStyle  = "#bd0026";  // t5
            ctx5.fillRect(10, 12, 250, 40);

            let query =  mapContext.state.featureLayer.createQuery();
            query.where = "1=1";
            
            mapContext.state.featureLayer.queryFeatures(query).then((results) => {
                if ( results.features[0].attributes.date !== null && results.features[0].attributes.date !== undefined)
                {
                let date = new Date(results.features[0].attributes.date)
                let month = (date.getMonth() + 1).toString()
                month.length < 2 ? month = "0"+ month : month = month

                let day = date.getDate().toString()
                day.length < 2 ? day = "0"+ day : day = day
                if (mapContext.state.time === "Day"){
                    setDateEnd(date.getFullYear()+ "/" + month + "/" + day)
                }
                
                if (mapContext.state.time === "Week") {
                    let prevWeekDate = new Date(date - 604800000)
                    let prevMonth = (prevWeekDate.getMonth() + 1).toString()
                    prevMonth.length < 2 ? prevMonth = "0"+ prevMonth : prevMonth = prevMonth
                    prevMonth === "0" ? prevMonth = "01" : prevMonth = prevMonth
                    let prevDay = prevWeekDate.getDate().toString()
                    prevDay.length < 2 ? prevDay = "0"+ prevDay : prevDay = prevDay

                    setDateEnd(prevWeekDate.getFullYear()+ "/" + prevMonth + "/" + prevDay + "-" + date.getFullYear()+ "/" + month + "/" + day) 
                } else if (mapContext.state.time === "Month") {
                    let prevMonth 
                    let prevYear
                    if (month === "01") {
                        prevMonth = "12"
                        prevYear = (date.getFullYear() - 1).toString()
                    } else {
                        prevMonth = date.getMonth().toString()
                        prevMonth.length < 2 ? prevMonth = "0" + prevMonth : prevMonth = prevMonth
                        prevYear = (date.getFullYear()).toString()
                    }
                    setDateEnd(prevYear+ "/" + prevMonth + "/" + day + "-" + date.getFullYear()+ "/" + month + "/" + day) 
                } else if (mapContext.state.time === "Year") {
                    setDateEnd((date.getFullYear() - 1)+ "/" + month + "/" + day + "-" + date.getFullYear()+ "/" + month + "/" + day)
                }

                }else {
                    setDateEnd(null)
                }
            })


        }
    }, [isExpanded, mapContext.state.featureLayer])

    return (
        <div className={isExpanded ?"LegendContainer" : "LegendBtn"  } onClick={() => {setIsExpanded(!isExpanded)}}>
            
            {isExpanded ? 
            <>
            <div id="LegendaTitle">
                Legenda
                <br/>
                {dateEnd !== null? dateEnd : "" }
            </div>
            <table className="LegendContainerTable">
                <tr className="LegendContainerTableRowTop">
                <div id="LegendCanvasTitle">Linija</div>
                <div id="LegendRangesTitle">Srautas</div>
                </tr>
                <tr className="LegendContainerTableRow">
                <canvas id="LegendCanvasId1" className="LegendCanvasClass"/>
                {"0 - " + (0.1 * mapContext.state.maxValue).toFixed()}
                </tr>
                <tr className="LegendContainerTableRow">
                <canvas id="LegendCanvasId2" className="LegendCanvasClass"/>
                {(0.1 * mapContext.state.maxValue).toFixed() + " - " + (0.3 * mapContext.state.maxValue).toFixed()}
                </tr>
                <tr className="LegendContainerTableRow">
                <canvas id="LegendCanvasId3" className="LegendCanvasClass"/>
                {(0.3 * mapContext.state.maxValue).toFixed() + " - " + (0.6 * mapContext.state.maxValue).toFixed()}
                </tr>
                <tr className="LegendContainerTableRow">
                <canvas id="LegendCanvasId4" className="LegendCanvasClass"/>
                {(0.6 * mapContext.state.maxValue).toFixed() + " - " + (0.9 * mapContext.state.maxValue).toFixed()}

                </tr>
                <tr className="LegendContainerTableRow"> 

                <canvas id="LegendCanvasId5" className="LegendCanvasClass"/>
                {(0.9 * mapContext.state.maxValue).toFixed() + " - " + (mapContext.state.maxValue).toFixed()}

                </tr>
                <tr/>
            </table>
            
                




            

            </>
            :
            <img id="legendIMG" src={legend}>
            </img>}
        </div>
    ) 
}