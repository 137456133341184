import React, { useEffect, useContext, useState} from "react";
import { MapContext } from "../../../../MapContext";
import "../../../../Judumas.css"
import legend from "../icons/legend.png"


export default function LegendSISP (Year, Month) {
    const mapContext = useContext(MapContext);
    const [isExpanded, setIsExpanded] = useState(0)
    const [dateEnd, setDateEnd] = useState(null);
/*
{ value: 0, color: "#FFFFb2" },
          { value: localMax * 0.1, color: "#fecc5c" },
          { value: localMax * 0.3, color: "#fd8d3c" },
          { value: localMax * 0.6, color: "#f03b20" },
          { value: localMax * 0.9, color: "#bd0026" },
*/

    useEffect(() => {
        if (isExpanded === true){
            let canvas = document.getElementById("LegendCanvasId1");
            let ctx = canvas.getContext("2d");

            ctx.fillStyle  = "#38a800";  // t1
            ctx.fillRect(10, 12, 250, 9);

            let canvas2 = document.getElementById("LegendCanvasId2");
            let ctx2 = canvas2.getContext("2d");

            ctx2.fillStyle  = "#55ff00";  // t2
            ctx2.fillRect(10, 12, 250, 13);

            let canvas3 = document.getElementById("LegendCanvasId3");
            let ctx3 = canvas3.getContext("2d");

            ctx3.fillStyle  = "#ffaa00";  // t3
            ctx3.fillRect(10, 12, 250, 18);

            let canvas4 = document.getElementById("LegendCanvasId4");
            let ctx4 = canvas4.getContext("2d");

            ctx4.fillStyle  = "#ff0000";  // t4
            ctx4.fillRect(10, 12, 250, 22);

            let canvas5 = document.getElementById("LegendCanvasId5");
            let ctx5 = canvas5.getContext("2d");

            ctx5.fillStyle  = "#bd0026";  // t5
            ctx5.fillRect(10, 12, 250, 40);



           console.log(Year, Month)



        }
    }, [isExpanded, mapContext.state.featureLayer])

    return (
        <div className={isExpanded ?"LegendContainer" : "LegendBtn"  } onClick={() => {setIsExpanded(!isExpanded)}}>
            
            {isExpanded ? 
            <>
            <div id="LegendaTitle">
                Legenda
                <br/>
                {Year.Year + "/" + Year.Month}
            </div>
            <table className="LegendContainerTable">
                <tr className="LegendContainerTableRowTop">
                <div id="LegendCanvasTitle">Linija</div>
                <div id="LegendRangesTitle">Srautas</div>
                </tr>
                <tr className="LegendContainerTableRow">
                <canvas id="LegendCanvasId1" className="LegendCanvasClass"/>
                {"1 - 4000"}
                </tr>
                <tr className="LegendContainerTableRow">
                <canvas id="LegendCanvasId2" className="LegendCanvasClass"/>
                {"4001 - 8000"}
                </tr>
                <tr className="LegendContainerTableRow">
                <canvas id="LegendCanvasId3" className="LegendCanvasClass"/>
                {"8001 - 13000"}
                </tr>
                <tr className="LegendContainerTableRow">
                <canvas id="LegendCanvasId4" className="LegendCanvasClass"/>
                {"13001 - 20000"}

                </tr>
                <tr className="LegendContainerTableRow"> 

                <canvas id="LegendCanvasId5" className="LegendCanvasClass"/>
                {" > 20000"}

                </tr>
                <tr/>
            </table>
            
                




            

            </>
            :
            <img id="legendIMG" src={legend}>
            </img>}
        </div>
    ) 
}