import React, { useState, useContext, useEffect } from "react";
import { MapContext } from "../../../../MapContext";

import "../widgets/widgetcss/styles.css";
import "../../../../Judumas.css"


export default function HideSidebar({text}) {
    const [hideSidebar, setHideSidebar] = useState(1);

    const mapContext = useContext(MapContext);

    const hideSidebarFunction = () => {
        setHideSidebar(!hideSidebar)
        if (text === "Heatmap") {
            if (hideSidebar) {
                document.getElementById("SidebarGridHeatmap").classList.remove('Sidebar');
                document.getElementById("SidebarGridHeatmap").classList.add('SidebarHidden');
                
                document.getElementById("HideSidebarHeatmapId").classList.remove('HideSidebarHeatmap');
                document.getElementById('HideSidebarBtn').style.cssText = 'right: 0px; top: 24.1vh';
            } else {

                document.getElementById("SidebarGridHeatmap").classList.remove('SidebarHidden');
                document.getElementById("HideSidebarHeatmapId").classList.add('HideSidebarHeatmap');
    
                document.getElementById('HideSidebarBtn').style.cssText = 'right: 25.5vw; top: 44.1vh';
            }
            return
        }
        if (mapContext.state.compareTask !== 0 && hideSidebar) {
            
            document.getElementById("CompareCardWrapper").classList.add('SidebarHidden');
            if (mapContext.state.contextDataVisible === false) {
                document.getElementById("CompareCardWrapper").classList.add('SidebarHidden');
            } else {
                document.getElementById("SidebarGridSidebar").classList.add('SidebarHidden');
                
            }
            
            

        } else if (mapContext.state.compareTask !== 0){
            document.getElementById("CompareCardWrapper").classList.remove('SidebarHidden');
        }
        if (hideSidebar) {
            document.getElementById("CompareCardWrapper").classList.add('SidebarHidden');
            if (mapContext.state.contextDataVisible === false) {
                document.getElementById("AnalyticsSidebar").classList.remove('Sidebar');
                document.getElementById("AnalyticsSidebar").classList.add('SidebarHidden');
            } else {
                document.getElementById("SidebarGridSidebar").classList.remove('Sidebar');
                document.getElementById("SidebarGridSidebar").classList.add('SidebarHidden');
                //document.getElementById("SidebarGridSidebar").classList.add('SidebarHidden');
                
            }
            
            var element = document.getElementById("searchBtn");
            if (typeof(element) != 'undefined' && element != null) {
                    element.classList.add("searchBtnXp");
            }
            
            document.getElementsByClassName("esri-search")[0].style.width = "240px"
            document.getElementsByClassName("esri-search__input-container")[0].style.display = "block"
            document.getElementsByClassName("esri-search")[0].onclick = null
            

            document.getElementById('HideSidebarBtn').style.cssText = 'right: 0px';
        } else {
            if (mapContext.state.contextDataVisible === false) {
                document.getElementById("AnalyticsSidebar").classList.remove('SidebarHidden');
                document.getElementById("AnalyticsSidebar").classList.add('Sidebar');
            } else {
                document.getElementById("SidebarGridSidebar").classList.remove('SidebarHidden');
                
            }
            document.getElementById("CompareCardWrapper").classList.remove('SidebarHidden');
            

            
            
            var element = document.getElementById("searchBtn");
            if (typeof(element) != 'undefined' && element != null) {
                element.classList.remove("searchBtnXp");
            }
            document.getElementsByClassName("esri-search")[0].style.width = "40px"
            document.getElementsByClassName("esri-search__input-container")[0].style.display = "none"  
            document.getElementsByClassName("esri-search")[0].onclick = () => {
                mapContext.dispatch({
                  searchFlag: 1,
                });        
              }   

            document.getElementById('HideSidebarBtn').style.cssText = 'right: 25.5vw';
        }
    }

    useEffect(() => {
        if (mapContext.state.searchFlag === 1) {
            mapContext.dispatch({
                searchFlag: 0,
            });  
            hideSidebarFunction()
        }

    },[mapContext.state.searchFlag])

    useEffect(() => {
        if (text === "Heatmap") {

            hideSidebarFunction()
        }

    },[])


    return (
        <div id="HideSidebarBtn" onClick={() => { hideSidebarFunction() }}>
            <div id="VerticalTxt"> {hideSidebar ? "Paslėpti " : "Rodyti "}</div>

        </div>
    );
}
