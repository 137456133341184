export const redirectURLLocal = "http://localhost:3000/"
export const redirectURLProd = "https://judumas.vilniausplanas.lt/"
export const clientID = "8uv41Zw4eC6IudPS"
export const redirectURLDev = "https://devjudumas.z6.web.core.windows.net/"

export const existingHeatmapDataSource = [
    ["Bike", "strava"],
    ["Pedestrian", "strava"],
    ["Car", "operator"],
    ["Bus", "operator"],
]