import CIMSymbol from "@arcgis/core/symbols/CIMSymbol.js";
import SimpleFillSymbol from "@arcgis/core/symbols/SimpleFillSymbol.js";

export const renderPointSchoolPre = () => {
    return {
      type: "simple",
      symbol: {
        size: "9px",
        color: "#FFFFb2",
        type: "simple-marker",
        style: "circle",
      }
    };
};

export const renderPointSchoolElementary = () => {
    return {
      type: "simple",
      symbol: {
        size: "12px",
        color: "#FaFFb6",
        type: "simple-marker",
        style: "circle",
      }
    };
};

export const renderPointSchoolBasic = () => {
    return {
      type: "simple",
      symbol: {
        size: "15px",
        color: "#FFaFb9",
        type: "simple-marker",
        style: "circle",
      }
    };
};

export const renderPointSchoolHigher = () => {
    return {
      type: "simple",
      symbol: {
        size: "18px",
        color: "#FFFabb",
        type: "simple-marker",
        style: "circle",
      }
    };
};


export const renderHospital = () => {
    return {
      type: "simple",
      symbol: {
        size: "15px",
        color: "#ff222f",
        type: "simple-marker",
        style: "cross",
      }
    };
};

export const renderDentist = () => {
    return {
      type: "simple",
      symbol: {
        size: "10px",
        color: "#4a4a66",
        type: "simple-marker",
        style: "cross",
      }
    };
};

export const renderPublicOffice = () => {
    return {
      type: "simple",
      symbol: {
        size: "18px",
        color: "#2b2bff",
        type: "simple-marker",
        style: "square",
      }
    };
};

export const renderWebOffice = () => {
    return {
      type: "simple",
      symbol: {
        name: "bus-station",
        styleUrl: "https://opencity.vplanas.lt/server/rest/services/Hosted/VDA_2023_m_veikiantys_ukio_subjektai__Ikimokyklinis_ugdymas/FeatureServer/30",
        type: "web-style",

      }
    };
};

export const renderBusStop = () => {
    return {
      type: "simple",
      symbol: {
        name: "bus-station",
        styleName: "Esri2DPointSymbolsStyle",
        type: "web-style",

      }
    };
};

export const renderCimBusStop = () => {
    return {
      type: "simple",
      symbol: cimBusSymbol
    };
};

const fillSymbol = new SimpleFillSymbol({
  style: "cross", 
  color: "#6677CD", 
  outline: {
    color: "#B4181F",
    width: 2.5
  }
});


export const blackSpotsRenderer = {

    type: "simple",
    symbol: fillSymbol
  
}



const cimSymbol = new CIMSymbol({
  "data": {
    "type": "CIMSymbolReference",
    "symbol": {
      "type": "CIMPointSymbol",
      "symbolLayers": [
        {
          "type": "CIMVectorMarker",
          "enable": true,
          "anchorPointUnits": "Relative",
          "dominantSizeAxis3D": "Y",
          "size": 16,
          "billboardMode3D": "FaceNearPlane",
          "frame": {
            "xmin": 0,
            "ymin": 0,
            "xmax": 9.88,
            "ymax": 9.88
          },
          "markerGraphics": [
            {
              "type": "CIMMarkerGraphic",
              "geometry": {
                "rings": [
                  [
                    [
                      8.7,
                      0
                    ],
                    [
                      8.9,
                      0.02
                    ],
                    [
                      9.1,
                      0.07
                    ],
                    [
                      9.29,
                      0.16
                    ],
                    [
                      9.45,
                      0.28
                    ],
                    [
                      9.6,
                      0.43
                    ],
                    [
                      9.72,
                      0.59
                    ],
                    [
                      9.81,
                      0.78
                    ],
                    [
                      9.86,
                      0.98
                    ],
                    [
                      9.88,
                      1.18
                    ],
                    [
                      9.88,
                      8.7
                    ],
                    [
                      9.86,
                      8.9
                    ],
                    [
                      9.81,
                      9.1
                    ],
                    [
                      9.72,
                      9.29
                    ],
                    [
                      9.6,
                      9.45
                    ],
                    [
                      9.45,
                      9.6
                    ],
                    [
                      9.29,
                      9.72
                    ],
                    [
                      9.1,
                      9.81
                    ],
                    [
                      8.9,
                      9.86
                    ],
                    [
                      8.7,
                      9.88
                    ],
                    [
                      1.18,
                      9.88
                    ],
                    [
                      0.73,
                      9.79
                    ],
                    [
                      0.35,
                      9.53
                    ],
                    [
                      0.09,
                      9.15
                    ],
                    [
                      0,
                      8.7
                    ],
                    [
                      0,
                      1.18
                    ],
                    [
                      0.09,
                      0.73
                    ],
                    [
                      0.35,
                      0.35
                    ],
                    [
                      0.73,
                      0.09
                    ],
                    [
                      1.18,
                      0
                    ],
                    [
                      8.7,
                      0
                    ]
                  ]
                ]
              },
              "symbol": {
                "type": "CIMPolygonSymbol",
                "symbolLayers": [
                  {
                    "type": "CIMSolidFill",
                    "enable": true,
                    "color": [
                      255,
                      255,
                      255,
                      255
                    ]
                  }
                ]
              }
            },
            {
              "type": "CIMMarkerGraphic",
              "geometry": {
                "rings": [
                  [
                    [
                      8.7,
                      0.19
                    ],
                    [
                      8.86,
                      0.2
                    ],
                    [
                      9.01,
                      0.24
                    ],
                    [
                      9.16,
                      0.3
                    ],
                    [
                      9.29,
                      0.38
                    ],
                    [
                      9.41,
                      0.48
                    ],
                    [
                      9.51,
                      0.6
                    ],
                    [
                      9.59,
                      0.73
                    ],
                    [
                      9.65,
                      0.88
                    ],
                    [
                      9.69,
                      1.03
                    ],
                    [
                      9.7,
                      1.19
                    ],
                    [
                      9.7,
                      8.71
                    ],
                    [
                      9.69,
                      8.87
                    ],
                    [
                      9.65,
                      9.02
                    ],
                    [
                      9.59,
                      9.17
                    ],
                    [
                      9.51,
                      9.3
                    ],
                    [
                      9.41,
                      9.42
                    ],
                    [
                      9.29,
                      9.52
                    ],
                    [
                      9.16,
                      9.6
                    ],
                    [
                      9.01,
                      9.66
                    ],
                    [
                      8.86,
                      9.7
                    ],
                    [
                      8.7,
                      9.71
                    ],
                    [
                      1.18,
                      9.69
                    ],
                    [
                      1.02,
                      9.68
                    ],
                    [
                      0.87,
                      9.64
                    ],
                    [
                      0.72,
                      9.58
                    ],
                    [
                      0.59,
                      9.5
                    ],
                    [
                      0.47,
                      9.4
                    ],
                    [
                      0.37,
                      9.28
                    ],
                    [
                      0.29,
                      9.15
                    ],
                    [
                      0.23,
                      9
                    ],
                    [
                      0.19,
                      8.85
                    ],
                    [
                      0.18,
                      8.69
                    ],
                    [
                      0.18,
                      1.19
                    ],
                    [
                      0.19,
                      1.03
                    ],
                    [
                      0.23,
                      0.88
                    ],
                    [
                      0.29,
                      0.73
                    ],
                    [
                      0.37,
                      0.6
                    ],
                    [
                      0.47,
                      0.48
                    ],
                    [
                      0.59,
                      0.38
                    ],
                    [
                      0.72,
                      0.3
                    ],
                    [
                      0.87,
                      0.24
                    ],
                    [
                      1.02,
                      0.2
                    ],
                    [
                      1.18,
                      0.19
                    ],
                    [
                      8.7,
                      0.19
                    ]
                  ]
                ]
              },
              "symbol": {
                "type": "CIMPolygonSymbol",
                "symbolLayers": [
                  {
                    "type": "CIMSolidFill",
                    "enable": true,
                    "color": [
                      37,
                      40,
                      81,
                      255
                    ]
                  }
                ]
              }
            },
            {
              "type": "CIMMarkerGraphic",
              "geometry": {
                "rings": [
                  [
                    [
                      4.14,
                      7.09
                    ],
                    [
                      5.64,
                      7.09
                    ],
                    [
                      5.82,
                      7.07
                    ],
                    [
                      5.97,
                      7.03
                    ],
                    [
                      6.11,
                      6.97
                    ],
                    [
                      6.24,
                      6.89
                    ],
                    [
                      6.36,
                      6.79
                    ],
                    [
                      6.46,
                      6.67
                    ],
                    [
                      6.54,
                      6.54
                    ],
                    [
                      6.59,
                      6.39
                    ],
                    [
                      6.63,
                      6.24
                    ],
                    [
                      6.64,
                      6.09
                    ],
                    [
                      6.63,
                      5.93
                    ],
                    [
                      6.59,
                      5.78
                    ],
                    [
                      6.53,
                      5.63
                    ],
                    [
                      6.45,
                      5.5
                    ],
                    [
                      6.35,
                      5.38
                    ],
                    [
                      6.23,
                      5.28
                    ],
                    [
                      6.1,
                      5.2
                    ],
                    [
                      5.95,
                      5.14
                    ],
                    [
                      5.8,
                      5.1
                    ],
                    [
                      5.64,
                      5.09
                    ],
                    [
                      4.14,
                      5.09
                    ],
                    [
                      4.14,
                      7.09
                    ]
                  ],
                  [
                    [
                      4.14,
                      1.6
                    ],
                    [
                      4.14,
                      3.78
                    ],
                    [
                      5.64,
                      3.78
                    ],
                    [
                      6.02,
                      3.79
                    ],
                    [
                      6.39,
                      3.86
                    ],
                    [
                      6.74,
                      3.98
                    ],
                    [
                      7.07,
                      4.17
                    ],
                    [
                      7.36,
                      4.4
                    ],
                    [
                      7.61,
                      4.68
                    ],
                    [
                      7.82,
                      5
                    ],
                    [
                      7.97,
                      5.34
                    ],
                    [
                      8.06,
                      5.71
                    ],
                    [
                      8.09,
                      6.08
                    ],
                    [
                      8.06,
                      6.45
                    ],
                    [
                      7.96,
                      6.82
                    ],
                    [
                      7.81,
                      7.16
                    ],
                    [
                      7.61,
                      7.47
                    ],
                    [
                      7.36,
                      7.75
                    ],
                    [
                      7.06,
                      7.99
                    ],
                    [
                      6.74,
                      8.17
                    ],
                    [
                      6.38,
                      8.29
                    ],
                    [
                      6.01,
                      8.36
                    ],
                    [
                      5.64,
                      8.37
                    ],
                    [
                      2.82,
                      8.37
                    ],
                    [
                      2.82,
                      1.6
                    ],
                    [
                      4.14,
                      1.6
                    ]
                  ]
                ]
              },
              "symbol": {
                "type": "CIMPolygonSymbol",
                "symbolLayers": [
                  {
                    "type": "CIMSolidFill",
                    "enable": true,
                    "color": [
                      255,
                      255,
                      255,
                      255
                    ]
                  }
                ]
              }
            }
          ],
          "scaleSymbolsProportionally": true,
          "respectFrame": true,
          "clippingPath": {
            "type": "CIMClippingPath",
            "clippingType": "Intersect",
            "path": {
              "rings": [
                [
                  [
                    0,
                    0
                  ],
                  [
                    9.88,
                    0
                  ],
                  [
                    9.88,
                    9.88
                  ],
                  [
                    0,
                    9.88
                  ],
                  [
                    0,
                    0
                  ]
                ]
              ]
            }
          }
        }
      ],
      "haloSize": 1,
      "scaleX": 1,
      "angleAlignment": "Display"
    }
  }
});


export const parkingRenderer = {
  type: "simple", // autocasts as new SimpleRenderer()
  symbol: cimSymbol
};


const cimBusSymbol = new CIMSymbol({
  "data": {
    "type": "CIMSymbolReference",
    "minScale": "13000",

    "symbol": {
      "type": "CIMPointSymbol",
      "symbolLayers": [
        {
          "type": "CIMVectorMarker",
          "enable": true,
          "anchorPointUnits": "Relative",
          "dominantSizeAxis3D": "Y",
          "size": 18,
          "billboardMode3D": "FaceNearPlane",
          "frame": {
            "xmin": 0,
            "ymin": 0,
            "xmax": 21,
            "ymax": 21
          },
          "markerGraphics": [
            {
              "type": "CIMMarkerGraphic",
              "geometry": {
                "rings": [
                  [
                    [
                      10.5,
                      18
                    ],
                    [
                      11.74,
                      17.94
                    ],
                    [
                      12.98,
                      17.75
                    ],
                    [
                      14.14,
                      17.46
                    ],
                    [
                      15.15,
                      17.08
                    ],
                    [
                      15.95,
                      16.63
                    ],
                    [
                      16.53,
                      16.13
                    ],
                    [
                      16.88,
                      15.58
                    ],
                    [
                      17,
                      15
                    ],
                    [
                      17,
                      5
                    ],
                    [
                      16,
                      5
                    ],
                    [
                      16,
                      3.5
                    ],
                    [
                      15.85,
                      3.15
                    ],
                    [
                      15.5,
                      3
                    ],
                    [
                      14.5,
                      3
                    ],
                    [
                      14.15,
                      3.15
                    ],
                    [
                      14,
                      3.5
                    ],
                    [
                      14,
                      5
                    ],
                    [
                      7,
                      5
                    ],
                    [
                      7,
                      3.5
                    ],
                    [
                      6.85,
                      3.15
                    ],
                    [
                      6.5,
                      3
                    ],
                    [
                      5.5,
                      3
                    ],
                    [
                      5.15,
                      3.15
                    ],
                    [
                      5,
                      3.5
                    ],
                    [
                      5,
                      5
                    ],
                    [
                      4,
                      5
                    ],
                    [
                      4,
                      15
                    ],
                    [
                      4.12,
                      15.58
                    ],
                    [
                      4.47,
                      16.13
                    ],
                    [
                      5.05,
                      16.63
                    ],
                    [
                      5.85,
                      17.08
                    ],
                    [
                      6.86,
                      17.46
                    ],
                    [
                      8.02,
                      17.75
                    ],
                    [
                      9.26,
                      17.94
                    ],
                    [
                      10.5,
                      18
                    ]
                  ],
                  [
                    [
                      7,
                      16
                    ],
                    [
                      7,
                      15
                    ],
                    [
                      14,
                      15
                    ],
                    [
                      14,
                      16
                    ],
                    [
                      7,
                      16
                    ]
                  ],
                  [
                    [
                      7,
                      6.5
                    ],
                    [
                      7,
                      7.5
                    ],
                    [
                      6.86,
                      7.86
                    ],
                    [
                      6.5,
                      8
                    ],
                    [
                      5.5,
                      8
                    ],
                    [
                      5.14,
                      7.86
                    ],
                    [
                      5,
                      7.5
                    ],
                    [
                      5,
                      6.5
                    ],
                    [
                      5.15,
                      6.15
                    ],
                    [
                      5.5,
                      6
                    ],
                    [
                      6.5,
                      6
                    ],
                    [
                      6.85,
                      6.15
                    ],
                    [
                      7,
                      6.5
                    ]
                  ],
                  [
                    [
                      16,
                      6.5
                    ],
                    [
                      16,
                      7.5
                    ],
                    [
                      15.86,
                      7.86
                    ],
                    [
                      15.5,
                      8
                    ],
                    [
                      14.5,
                      8
                    ],
                    [
                      14.15,
                      7.86
                    ],
                    [
                      14,
                      7.5
                    ],
                    [
                      14,
                      6.5
                    ],
                    [
                      14.15,
                      6.15
                    ],
                    [
                      14.5,
                      6
                    ],
                    [
                      15.5,
                      6
                    ],
                    [
                      15.85,
                      6.15
                    ],
                    [
                      16,
                      6.5
                    ]
                  ],
                  [
                    [
                      16,
                      9
                    ],
                    [
                      16,
                      14
                    ],
                    [
                      5,
                      14
                    ],
                    [
                      5,
                      9
                    ],
                    [
                      16,
                      9
                    ]
                  ]
                ]
              },
              "symbol": {
                "type": "CIMPolygonSymbol",
                "symbolLayers": [
                  {
                    "type": "CIMSolidStroke",
                    "enable": true,
                    "capStyle": "Round",
                    "joinStyle": "Round",
                    "lineStyle3D": "Strip",
                    "miterLimit": 10,
                    "width": 0.1,
                    "color": [
                      0,
                      0,
                      0,
                      255
                    ]
                  },
                  {
                    "type": "CIMSolidFill",
                    "enable": true,
                    "color": [
                      255,
                      255,
                      255,
                      255
                    ]
                  }
                ]
              }
            }
          ],
          "scaleSymbolsProportionally": true,
          "respectFrame": true
        },
        {
          "type": "CIMVectorMarker",
          "enable": true,
          "colorLocked": true,
          "anchorPointUnits": "Relative",
          "dominantSizeAxis3D": "Y",
          "size": 24,
          "billboardMode3D": "FaceNearPlane",
          "frame": {
            "xmin": -5,
            "ymin": -5,
            "xmax": 5,
            "ymax": 5
          },
          "markerGraphics": [
            {
              "type": "CIMMarkerGraphic",
              "geometry": {
                "rings": [
                  [
                    [
                      0,
                      5
                    ],
                    [
                      0.87,
                      4.92
                    ],
                    [
                      1.71,
                      4.7
                    ],
                    [
                      2.5,
                      4.33
                    ],
                    [
                      3.21,
                      3.83
                    ],
                    [
                      3.83,
                      3.21
                    ],
                    [
                      4.33,
                      2.5
                    ],
                    [
                      4.7,
                      1.71
                    ],
                    [
                      4.92,
                      0.87
                    ],
                    [
                      5,
                      0
                    ],
                    [
                      4.92,
                      -0.87
                    ],
                    [
                      4.7,
                      -1.71
                    ],
                    [
                      4.33,
                      -2.5
                    ],
                    [
                      3.83,
                      -3.21
                    ],
                    [
                      3.21,
                      -3.83
                    ],
                    [
                      2.5,
                      -4.33
                    ],
                    [
                      1.71,
                      -4.7
                    ],
                    [
                      0.87,
                      -4.92
                    ],
                    [
                      0,
                      -5
                    ],
                    [
                      -0.87,
                      -4.92
                    ],
                    [
                      -1.71,
                      -4.7
                    ],
                    [
                      -2.5,
                      -4.33
                    ],
                    [
                      -3.21,
                      -3.83
                    ],
                    [
                      -3.83,
                      -3.21
                    ],
                    [
                      -4.33,
                      -2.5
                    ],
                    [
                      -4.7,
                      -1.71
                    ],
                    [
                      -4.92,
                      -0.87
                    ],
                    [
                      -5,
                      0
                    ],
                    [
                      -4.92,
                      0.87
                    ],
                    [
                      -4.7,
                      1.71
                    ],
                    [
                      -4.33,
                      2.5
                    ],
                    [
                      -3.83,
                      3.21
                    ],
                    [
                      -3.21,
                      3.83
                    ],
                    [
                      -2.5,
                      4.33
                    ],
                    [
                      -1.71,
                      4.7
                    ],
                    [
                      -0.87,
                      4.92
                    ],
                    [
                      0,
                      5
                    ]
                  ]
                ]
              },
              "symbol": {
                "type": "CIMPolygonSymbol",
                "symbolLayers": [
                  {
                    "type": "CIMSolidStroke",
                    "enable": true,
                    "capStyle": "Round",
                    "joinStyle": "Round",
                    "lineStyle3D": "Strip",
                    "miterLimit": 10,
                    "width": 0.7,
                    "color": [
                      255,
                      255,
                      255,
                      255
                    ]
                  },
                  {
                    "type": "CIMSolidFill",
                    "enable": true,
                    "color": [
                      37,
                      40,
                      81,
                      255
                    ]
                  }
                ]
              }
            }
          ],
          "scaleSymbolsProportionally": true,
          "respectFrame": true
        }
      ],
      "haloSize": 1,
      "scaleX": 1,
      "angleAlignment": "Display",
      "version": "2.0.0",
      "build": "8933"
    }
  }
});




const cimBikeParkingSymbol = new CIMSymbol({
  "data": {
    "type": "CIMSymbolReference",
    "symbol": {
      "type": "CIMPointSymbol",
      "symbolLayers": [
        {
          "type": "CIMVectorMarker",
          "enable": true,
          "colorLocked": true,
          "anchorPoint": {
            "x": 0,
            "y": -0.36,
            "z": 0
          },
          "anchorPointUnits": "Relative",
          "dominantSizeAxis3D": "Y",
          "size": 15,
          "billboardMode3D": "FaceNearPlane",
          "frame": {
            "xmin": 0,
            "ymin": 0,
            "xmax": 21,
            "ymax": 21
          },
          "markerGraphics": [
            {
              "type": "CIMMarkerGraphic",
              "geometry": {
                "rings": [
                  [
                    [
                      13.92,
                      6.27
                    ],
                    [
                      13.91,
                      6.39
                    ],
                    [
                      13.9,
                      6.45
                    ],
                    [
                      13.9,
                      6.5
                    ],
                    [
                      13.9,
                      6.59
                    ],
                    [
                      13.91,
                      6.68
                    ],
                    [
                      13.91,
                      6.76
                    ],
                    [
                      13.92,
                      6.85
                    ],
                    [
                      13.94,
                      7.02
                    ],
                    [
                      13.97,
                      7.18
                    ],
                    [
                      14.03,
                      7.22
                    ],
                    [
                      14.09,
                      7.25
                    ],
                    [
                      14.15,
                      7.29
                    ],
                    [
                      14.21,
                      7.33
                    ],
                    [
                      14.26,
                      7.37
                    ],
                    [
                      14.32,
                      7.42
                    ],
                    [
                      14.37,
                      7.46
                    ],
                    [
                      14.42,
                      7.51
                    ],
                    [
                      14.47,
                      7.56
                    ],
                    [
                      14.52,
                      7.61
                    ],
                    [
                      14.57,
                      7.66
                    ],
                    [
                      14.61,
                      7.71
                    ],
                    [
                      14.65,
                      7.77
                    ],
                    [
                      14.69,
                      7.82
                    ],
                    [
                      14.73,
                      7.88
                    ],
                    [
                      14.77,
                      7.94
                    ],
                    [
                      14.8,
                      8
                    ],
                    [
                      14.84,
                      8.06
                    ],
                    [
                      14.87,
                      8.12
                    ],
                    [
                      14.9,
                      8.18
                    ],
                    [
                      14.92,
                      8.25
                    ],
                    [
                      14.95,
                      8.31
                    ],
                    [
                      14.97,
                      8.38
                    ],
                    [
                      14.99,
                      8.44
                    ],
                    [
                      15.01,
                      8.51
                    ],
                    [
                      15.02,
                      8.58
                    ],
                    [
                      15.04,
                      8.65
                    ],
                    [
                      15.05,
                      8.72
                    ],
                    [
                      15.06,
                      8.79
                    ],
                    [
                      15.06,
                      8.86
                    ],
                    [
                      15.07,
                      8.93
                    ],
                    [
                      15.07,
                      9
                    ],
                    [
                      15.07,
                      9.06
                    ],
                    [
                      15.07,
                      9.12
                    ],
                    [
                      15.06,
                      9.18
                    ],
                    [
                      15.05,
                      9.24
                    ],
                    [
                      15.05,
                      9.3
                    ],
                    [
                      15.04,
                      9.36
                    ],
                    [
                      15.01,
                      9.47
                    ],
                    [
                      15.07,
                      9.54
                    ],
                    [
                      15.13,
                      9.61
                    ],
                    [
                      15.19,
                      9.67
                    ],
                    [
                      15.25,
                      9.73
                    ],
                    [
                      15.36,
                      9.83
                    ],
                    [
                      15.46,
                      9.93
                    ],
                    [
                      15.57,
                      10.02
                    ],
                    [
                      15.68,
                      10.11
                    ],
                    [
                      15.68,
                      10.11
                    ],
                    [
                      15.7,
                      10.05
                    ],
                    [
                      15.73,
                      9.98
                    ],
                    [
                      15.75,
                      9.92
                    ],
                    [
                      15.77,
                      9.85
                    ],
                    [
                      15.79,
                      9.78
                    ],
                    [
                      15.81,
                      9.71
                    ],
                    [
                      15.83,
                      9.65
                    ],
                    [
                      15.84,
                      9.58
                    ],
                    [
                      15.86,
                      9.51
                    ],
                    [
                      15.87,
                      9.44
                    ],
                    [
                      15.88,
                      9.37
                    ],
                    [
                      15.89,
                      9.29
                    ],
                    [
                      15.89,
                      9.22
                    ],
                    [
                      15.9,
                      9.15
                    ],
                    [
                      15.9,
                      9.08
                    ],
                    [
                      15.9,
                      9
                    ],
                    [
                      15.9,
                      8.89
                    ],
                    [
                      15.89,
                      8.77
                    ],
                    [
                      15.88,
                      8.66
                    ],
                    [
                      15.86,
                      8.55
                    ],
                    [
                      15.84,
                      8.44
                    ],
                    [
                      15.82,
                      8.33
                    ],
                    [
                      15.79,
                      8.22
                    ],
                    [
                      15.76,
                      8.11
                    ],
                    [
                      15.72,
                      8
                    ],
                    [
                      15.68,
                      7.9
                    ],
                    [
                      15.63,
                      7.8
                    ],
                    [
                      15.58,
                      7.7
                    ],
                    [
                      15.53,
                      7.6
                    ],
                    [
                      15.48,
                      7.5
                    ],
                    [
                      15.42,
                      7.41
                    ],
                    [
                      15.35,
                      7.32
                    ],
                    [
                      15.29,
                      7.23
                    ],
                    [
                      15.22,
                      7.14
                    ],
                    [
                      15.14,
                      7.06
                    ],
                    [
                      15.07,
                      6.98
                    ],
                    [
                      14.99,
                      6.9
                    ],
                    [
                      14.9,
                      6.83
                    ],
                    [
                      14.82,
                      6.75
                    ],
                    [
                      14.73,
                      6.69
                    ],
                    [
                      14.64,
                      6.62
                    ],
                    [
                      14.54,
                      6.56
                    ],
                    [
                      14.44,
                      6.5
                    ],
                    [
                      14.34,
                      6.45
                    ],
                    [
                      14.24,
                      6.4
                    ],
                    [
                      14.13,
                      6.35
                    ],
                    [
                      14.03,
                      6.31
                    ],
                    [
                      13.92,
                      6.27
                    ]
                  ],
                  [
                    [
                      19,
                      12.98
                    ],
                    [
                      19,
                      5
                    ],
                    [
                      18,
                      5
                    ],
                    [
                      18,
                      12.08
                    ],
                    [
                      17.87,
                      12.07
                    ],
                    [
                      17.75,
                      12.05
                    ],
                    [
                      17.62,
                      12.03
                    ],
                    [
                      17.5,
                      12.01
                    ],
                    [
                      17.38,
                      11.99
                    ],
                    [
                      17.26,
                      11.96
                    ],
                    [
                      17.13,
                      11.93
                    ],
                    [
                      17.01,
                      11.9
                    ],
                    [
                      16.9,
                      11.87
                    ],
                    [
                      16.78,
                      11.83
                    ],
                    [
                      16.66,
                      11.79
                    ],
                    [
                      16.55,
                      11.75
                    ],
                    [
                      16.43,
                      11.71
                    ],
                    [
                      16.32,
                      11.66
                    ],
                    [
                      16.21,
                      11.61
                    ],
                    [
                      16.1,
                      11.56
                    ],
                    [
                      15.99,
                      11.51
                    ],
                    [
                      15.88,
                      11.45
                    ],
                    [
                      15.77,
                      11.39
                    ],
                    [
                      15.67,
                      11.33
                    ],
                    [
                      15.56,
                      11.27
                    ],
                    [
                      15.46,
                      11.2
                    ],
                    [
                      15.36,
                      11.14
                    ],
                    [
                      15.26,
                      11.07
                    ],
                    [
                      15.16,
                      11
                    ],
                    [
                      15.07,
                      10.92
                    ],
                    [
                      14.97,
                      10.85
                    ],
                    [
                      14.88,
                      10.77
                    ],
                    [
                      14.79,
                      10.69
                    ],
                    [
                      14.7,
                      10.61
                    ],
                    [
                      14.61,
                      10.53
                    ],
                    [
                      14.53,
                      10.45
                    ],
                    [
                      14.43,
                      10.35
                    ],
                    [
                      14.34,
                      10.25
                    ],
                    [
                      14.26,
                      10.15
                    ],
                    [
                      14.17,
                      10.05
                    ],
                    [
                      14.09,
                      9.95
                    ],
                    [
                      14,
                      9.84
                    ],
                    [
                      13.93,
                      9.73
                    ],
                    [
                      13.85,
                      9.62
                    ],
                    [
                      13.78,
                      9.51
                    ],
                    [
                      13.7,
                      9.39
                    ],
                    [
                      13.64,
                      9.28
                    ],
                    [
                      13.57,
                      9.16
                    ],
                    [
                      13.51,
                      9.04
                    ],
                    [
                      13.45,
                      8.92
                    ],
                    [
                      13.39,
                      8.8
                    ],
                    [
                      13.34,
                      8.67
                    ],
                    [
                      13.29,
                      8.55
                    ],
                    [
                      13.24,
                      8.42
                    ],
                    [
                      13.19,
                      8.29
                    ],
                    [
                      13.15,
                      8.16
                    ],
                    [
                      13.11,
                      8.03
                    ],
                    [
                      13.08,
                      7.9
                    ],
                    [
                      13.04,
                      7.76
                    ],
                    [
                      13.01,
                      7.63
                    ],
                    [
                      12.99,
                      7.49
                    ],
                    [
                      12.96,
                      7.35
                    ],
                    [
                      12.94,
                      7.21
                    ],
                    [
                      12.93,
                      7.07
                    ],
                    [
                      12.92,
                      6.93
                    ],
                    [
                      12.91,
                      6.79
                    ],
                    [
                      12.9,
                      6.65
                    ],
                    [
                      12.9,
                      6.5
                    ],
                    [
                      12.9,
                      6.33
                    ],
                    [
                      12.91,
                      6.15
                    ],
                    [
                      12.93,
                      5.97
                    ],
                    [
                      12.95,
                      5.79
                    ],
                    [
                      12.96,
                      5.69
                    ],
                    [
                      12.98,
                      5.59
                    ],
                    [
                      13,
                      5.49
                    ],
                    [
                      13.01,
                      5.39
                    ],
                    [
                      13.04,
                      5.3
                    ],
                    [
                      13.06,
                      5.2
                    ],
                    [
                      13.08,
                      5.1
                    ],
                    [
                      13.11,
                      5
                    ],
                    [
                      12.18,
                      5
                    ],
                    [
                      12.14,
                      5.19
                    ],
                    [
                      12.1,
                      5.37
                    ],
                    [
                      12.07,
                      5.55
                    ],
                    [
                      12.05,
                      5.74
                    ],
                    [
                      12.03,
                      5.93
                    ],
                    [
                      12.01,
                      6.12
                    ],
                    [
                      12,
                      6.31
                    ],
                    [
                      12,
                      6.41
                    ],
                    [
                      12,
                      6.5
                    ],
                    [
                      12,
                      6.67
                    ],
                    [
                      12.01,
                      6.84
                    ],
                    [
                      12.02,
                      7
                    ],
                    [
                      12.03,
                      7.17
                    ],
                    [
                      12.05,
                      7.33
                    ],
                    [
                      12.07,
                      7.49
                    ],
                    [
                      12.1,
                      7.66
                    ],
                    [
                      12.13,
                      7.81
                    ],
                    [
                      12.17,
                      7.97
                    ],
                    [
                      12.2,
                      8.13
                    ],
                    [
                      12.25,
                      8.28
                    ],
                    [
                      12.29,
                      8.44
                    ],
                    [
                      12.34,
                      8.59
                    ],
                    [
                      12.39,
                      8.74
                    ],
                    [
                      12.45,
                      8.89
                    ],
                    [
                      12.51,
                      9.03
                    ],
                    [
                      12.57,
                      9.18
                    ],
                    [
                      12.64,
                      9.32
                    ],
                    [
                      12.71,
                      9.46
                    ],
                    [
                      12.78,
                      9.6
                    ],
                    [
                      12.86,
                      9.74
                    ],
                    [
                      12.94,
                      9.88
                    ],
                    [
                      13.02,
                      10.01
                    ],
                    [
                      13.11,
                      10.14
                    ],
                    [
                      13.2,
                      10.27
                    ],
                    [
                      13.29,
                      10.39
                    ],
                    [
                      13.39,
                      10.52
                    ],
                    [
                      13.48,
                      10.64
                    ],
                    [
                      13.58,
                      10.76
                    ],
                    [
                      13.69,
                      10.88
                    ],
                    [
                      13.79,
                      10.99
                    ],
                    [
                      13.9,
                      11.1
                    ],
                    [
                      14.02,
                      11.21
                    ],
                    [
                      14.13,
                      11.32
                    ],
                    [
                      14.25,
                      11.42
                    ],
                    [
                      14.37,
                      11.52
                    ],
                    [
                      14.49,
                      11.62
                    ],
                    [
                      14.61,
                      11.71
                    ],
                    [
                      14.74,
                      11.81
                    ],
                    [
                      14.87,
                      11.89
                    ],
                    [
                      15,
                      11.98
                    ],
                    [
                      15.13,
                      12.06
                    ],
                    [
                      15.26,
                      12.14
                    ],
                    [
                      15.4,
                      12.22
                    ],
                    [
                      15.54,
                      12.29
                    ],
                    [
                      15.68,
                      12.36
                    ],
                    [
                      15.82,
                      12.43
                    ],
                    [
                      15.97,
                      12.49
                    ],
                    [
                      16.12,
                      12.55
                    ],
                    [
                      16.27,
                      12.61
                    ],
                    [
                      16.42,
                      12.66
                    ],
                    [
                      16.57,
                      12.71
                    ],
                    [
                      16.72,
                      12.76
                    ],
                    [
                      16.88,
                      12.8
                    ],
                    [
                      17.03,
                      12.84
                    ],
                    [
                      17.19,
                      12.87
                    ],
                    [
                      17.35,
                      12.9
                    ],
                    [
                      17.51,
                      12.93
                    ],
                    [
                      17.67,
                      12.95
                    ],
                    [
                      17.84,
                      12.97
                    ],
                    [
                      18,
                      12.99
                    ],
                    [
                      18.17,
                      13
                    ],
                    [
                      18.33,
                      13
                    ],
                    [
                      18.5,
                      13
                    ],
                    [
                      18.63,
                      13
                    ],
                    [
                      18.75,
                      13
                    ],
                    [
                      18.88,
                      12.99
                    ],
                    [
                      19,
                      12.98
                    ]
                  ],
                  [
                    [
                      11.5,
                      17
                    ],
                    [
                      12.78,
                      11.88
                    ],
                    [
                      12.83,
                      11.89
                    ],
                    [
                      12.88,
                      11.9
                    ],
                    [
                      12.94,
                      11.9
                    ],
                    [
                      13,
                      11.9
                    ],
                    [
                      13.06,
                      11.9
                    ],
                    [
                      13.11,
                      11.9
                    ],
                    [
                      13.22,
                      11.88
                    ],
                    [
                      13.28,
                      11.88
                    ],
                    [
                      13.18,
                      11.78
                    ],
                    [
                      13.08,
                      11.67
                    ],
                    [
                      12.98,
                      11.57
                    ],
                    [
                      12.88,
                      11.46
                    ],
                    [
                      12.79,
                      11.35
                    ],
                    [
                      12.7,
                      11.24
                    ],
                    [
                      12.62,
                      11.15
                    ],
                    [
                      12.55,
                      11.06
                    ],
                    [
                      12.52,
                      11.01
                    ],
                    [
                      12.44,
                      10.99
                    ],
                    [
                      12.35,
                      10.97
                    ],
                    [
                      12.27,
                      10.94
                    ],
                    [
                      12.19,
                      10.9
                    ],
                    [
                      12.11,
                      10.87
                    ],
                    [
                      12.03,
                      10.83
                    ],
                    [
                      11.96,
                      10.79
                    ],
                    [
                      11.88,
                      10.74
                    ],
                    [
                      11.81,
                      10.7
                    ],
                    [
                      11.74,
                      10.65
                    ],
                    [
                      11.68,
                      10.59
                    ],
                    [
                      11.61,
                      10.54
                    ],
                    [
                      11.55,
                      10.48
                    ],
                    [
                      11.49,
                      10.42
                    ],
                    [
                      11.44,
                      10.36
                    ],
                    [
                      11.38,
                      10.29
                    ],
                    [
                      11.33,
                      10.22
                    ],
                    [
                      11.28,
                      10.15
                    ],
                    [
                      11.23,
                      10.08
                    ],
                    [
                      11.19,
                      10.01
                    ],
                    [
                      11.15,
                      9.93
                    ],
                    [
                      11.11,
                      9.85
                    ],
                    [
                      11.08,
                      9.78
                    ],
                    [
                      11.05,
                      9.69
                    ],
                    [
                      11.02,
                      9.61
                    ],
                    [
                      11,
                      9.53
                    ],
                    [
                      10.98,
                      9.44
                    ],
                    [
                      10.96,
                      9.36
                    ],
                    [
                      10.95,
                      9.27
                    ],
                    [
                      10.94,
                      9.18
                    ],
                    [
                      10.93,
                      9.09
                    ],
                    [
                      10.93,
                      9
                    ],
                    [
                      10.93,
                      8.94
                    ],
                    [
                      10.93,
                      8.88
                    ],
                    [
                      10.94,
                      8.82
                    ],
                    [
                      10.95,
                      8.75
                    ],
                    [
                      10.95,
                      8.69
                    ],
                    [
                      10.97,
                      8.63
                    ],
                    [
                      10.98,
                      8.57
                    ],
                    [
                      10.99,
                      8.51
                    ],
                    [
                      11.01,
                      8.45
                    ],
                    [
                      11.03,
                      8.4
                    ],
                    [
                      11.05,
                      8.34
                    ],
                    [
                      11.07,
                      8.28
                    ],
                    [
                      11.09,
                      8.23
                    ],
                    [
                      11.11,
                      8.17
                    ],
                    [
                      11.14,
                      8.12
                    ],
                    [
                      11.17,
                      8.06
                    ],
                    [
                      11.14,
                      7.92
                    ],
                    [
                      11.11,
                      7.78
                    ],
                    [
                      11.09,
                      7.63
                    ],
                    [
                      11.07,
                      7.49
                    ],
                    [
                      11.05,
                      7.34
                    ],
                    [
                      11.04,
                      7.19
                    ],
                    [
                      11.03,
                      7.04
                    ],
                    [
                      11.02,
                      6.9
                    ],
                    [
                      10.97,
                      6.95
                    ],
                    [
                      10.91,
                      7
                    ],
                    [
                      10.86,
                      7.05
                    ],
                    [
                      10.81,
                      7.11
                    ],
                    [
                      10.76,
                      7.17
                    ],
                    [
                      10.72,
                      7.22
                    ],
                    [
                      10.67,
                      7.28
                    ],
                    [
                      10.63,
                      7.34
                    ],
                    [
                      10.59,
                      7.4
                    ],
                    [
                      10.55,
                      7.47
                    ],
                    [
                      10.51,
                      7.53
                    ],
                    [
                      10.47,
                      7.59
                    ],
                    [
                      10.44,
                      7.66
                    ],
                    [
                      10.4,
                      7.72
                    ],
                    [
                      10.37,
                      7.79
                    ],
                    [
                      10.34,
                      7.86
                    ],
                    [
                      10.31,
                      7.92
                    ],
                    [
                      10.28,
                      7.99
                    ],
                    [
                      10.26,
                      8.06
                    ],
                    [
                      10.24,
                      8.13
                    ],
                    [
                      10.22,
                      8.2
                    ],
                    [
                      10.2,
                      8.27
                    ],
                    [
                      10.18,
                      8.34
                    ],
                    [
                      10.16,
                      8.42
                    ],
                    [
                      10.15,
                      8.49
                    ],
                    [
                      10.13,
                      8.56
                    ],
                    [
                      10.12,
                      8.63
                    ],
                    [
                      10.12,
                      8.71
                    ],
                    [
                      10.11,
                      8.78
                    ],
                    [
                      10.1,
                      8.86
                    ],
                    [
                      10.1,
                      8.93
                    ],
                    [
                      10.1,
                      9
                    ],
                    [
                      10.1,
                      9.08
                    ],
                    [
                      10.1,
                      9.15
                    ],
                    [
                      10.11,
                      9.22
                    ],
                    [
                      10.11,
                      9.29
                    ],
                    [
                      10.12,
                      9.36
                    ],
                    [
                      10.13,
                      9.43
                    ],
                    [
                      10.14,
                      9.5
                    ],
                    [
                      10.15,
                      9.56
                    ],
                    [
                      10.17,
                      9.63
                    ],
                    [
                      10.18,
                      9.7
                    ],
                    [
                      10.2,
                      9.76
                    ],
                    [
                      10.22,
                      9.83
                    ],
                    [
                      10.24,
                      9.89
                    ],
                    [
                      10.26,
                      9.96
                    ],
                    [
                      10.28,
                      10.02
                    ],
                    [
                      10.31,
                      10.08
                    ],
                    [
                      10.34,
                      10.15
                    ],
                    [
                      10.36,
                      10.21
                    ],
                    [
                      10.39,
                      10.27
                    ],
                    [
                      10.42,
                      10.33
                    ],
                    [
                      10.45,
                      10.39
                    ],
                    [
                      10.49,
                      10.45
                    ],
                    [
                      10.56,
                      10.56
                    ],
                    [
                      10.63,
                      10.67
                    ],
                    [
                      10.71,
                      10.78
                    ],
                    [
                      10.79,
                      10.88
                    ],
                    [
                      10.88,
                      10.98
                    ],
                    [
                      10.94,
                      11.04
                    ],
                    [
                      11,
                      11.1
                    ],
                    [
                      11.07,
                      11.15
                    ],
                    [
                      11.13,
                      11.21
                    ],
                    [
                      11.2,
                      11.26
                    ],
                    [
                      11.26,
                      11.32
                    ],
                    [
                      11.33,
                      11.37
                    ],
                    [
                      11.4,
                      11.41
                    ],
                    [
                      11.47,
                      11.46
                    ],
                    [
                      11.55,
                      11.5
                    ],
                    [
                      11.62,
                      11.55
                    ],
                    [
                      11.7,
                      11.59
                    ],
                    [
                      11.78,
                      11.62
                    ],
                    [
                      11.85,
                      11.66
                    ],
                    [
                      11.93,
                      11.69
                    ],
                    [
                      12.02,
                      11.72
                    ],
                    [
                      11.7,
                      12.95
                    ],
                    [
                      7.81,
                      9.69
                    ],
                    [
                      7.83,
                      9.6
                    ],
                    [
                      7.85,
                      9.52
                    ],
                    [
                      7.86,
                      9.44
                    ],
                    [
                      7.88,
                      9.35
                    ],
                    [
                      7.89,
                      9.27
                    ],
                    [
                      7.89,
                      9.18
                    ],
                    [
                      7.9,
                      9.09
                    ],
                    [
                      7.9,
                      9
                    ],
                    [
                      7.9,
                      8.93
                    ],
                    [
                      7.9,
                      8.86
                    ],
                    [
                      7.89,
                      8.78
                    ],
                    [
                      7.89,
                      8.71
                    ],
                    [
                      7.88,
                      8.64
                    ],
                    [
                      7.87,
                      8.56
                    ],
                    [
                      7.85,
                      8.49
                    ],
                    [
                      7.84,
                      8.42
                    ],
                    [
                      7.83,
                      8.35
                    ],
                    [
                      7.81,
                      8.28
                    ],
                    [
                      7.79,
                      8.21
                    ],
                    [
                      7.77,
                      8.14
                    ],
                    [
                      7.75,
                      8.07
                    ],
                    [
                      7.72,
                      8.01
                    ],
                    [
                      7.7,
                      7.94
                    ],
                    [
                      7.67,
                      7.88
                    ],
                    [
                      7.64,
                      7.81
                    ],
                    [
                      7.61,
                      7.75
                    ],
                    [
                      7.58,
                      7.68
                    ],
                    [
                      7.55,
                      7.62
                    ],
                    [
                      7.52,
                      7.56
                    ],
                    [
                      7.48,
                      7.5
                    ],
                    [
                      7.44,
                      7.44
                    ],
                    [
                      7.4,
                      7.38
                    ],
                    [
                      7.37,
                      7.33
                    ],
                    [
                      7.32,
                      7.27
                    ],
                    [
                      7.28,
                      7.21
                    ],
                    [
                      7.24,
                      7.16
                    ],
                    [
                      7.19,
                      7.11
                    ],
                    [
                      7.15,
                      7.05
                    ],
                    [
                      7.1,
                      7
                    ],
                    [
                      7.05,
                      6.95
                    ],
                    [
                      7,
                      6.91
                    ],
                    [
                      6.95,
                      6.86
                    ],
                    [
                      6.9,
                      6.81
                    ],
                    [
                      6.84,
                      6.77
                    ],
                    [
                      6.79,
                      6.72
                    ],
                    [
                      6.74,
                      6.68
                    ],
                    [
                      6.68,
                      6.64
                    ],
                    [
                      6.62,
                      6.6
                    ],
                    [
                      6.56,
                      6.56
                    ],
                    [
                      6.5,
                      6.52
                    ],
                    [
                      6.44,
                      6.49
                    ],
                    [
                      6.38,
                      6.45
                    ],
                    [
                      6.32,
                      6.42
                    ],
                    [
                      6.26,
                      6.39
                    ],
                    [
                      6.19,
                      6.36
                    ],
                    [
                      6.13,
                      6.33
                    ],
                    [
                      6.06,
                      6.31
                    ],
                    [
                      6,
                      6.28
                    ],
                    [
                      5.93,
                      6.26
                    ],
                    [
                      5.86,
                      6.24
                    ],
                    [
                      5.79,
                      6.21
                    ],
                    [
                      5.72,
                      6.2
                    ],
                    [
                      5.66,
                      6.18
                    ],
                    [
                      5.58,
                      6.16
                    ],
                    [
                      5.51,
                      6.15
                    ],
                    [
                      5.44,
                      6.14
                    ],
                    [
                      5.37,
                      6.13
                    ],
                    [
                      5.3,
                      6.12
                    ],
                    [
                      5.22,
                      6.11
                    ],
                    [
                      5.15,
                      6.11
                    ],
                    [
                      5.07,
                      6.11
                    ],
                    [
                      5,
                      6.1
                    ],
                    [
                      4.93,
                      6.11
                    ],
                    [
                      4.85,
                      6.11
                    ],
                    [
                      4.78,
                      6.11
                    ],
                    [
                      4.7,
                      6.12
                    ],
                    [
                      4.63,
                      6.13
                    ],
                    [
                      4.56,
                      6.14
                    ],
                    [
                      4.49,
                      6.15
                    ],
                    [
                      4.42,
                      6.16
                    ],
                    [
                      4.34,
                      6.18
                    ],
                    [
                      4.28,
                      6.2
                    ],
                    [
                      4.21,
                      6.21
                    ],
                    [
                      4.14,
                      6.24
                    ],
                    [
                      4.07,
                      6.26
                    ],
                    [
                      4,
                      6.28
                    ],
                    [
                      3.94,
                      6.31
                    ],
                    [
                      3.87,
                      6.33
                    ],
                    [
                      3.81,
                      6.36
                    ],
                    [
                      3.74,
                      6.39
                    ],
                    [
                      3.68,
                      6.42
                    ],
                    [
                      3.62,
                      6.45
                    ],
                    [
                      3.56,
                      6.49
                    ],
                    [
                      3.5,
                      6.52
                    ],
                    [
                      3.44,
                      6.56
                    ],
                    [
                      3.38,
                      6.6
                    ],
                    [
                      3.32,
                      6.64
                    ],
                    [
                      3.26,
                      6.68
                    ],
                    [
                      3.21,
                      6.72
                    ],
                    [
                      3.16,
                      6.77
                    ],
                    [
                      3.1,
                      6.81
                    ],
                    [
                      3.05,
                      6.86
                    ],
                    [
                      3,
                      6.91
                    ],
                    [
                      2.95,
                      6.95
                    ],
                    [
                      2.9,
                      7
                    ],
                    [
                      2.85,
                      7.05
                    ],
                    [
                      2.81,
                      7.11
                    ],
                    [
                      2.76,
                      7.16
                    ],
                    [
                      2.72,
                      7.21
                    ],
                    [
                      2.68,
                      7.27
                    ],
                    [
                      2.63,
                      7.33
                    ],
                    [
                      2.6,
                      7.38
                    ],
                    [
                      2.56,
                      7.44
                    ],
                    [
                      2.52,
                      7.5
                    ],
                    [
                      2.48,
                      7.56
                    ],
                    [
                      2.45,
                      7.62
                    ],
                    [
                      2.42,
                      7.68
                    ],
                    [
                      2.39,
                      7.75
                    ],
                    [
                      2.36,
                      7.81
                    ],
                    [
                      2.33,
                      7.88
                    ],
                    [
                      2.3,
                      7.94
                    ],
                    [
                      2.28,
                      8.01
                    ],
                    [
                      2.25,
                      8.07
                    ],
                    [
                      2.23,
                      8.14
                    ],
                    [
                      2.21,
                      8.21
                    ],
                    [
                      2.19,
                      8.28
                    ],
                    [
                      2.17,
                      8.35
                    ],
                    [
                      2.16,
                      8.42
                    ],
                    [
                      2.15,
                      8.49
                    ],
                    [
                      2.13,
                      8.56
                    ],
                    [
                      2.12,
                      8.64
                    ],
                    [
                      2.11,
                      8.71
                    ],
                    [
                      2.11,
                      8.78
                    ],
                    [
                      2.1,
                      8.86
                    ],
                    [
                      2.1,
                      8.93
                    ],
                    [
                      2.1,
                      9
                    ],
                    [
                      2.1,
                      9.08
                    ],
                    [
                      2.1,
                      9.15
                    ],
                    [
                      2.11,
                      9.23
                    ],
                    [
                      2.11,
                      9.3
                    ],
                    [
                      2.12,
                      9.37
                    ],
                    [
                      2.13,
                      9.45
                    ],
                    [
                      2.15,
                      9.52
                    ],
                    [
                      2.16,
                      9.59
                    ],
                    [
                      2.17,
                      9.66
                    ],
                    [
                      2.19,
                      9.73
                    ],
                    [
                      2.21,
                      9.8
                    ],
                    [
                      2.23,
                      9.87
                    ],
                    [
                      2.25,
                      9.93
                    ],
                    [
                      2.28,
                      10
                    ],
                    [
                      2.3,
                      10.07
                    ],
                    [
                      2.33,
                      10.13
                    ],
                    [
                      2.36,
                      10.2
                    ],
                    [
                      2.39,
                      10.26
                    ],
                    [
                      2.42,
                      10.33
                    ],
                    [
                      2.45,
                      10.39
                    ],
                    [
                      2.48,
                      10.45
                    ],
                    [
                      2.52,
                      10.51
                    ],
                    [
                      2.56,
                      10.57
                    ],
                    [
                      2.6,
                      10.63
                    ],
                    [
                      2.63,
                      10.68
                    ],
                    [
                      2.68,
                      10.74
                    ],
                    [
                      2.72,
                      10.8
                    ],
                    [
                      2.76,
                      10.85
                    ],
                    [
                      2.81,
                      10.9
                    ],
                    [
                      2.85,
                      10.95
                    ],
                    [
                      2.9,
                      11.01
                    ],
                    [
                      2.95,
                      11.06
                    ],
                    [
                      3,
                      11.1
                    ],
                    [
                      3.05,
                      11.15
                    ],
                    [
                      3.1,
                      11.2
                    ],
                    [
                      3.16,
                      11.24
                    ],
                    [
                      3.21,
                      11.29
                    ],
                    [
                      3.26,
                      11.33
                    ],
                    [
                      3.32,
                      11.37
                    ],
                    [
                      3.38,
                      11.41
                    ],
                    [
                      3.44,
                      11.45
                    ],
                    [
                      3.5,
                      11.48
                    ],
                    [
                      3.56,
                      11.52
                    ],
                    [
                      3.62,
                      11.55
                    ],
                    [
                      3.68,
                      11.59
                    ],
                    [
                      3.74,
                      11.62
                    ],
                    [
                      3.81,
                      11.65
                    ],
                    [
                      3.87,
                      11.68
                    ],
                    [
                      3.94,
                      11.7
                    ],
                    [
                      4,
                      11.73
                    ],
                    [
                      4.07,
                      11.75
                    ],
                    [
                      4.14,
                      11.77
                    ],
                    [
                      4.21,
                      11.79
                    ],
                    [
                      4.28,
                      11.81
                    ],
                    [
                      4.34,
                      11.83
                    ],
                    [
                      4.42,
                      11.85
                    ],
                    [
                      4.49,
                      11.86
                    ],
                    [
                      4.56,
                      11.87
                    ],
                    [
                      4.63,
                      11.88
                    ],
                    [
                      4.7,
                      11.89
                    ],
                    [
                      4.78,
                      11.9
                    ],
                    [
                      4.85,
                      11.9
                    ],
                    [
                      4.93,
                      11.9
                    ],
                    [
                      5,
                      11.9
                    ],
                    [
                      5.07,
                      11.9
                    ],
                    [
                      5.14,
                      11.9
                    ],
                    [
                      5.2,
                      11.89
                    ],
                    [
                      5.27,
                      11.88
                    ],
                    [
                      5.37,
                      11.87
                    ],
                    [
                      6.3,
                      14
                    ],
                    [
                      4,
                      14
                    ],
                    [
                      4,
                      15
                    ],
                    [
                      7,
                      15
                    ],
                    [
                      7,
                      14
                    ],
                    [
                      11.42,
                      14
                    ],
                    [
                      10.9,
                      16
                    ],
                    [
                      8,
                      16
                    ],
                    [
                      8,
                      17
                    ],
                    [
                      11.5,
                      17
                    ]
                  ],
                  [
                    [
                      5,
                      6.93
                    ],
                    [
                      5.11,
                      6.94
                    ],
                    [
                      5.21,
                      6.94
                    ],
                    [
                      5.31,
                      6.96
                    ],
                    [
                      5.42,
                      6.98
                    ],
                    [
                      5.52,
                      7
                    ],
                    [
                      5.61,
                      7.03
                    ],
                    [
                      5.71,
                      7.06
                    ],
                    [
                      5.8,
                      7.1
                    ],
                    [
                      5.9,
                      7.14
                    ],
                    [
                      5.98,
                      7.18
                    ],
                    [
                      6.07,
                      7.23
                    ],
                    [
                      6.16,
                      7.29
                    ],
                    [
                      6.24,
                      7.35
                    ],
                    [
                      6.32,
                      7.41
                    ],
                    [
                      6.39,
                      7.47
                    ],
                    [
                      6.46,
                      7.54
                    ],
                    [
                      6.53,
                      7.61
                    ],
                    [
                      6.6,
                      7.69
                    ],
                    [
                      6.66,
                      7.77
                    ],
                    [
                      6.72,
                      7.85
                    ],
                    [
                      6.77,
                      7.93
                    ],
                    [
                      6.82,
                      8.02
                    ],
                    [
                      6.87,
                      8.11
                    ],
                    [
                      6.91,
                      8.2
                    ],
                    [
                      6.94,
                      8.29
                    ],
                    [
                      6.98,
                      8.39
                    ],
                    [
                      7,
                      8.49
                    ],
                    [
                      7.03,
                      8.59
                    ],
                    [
                      7.05,
                      8.69
                    ],
                    [
                      7.06,
                      8.79
                    ],
                    [
                      7.07,
                      8.9
                    ],
                    [
                      7.07,
                      9
                    ],
                    [
                      7.07,
                      9.11
                    ],
                    [
                      7.06,
                      9.22
                    ],
                    [
                      7.05,
                      9.32
                    ],
                    [
                      7.03,
                      9.42
                    ],
                    [
                      7,
                      9.52
                    ],
                    [
                      6.98,
                      9.62
                    ],
                    [
                      6.94,
                      9.72
                    ],
                    [
                      6.91,
                      9.81
                    ],
                    [
                      6.86,
                      9.9
                    ],
                    [
                      6.82,
                      9.99
                    ],
                    [
                      6.77,
                      10.08
                    ],
                    [
                      6.71,
                      10.16
                    ],
                    [
                      6.66,
                      10.25
                    ],
                    [
                      6.59,
                      10.32
                    ],
                    [
                      6.53,
                      10.4
                    ],
                    [
                      6.46,
                      10.47
                    ],
                    [
                      6.39,
                      10.54
                    ],
                    [
                      6.31,
                      10.6
                    ],
                    [
                      6.23,
                      10.67
                    ],
                    [
                      6.15,
                      10.72
                    ],
                    [
                      6.07,
                      10.78
                    ],
                    [
                      5.98,
                      10.83
                    ],
                    [
                      5.89,
                      10.87
                    ],
                    [
                      5.8,
                      10.91
                    ],
                    [
                      5.71,
                      10.95
                    ],
                    [
                      5.61,
                      10.98
                    ],
                    [
                      5.51,
                      11.01
                    ],
                    [
                      5.41,
                      11.03
                    ],
                    [
                      5.31,
                      11.05
                    ],
                    [
                      5.21,
                      11.07
                    ],
                    [
                      5.11,
                      11.07
                    ],
                    [
                      5,
                      11.08
                    ],
                    [
                      4.89,
                      11.07
                    ],
                    [
                      4.79,
                      11.07
                    ],
                    [
                      4.68,
                      11.05
                    ],
                    [
                      4.58,
                      11.03
                    ],
                    [
                      4.48,
                      11.01
                    ],
                    [
                      4.38,
                      10.98
                    ],
                    [
                      4.29,
                      10.95
                    ],
                    [
                      4.19,
                      10.91
                    ],
                    [
                      4.1,
                      10.87
                    ],
                    [
                      4.01,
                      10.83
                    ],
                    [
                      3.93,
                      10.78
                    ],
                    [
                      3.84,
                      10.72
                    ],
                    [
                      3.76,
                      10.66
                    ],
                    [
                      3.68,
                      10.6
                    ],
                    [
                      3.61,
                      10.54
                    ],
                    [
                      3.54,
                      10.47
                    ],
                    [
                      3.47,
                      10.4
                    ],
                    [
                      3.4,
                      10.32
                    ],
                    [
                      3.34,
                      10.24
                    ],
                    [
                      3.28,
                      10.16
                    ],
                    [
                      3.23,
                      10.08
                    ],
                    [
                      3.18,
                      9.99
                    ],
                    [
                      3.13,
                      9.9
                    ],
                    [
                      3.09,
                      9.81
                    ],
                    [
                      3.05,
                      9.72
                    ],
                    [
                      3.02,
                      9.62
                    ],
                    [
                      2.99,
                      9.52
                    ],
                    [
                      2.97,
                      9.42
                    ],
                    [
                      2.95,
                      9.32
                    ],
                    [
                      2.94,
                      9.22
                    ],
                    [
                      2.93,
                      9.11
                    ],
                    [
                      2.93,
                      9
                    ],
                    [
                      2.93,
                      8.9
                    ],
                    [
                      2.94,
                      8.79
                    ],
                    [
                      2.95,
                      8.69
                    ],
                    [
                      2.97,
                      8.59
                    ],
                    [
                      2.99,
                      8.49
                    ],
                    [
                      3.02,
                      8.39
                    ],
                    [
                      3.05,
                      8.29
                    ],
                    [
                      3.09,
                      8.2
                    ],
                    [
                      3.13,
                      8.11
                    ],
                    [
                      3.18,
                      8.02
                    ],
                    [
                      3.23,
                      7.93
                    ],
                    [
                      3.28,
                      7.85
                    ],
                    [
                      3.34,
                      7.77
                    ],
                    [
                      3.4,
                      7.69
                    ],
                    [
                      3.47,
                      7.61
                    ],
                    [
                      3.54,
                      7.54
                    ],
                    [
                      3.61,
                      7.47
                    ],
                    [
                      3.68,
                      7.41
                    ],
                    [
                      3.76,
                      7.35
                    ],
                    [
                      3.84,
                      7.29
                    ],
                    [
                      3.93,
                      7.23
                    ],
                    [
                      4.01,
                      7.18
                    ],
                    [
                      4.1,
                      7.14
                    ],
                    [
                      4.19,
                      7.1
                    ],
                    [
                      4.29,
                      7.06
                    ],
                    [
                      4.38,
                      7.03
                    ],
                    [
                      4.48,
                      7
                    ],
                    [
                      4.58,
                      6.98
                    ],
                    [
                      4.68,
                      6.96
                    ],
                    [
                      4.79,
                      6.94
                    ],
                    [
                      4.89,
                      6.94
                    ],
                    [
                      5,
                      6.93
                    ]
                  ],
                  [
                    [
                      7.59,
                      10.29
                    ],
                    [
                      10.81,
                      13
                    ],
                    [
                      10.81,
                      13
                    ],
                    [
                      6.57,
                      13
                    ],
                    [
                      6.01,
                      11.71
                    ],
                    [
                      6.07,
                      11.69
                    ],
                    [
                      6.13,
                      11.66
                    ],
                    [
                      6.2,
                      11.64
                    ],
                    [
                      6.26,
                      11.61
                    ],
                    [
                      6.32,
                      11.58
                    ],
                    [
                      6.38,
                      11.54
                    ],
                    [
                      6.44,
                      11.51
                    ],
                    [
                      6.5,
                      11.48
                    ],
                    [
                      6.56,
                      11.44
                    ],
                    [
                      6.61,
                      11.4
                    ],
                    [
                      6.67,
                      11.36
                    ],
                    [
                      6.72,
                      11.32
                    ],
                    [
                      6.78,
                      11.28
                    ],
                    [
                      6.83,
                      11.24
                    ],
                    [
                      6.88,
                      11.2
                    ],
                    [
                      6.93,
                      11.15
                    ],
                    [
                      6.98,
                      11.11
                    ],
                    [
                      7.03,
                      11.06
                    ],
                    [
                      7.08,
                      11.01
                    ],
                    [
                      7.13,
                      10.96
                    ],
                    [
                      7.17,
                      10.91
                    ],
                    [
                      7.21,
                      10.86
                    ],
                    [
                      7.26,
                      10.81
                    ],
                    [
                      7.3,
                      10.76
                    ],
                    [
                      7.34,
                      10.7
                    ],
                    [
                      7.38,
                      10.65
                    ],
                    [
                      7.42,
                      10.59
                    ],
                    [
                      7.45,
                      10.53
                    ],
                    [
                      7.49,
                      10.47
                    ],
                    [
                      7.52,
                      10.42
                    ],
                    [
                      7.55,
                      10.36
                    ],
                    [
                      7.59,
                      10.29
                    ]
                  ]
                ]
              },
              "symbol": {
                "type": "CIMPolygonSymbol",
                "symbolLayers": [
                  {
                    "type": "CIMSolidFill",
                    "enable": true,
                    "color": [
                      255,
                      255,
                      255,
                      255
                    ]
                  }
                ],
                "angleAlignment": "Map"
              }
            }
          ],
          "scaleSymbolsProportionally": true,
          "respectFrame": true
        },
        {
          "type": "CIMVectorMarker",
          "enable": true,
          "anchorPoint": {
            "x": 0,
            "y": 0,
            "z": 0
          },
          "anchorPointUnits": "Relative",
          "dominantSizeAxis3D": "Y",
          "size": 29.5,
          "billboardMode3D": "FaceNearPlane",
          "frame": {
            "xmin": 0,
            "ymin": 0,
            "xmax": 21,
            "ymax": 21
          },
          "markerGraphics": [
            {
              "type": "CIMMarkerGraphic",
              "geometry": {
                "rings": [
                  [
                    [
                      17.17,
                      14.33
                    ],
                    [
                      16.97,
                      12.96
                    ],
                    [
                      16.38,
                      11.37
                    ],
                    [
                      12.16,
                      3.98
                    ],
                    [
                      11.2,
                      1.94
                    ],
                    [
                      10.5,
                      0
                    ],
                    [
                      9.8,
                      1.96
                    ],
                    [
                      8.84,
                      4.02
                    ],
                    [
                      4.61,
                      11.41
                    ],
                    [
                      4.02,
                      12.98
                    ],
                    [
                      3.83,
                      14.33
                    ],
                    [
                      3.96,
                      15.63
                    ],
                    [
                      4.34,
                      16.88
                    ],
                    [
                      4.95,
                      18.03
                    ],
                    [
                      5.78,
                      19.04
                    ],
                    [
                      6.8,
                      19.88
                    ],
                    [
                      7.95,
                      20.49
                    ],
                    [
                      9.2,
                      20.87
                    ],
                    [
                      10.5,
                      21
                    ],
                    [
                      11.8,
                      20.87
                    ],
                    [
                      13.05,
                      20.5
                    ],
                    [
                      14.2,
                      19.88
                    ],
                    [
                      15.22,
                      19.05
                    ],
                    [
                      16.05,
                      18.03
                    ],
                    [
                      16.66,
                      16.88
                    ],
                    [
                      17.04,
                      15.63
                    ],
                    [
                      17.17,
                      14.33
                    ]
                  ]
                ]
              },
              "symbol": {
                "type": "CIMPolygonSymbol",
                "symbolLayers": [
                  {
                    "type": "CIMSolidStroke",
                    "effects": [
                      {
                        "type": "CIMGeometricEffectOffset",
                        "method": "Square",
                        "offset": -0.25,
                        "option": "Fast"
                      }
                    ],
                    "enable": true,
                    "colorLocked": true,
                    "capStyle": "Round",
                    "joinStyle": "Round",
                    "lineStyle3D": "Strip",
                    "miterLimit": 10,
                    "width": 0.5,
                    "height3D": 1,
                    "anchor3D": "Center",
                    "color": [
                      0,
                      0,
                      0,
                      64
                    ]
                  },
                  {
                    "type": "CIMSolidFill",
                    "enable": true,
                    "color": [
                      37,
                      40,
                      81,
                      255
                    ]
                  }
                ],
                "angleAlignment": "Map"
              }
            }
          ],
          "scaleSymbolsProportionally": true,
          "respectFrame": true
        }
      ],
      "haloSize": 1,
      "scaleX": 1,
      "angleAlignment": "Display"
    }
  }
});

export const parkingBikeRenderer = {
  type: "simple", // autocasts as new SimpleRenderer()
  symbol: cimBikeParkingSymbol
};


