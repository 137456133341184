import React, { useEffect, useRef, useContext, useState, forwardRef } from "react";
import "../widgets/widgetcss/styles.css";
import { MapContext } from "../../../../MapContext";


export default function SearchToGraphic () {

    const mapContext = useContext(MapContext);
    const [btnState, setBtnState] = useState(false);

      function enableSearchState () {
        
        
        mapContext.dispatch({
            searchFlag: 1,
        });  

        if (mapContext.state.searchToGraphic === false ){
            var element = document.getElementById("searchBtn");
            element.classList.add("Active");
            //console.log(mapContext.state.searchToGraphic)
        }
        if (mapContext.state.searchToGraphic === true) {
            
            var element = document.getElementById("searchBtn");
            element.classList.remove("Active");
            //console.log(mapContext.state.searchToGraphic)
        }

        mapContext.dispatch({
            searchToGraphic: !mapContext.state.searchToGraphic,
        });  
      }

      


    return (
        <>
         {mapContext.state.dateRange === null  ? <> </> : <button className="SearchBtn SidebarBtn ClearBtn " id="searchBtn" onClick={() => {enableSearchState()}}>Pasirinkti gatvę</button>}
         
        </>
    )
}