import React from 'react'
import  './__mocks__/@arcgis/core/widgets/widgetcss/styles.css'
import './Judumas.css'
export default function Header() {
  return (
    <div className='Header'>
        {"VILNIAUS MIESTO JUDUMO ŽEMĖLAPIS"}
    </div>
  )
}
