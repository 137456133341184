

export default {
    strava_bike_day_url: "https://opencity.vplanas.lt/map/rest/services/P_Judumas/Strava_trips/FeatureServer/6",//strava dvir
    strava_bike_month_url:"https://opencity.vplanas.lt/map/rest/services/P_Judumas/Strava_trips/FeatureServer/8",//strava dviratininkai
    strava_bike_year_url: "https://opencity.vplanas.lt/map/rest/services/P_Judumas/Strava_trips/FeatureServer/9",
    strava_bike_week_url: "https://opencity.vplanas.lt/map/rest/services/P_Judumas/Strava_trips/FeatureServer/7",

    waze_jams:"https://services1.arcgis.com/usA3lHW20rGU6glp/arcgis/rest/services/waze_jams/FeatureServer/1",// waze duomenys

    strava_pedest_day_url:"https://opencity.vplanas.lt/map/rest/services/P_Judumas/Strava_trips/FeatureServer/1",//strava pestieji diena
    strava_pedest_month_url:"https://opencity.vplanas.lt/map/rest/services/P_Judumas/Strava_trips/FeatureServer/3",//strava pestieji menesis
    strava_pedest_year_url: "https://opencity.vplanas.lt/map/rest/services/P_Judumas/Strava_trips/FeatureServer/4",
    strava_pedest_week_url: "https://opencity.vplanas.lt/map/rest/services/P_Judumas/Strava_trips/FeatureServer/2",

    op_car_day_url: "https://opencity.vplanas.lt/map/rest/services/P_Judumas/Judumas_OP_data_update/FeatureServer/1",
    op_car_week_url: "https://opencity.vplanas.lt/map/rest/services/P_Judumas/Judumas_OP_data_update/FeatureServer/2",
    op_car_month_url: "https://opencity.vplanas.lt/map/rest/services/P_Judumas/Judumas_OP_data_update/FeatureServer/3",
    op_car_year_url: "https://opencity.vplanas.lt/map/rest/services/P_Judumas/Judumas_OP_data_update/FeatureServer/4",

    op_bike_day_url: "https://opencity.vplanas.lt/map/rest/services/P_Judumas/Judumas_OP_data_update/FeatureServer/5",
    op_bike_week_url: "https://opencity.vplanas.lt/map/rest/services/P_Judumas/Judumas_OP_data_update/FeatureServer/6",
    op_bike_month_url: "https://opencity.vplanas.lt/map/rest/services/P_Judumas/Judumas_OP_data_update/FeatureServer/7",
    op_bike_year_url: "https://opencity.vplanas.lt/map/rest/services/P_Judumas/Judumas_OP_data_update/FeatureServer/8",

    op_pedest_day_url: "https://opencity.vplanas.lt/map/rest/services/P_Judumas/Judumas_OP_data_update/FeatureServer/9",
    op_pedest_week_url: "https://opencity.vplanas.lt/map/rest/services/P_Judumas/Judumas_OP_data_update/FeatureServer/10",
    op_pedest_month_url: "https://opencity.vplanas.lt/map/rest/services/P_Judumas/Judumas_OP_data_update/FeatureServer/11",
    op_pedest_year_url: "https://opencity.vplanas.lt/map/rest/services/P_Judumas/Judumas_OP_data_update/FeatureServer/12",

    op_bus_day_url: "https://opencity.vplanas.lt/map/rest/services/P_Judumas/Judumas_OP_data_update/FeatureServer/13",
    op_bus_week_url: "https://opencity.vplanas.lt/map/rest/services/P_Judumas/Judumas_OP_data_update/FeatureServer/14",
    op_bus_month_url: "https://opencity.vplanas.lt/map/rest/services/P_Judumas/Judumas_OP_data_update/FeatureServer/15",
    op_bus_year_url: "https://opencity.vplanas.lt/map/rest/services/P_Judumas/Judumas_OP_data_update/FeatureServer/16",

    operator:"https://opencity.vplanas.lt/map/rest/services/P_Judumas/Judumas_OP_data_update/FeatureServer/0",
    operatorData:"https://opencity.vplanas.lt/map/rest/services/P_Judumas/Judumas_OP_data_update/FeatureServer/101",
    operatorAvailableDates: "https://opencity.vplanas.lt/map/rest/services/P_Judumas/Judumas_OP_data_update/FeatureServer/100",
    operatorBikeData: "https://opencity.vplanas.lt/map/rest/services/P_Judumas/Judumas_OP_data_update/FeatureServer/102",
    operatorPedestrianData: "https://opencity.vplanas.lt/map/rest/services/P_Judumas/Judumas_OP_data_update/FeatureServer/103",
    operatorBusData: "https://opencity.vplanas.lt/map/rest/services/P_Judumas/Judumas_OP_data_update/FeatureServer/104",

    extent: "https://opencity.vplanas.lt/server/rest/services/Hosted/extentas_line/FeatureServer/2",

    hexGrid: "https://opencity.vplanas.lt/server/rest/services/Hosted/Hexagon250/FeatureServer/39",

    nightTable: "https://opencity.vplanas.lt/server/rest/services/Hosted/NK_data/FeatureServer/3",
    dayTable: "https://opencity.vplanas.lt/server/rest/services/Hosted/DN_data/FeatureServer/2",

    infoTable: "https://opencity.vplanas.lt/map/rest/services/P_Judumas/Judumas_OP_data_update/FeatureServer/105",
    
    meteo: "https://opencity.vplanas.lt/map/rest/services/Aplinkosauga/Meteo_oro_salygos/MapServer/1",
    // Automobilio operatoriu duomenys
    juduData: "https://arcgis.sisp.lt/arcgis/rest/services/VIS/Traffic_Volumes/MapServer/0"
}