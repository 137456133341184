import React, { useContext, useState } from "react";

import bikeInactive from "../icons/BikeInactive.png";
import bikeActive from "../icons/BikeActive.png";
import bikeDisabled from "../icons/BikeDisabled.png";

import carInactive from "../icons/CarInactive.png";
import carActive from "../icons/CarActive.png";
import carDisabled from "../icons/CarDisabled.png";

import busInactive from "../icons/BusInactive.png";
import busActive from "../icons/BusActive.png";
import busDisabled from "../icons/BusDisabled.png";

import humanInactive from "../icons/HumanInactive.png";
import humanActive from "../icons/HumanActive.png";
import humanDisabled from "../icons/HumanDisabled.png";

import "../widgets/widgetcss/styles.css";
import "../../../../Judumas.css"
import { existingHeatmapDataSource } from "../../../../config";

import { MapContext } from "../../../../MapContext";
import { operatorBikeData, operatorData, operatorBusData, operatorPedestrianData } from "../../../../Services/serviceLayers";

export default function IconWidgetMulti({ handleBike, handleCar, handleBus, handleHuman, availData }) {
  const mapContext = useContext(MapContext);
  const [compareLayerList, setCompareLayerList] = useState([operatorData]);
 /*
  function waitForElm(selector) {
    return new Promise((resolve) => {
      if (document.querySelector(selector)) {
        return resolve(document.querySelectorAll(selector));
      }

      const observer = new MutationObserver((mutations) => {
        if (document.querySelector(selector)) {
          resolve(document.querySelectorAll(selector));
          observer.disconnect();
        }
      });

      observer.observe(document.body, {
        childList: true,
        subtree: true,
      });
    });
  }
 
  waitForElm(".ready3").then((button) => {
    button.forEach((element) => {
      element.addEventListener("click", function () {
        button.forEach((nav) => nav.classList.remove("active"));
        this.classList.add("active");
      });
    });
  });

  waitForElm(".Icon").then((button) => {
    button.forEach((element) => {
      element.addEventListener("click", function () {
        // Ismest kai turesim autobusus
        if (element.id !== "BusIcon") {
          button.forEach((nav) => nav.classList.remove("Active"));
          this.classList.add("Active");

        }
      });
    });
  });
*/
  function multiSelect(id) {
    let selectedElement = document.getElementById(id);
    let childImg = selectedElement.firstElementChild
    if (selectedElement.classList.contains("Disabled")) {
      return
    } else if (selectedElement.classList.contains("Active")) {
      selectedElement.classList.remove("Active")
      childImg.classList.remove("active")
      updateLayerList(id, 0)
    } else {
      selectedElement.classList.add("Active");
      childImg.classList.add("active")
      updateLayerList(id, 1)
    }
  }
/*
  function transportationChange(transportationContext) {
    if (mapContext.state.transportation == transportationContext || transportationContext === null) return;
    mapContext.state.featureLayer.visible = false;

    mapContext.dispatch({
      transportation: transportationContext,
    });

    for (let i = 0; i < existingHeatmapDataSource.length; i++) {
      console.log("test", transportationContext.toLowerCase(), existingHeatmapDataSource[i][0]);
      if (transportationContext.toLowerCase() === existingHeatmapDataSource[i][0].toLowerCase()) {
        if (existingHeatmapDataSource[i].includes(mapContext.state.data)) {
        } else {
          mapContext.dispatch({
            data: existingHeatmapDataSource[i][1],
          });
        }
      }
    }
  }
*/
  //0- remove , 1-add
  function updateLayerList(id, addOrRemove){
    let clickedLayer = compareLayers(id)
    console.log(clickedLayer)
    let compareLayerListCopy = [...compareLayerList]
    if (addOrRemove === 0) {
        let removeIndex = compareLayerListCopy.indexOf(clickedLayer)
        compareLayerListCopy.splice(removeIndex, 1)
    }
    if (addOrRemove === 1) {
      compareLayerListCopy.push(clickedLayer)
    }
    console.log("compareLayerListCopy")
    console.log(compareLayerListCopy)
    setCompareLayerList(compareLayerListCopy)
    mapContext.dispatch({
      compareLayers: compareLayerListCopy
    });
  }

  function compareLayers(id) {
    console.log(id)
    let compareLayersObj = {
      BikeIcon: 
         operatorBikeData,
      
      CarIcon: operatorData,
      BusIcon: operatorBusData,
      HumanIcon: operatorPedestrianData,
    }
    return compareLayersObj[id]
  }

  function isActive(id) {
    //console.log(mapContext.state.transportation);
    let transportObject = {
      BikeIconImg: [bikeActive, bikeInactive],
      CarIconImg: [carActive, carInactive],
      BusIconImg: [busActive, busInactive],
      HumanIconImg: [humanActive, humanInactive],
    };
    return document.getElementById(id).classList.contains("active") ? transportObject[id][0] : transportObject[id][1];
  }

  return (
    <div className="IconWidget">
      {"Pasirinkite judėjimo būdą:"}
      <br />
      <div id="BikeIcon" className={availData.includes("Bike") ? "IconContainer Icon" : "IconContainer Icon Disabled"} onClick={() => {
        multiSelect("BikeIcon");
      }}>
        <img
          id="BikeIconImg"
          className={availData.includes("Bike") ? "ready3 img padding  " : "notready3 img padding  "}
          src={availData.includes("Bike") ? isActive("BikeIconImg") : bikeDisabled}
          alt="bike"

        ></img>
      </div>

      <div id="BusIcon" className={availData.includes("Bus") ? "IconContainer Icon" : "IconContainer Icon Disabled"}>
        <img
          id="BusIconImg"
          className={availData.includes("Bus") ? "ready3 img padding  " : "notready3 img padding  "}
          src={availData.includes("Bus") ? isActive("BusIconImg") : busDisabled}
          alt="bus"
          onClick={() => {
            multiSelect("BusIcon");
          }}
        ></img>
      </div>
      <br />
      <div id="CarIcon" className="IconContainer Icon Active" onClick={() => {
        multiSelect("CarIcon");
      }}>
        <img
          id="CarIconImg"
          className={availData.includes("Car") ? "ready3 img padding active" : "notready3 img padding  "}
          src={availData.includes("Car") ? isActive("CarIconImg") : bikeDisabled}
          alt="car"

        ></img>
      </div>

      <div id="HumanIcon" className={availData.includes("Human") ? "IconContainer Icon" : "IconContainer Icon Disabled"} onClick={() => {
        multiSelect("HumanIcon");
      }}>
        <img
          id="HumanIconImg"
          className={availData.includes("Human") ? "ready3 img padding  " : "notready3 img padding  "}
          src={availData.includes("Human") ? isActive("HumanIconImg") : humanDisabled}
          alt="pedestrian"

        ></img>
      </div>
    </div>
  );
}
