import React, { useContext } from "react";
import "../widgets/widgetcss/styles.css";
import { MapContext } from "../../../../MapContext";
import "../../../../Judumas.css";
import { juduLayer } from "../../../../Services/serviceLayers";

export default function DataWidget({ handleStrava, handleSISP, handleOperator }) {
  const mapContext = useContext(MapContext);

  function waitForElm(selector) {
    return new Promise((resolve) => {
      if (document.querySelector(selector)) {
        return resolve(document.querySelectorAll(selector));
      }

      const observer = new MutationObserver((mutations) => {
        if (document.querySelector(selector)) {
          resolve(document.querySelectorAll(selector));
          observer.disconnect();
        }
      });

      observer.observe(document.body, {
        childList: true,
        subtree: true,
      });
    });
  }
  waitForElm(".Data").then((button) => {
    button.forEach((element) => {
      element.addEventListener("click", function () {
        button.forEach((nav) => nav.classList.remove("Active"));
        this.classList.add("Active");
      });
    });
  });

  function changeData(dataContext) {
    juduLayer.visible = false;
    console.log(mapContext.state.data, dataContext)
    
    if (mapContext.state.data == dataContext || dataContext === null || mapContext.state.data.toLowerCase() === dataContext.toLowerCase()) return;

    mapContext.state.featureLayer.visible = false;
    if (mapContext.state.data == "SISP") {
      juduLayer.visible = false;
      mapContext.state.featureLayer.visible = true;
    }
    mapContext.dispatch({
      data: dataContext,
    });
  }

  // Judu layeris tampa problematiskais kai pridedu ji kaip feature layeri, todel ji renderint reikia tiesiai is servisu.
  function changeJudu(dataContext) {
    console.log(mapContext.state.data, dataContext)
    mapContext.state.featureLayer.visible = false;
    juduLayer.visible = true;
    mapContext.dispatch({
      data: dataContext,
    });
    console.log(mapContext.state.data, dataContext)
  }

  return (
    <>
      <div className="DataWidget">
        {"Pasirinkite duomenų šaltinį:"}
        <div className="">
          {mapContext.state.transportation === "Pedestrian" || mapContext.state.transportation === "Bike" ? (
            <button
              id = "DataActiveVilnius"
              onClick={() => { 
                changeData(handleStrava);
              }}
              className="IconContainer Data Active"
            >
              Active Vilnius
            </button>
          ) : (
            <></>
          )}
          {mapContext.state.transportation === "TODO" ? (
            <button
              onClick={() => {
                changeData("Waze");
              }}
              className="button-23 ready"
            >
              Waze
            </button>
          ) : (
            <></>
          )}
          {mapContext.state.transportation === "Car" ? (
            <button
              onClick={() => {
                changeJudu(handleSISP);
              }}
              className="IconContainer Data"
            >
              Judu
            </button>
          ) : (
            <></>
          )}
          {mapContext.state.transportation === "Car" || mapContext.state.transportation === "Bus"? (
            <button
              onClick={() => {
                changeData(handleOperator);
              }}
              className="IconContainer Data Active"
            >
              Operatorių
            </button>
          ) : 

          
            mapContext.state.transportation === "Bike" ?
            (
              <button
                onClick={() => {
                  changeData(handleOperator);
                }}
                className="IconContainer Data "
              >
                Operatorių
              </button>
            )
            :
          
          (
            <>
              {mapContext.state.transportation === "Pedestrian"  ? (
                <button
                  onClick={() => {
                    changeData(handleOperator);
                  }}
                  className="IconContainer Data "
                  
                >
                  Operatorių
                </button>
              ) : (
                <></>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
}
