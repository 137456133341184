import React, { useState } from "react";

import Appv2 from './Appv2';

import "./App.css";







function Login() {

  const [authorization, setAuthorization] = useState(false);


  function authenticate(username, password){
    if ((username === "VP" && password === "JudrusAutobusas") || (username === "VMSA" && password === "JudrusAutomobilis") || (username === "SISP" && password === "JudrusDviratis") ) {
      setAuthorization(true);
    }
  }

  return(
    authorization
    ?
    <Appv2/>
    :
    <>
    <input type="text" id="username" placeholder="username"/><br/>
    <input type="password" id="password" placeholder="password"/><br/>
    <input type="button" value="Login" onClick={() => {authenticate( document.getElementById("username").value,  document.getElementById("password").value)}}>
    </input>
    </>
  
  ) ;
}

export default Login;
