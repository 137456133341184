import React, { useContext, useState, useEffect } from "react";
import Graphic from "@arcgis/core/Graphic";
import "../../../../Judumas.css";
import { MapContext } from "../../../../MapContext.js";
import { queryFeaturesOverLimit2k } from "../../../../helpers/helper.js";
import Calendar from "./Calendar.js";
import { dayLayer, nightLayer, hexGrid } from "../../../../Services/serviceLayers";
import GraphicsLayer from "@arcgis/core/layers/GraphicsLayer";


import uniqueValues from "@arcgis/core/smartMapping/statistics/uniqueValues";




import dayActive from "../icons/dienaActive.png";
import dayInactive from "../icons/dienaInactive.png";
import nightActive from "../icons/naktisActive.png";
import nightInactive from "../icons/naktisInactive.png";

export default function Grid({ data, token }) {
  const mapContext = useContext(MapContext);

  const [initialRender, setInitialRender] = useState(0)

  const [gridSelected, setGridSelected] = useState(null);
  const [gridSidebarSelection, setGridSidebarSelection] = useState(0);
  const [gridData, setGridData] = useState([]);

  const [gridObjArr, setGridObjArr] = useState([])
  const [gridTextVisible, setGridTextVisible] = useState(true)

  const[calendarDates, setCalendarDates] = useState([])

  const[gridToggleArr, setGridToggleArr] = useState([true, true, true, false, false, true, true, true])

  let hexGraphicLayer = new GraphicsLayer();
  mapContext.state.mapView.map.add(hexGraphicLayer)
  const[graphicLayer, setGraphicLayer] = useState(hexGraphicLayer);

  const hexDay1 = hexGrid.clone()
  const hexDay2 = hexGrid.clone()
  const hexDay3 = hexGrid.clone()
  const hexDay4 = hexGrid.clone()
  const hexDay5 = hexGrid.clone()

  const hexNight1 = hexGrid.clone()
  const hexNight2 = hexGrid.clone()
  const hexNight3 = hexGrid.clone()
  const hexNight4 = hexGrid.clone()
  const hexNight5 = hexGrid.clone()

  function addSymbolsToGrids(colour, dayOrNight /*0 - day , 1 - night */) {
    let fillColor = [ 255, 0, 0, 0.2 ]
    let outlineColor = [ 178, 178, 178, 0.9 ]
    let oulineWidth = 0.7
    let fillColorArr

    if (dayOrNight === 1) {
      fillColorArr = nightColors()
    }
    if (dayOrNight === 0) {
      fillColorArr = dayColors()
    }
    console.log(fillColorArr)
    fillColor = fillColorArr[colour]
    
    
    let symbolRender = {
      type: "simple-fill",  
      color: fillColor,
      style: "solid",
      outline: { 
        color: outlineColor,
        width: oulineWidth
      }
    };

    let render = {
      type: "simple",
      symbol: symbolRender
    }

    return  render
  }

  function addGraphicLayerPolygon(gridArray, max) {
    //grid.usage, maxGridValue, grid.gridId
    const grids = [[], [], [], [], []];

    //const percentage = (gridArray.usage / gridArray.maxGridValue) * 100;
    console.log(gridArray)
    gridArray.forEach(grid => {
      const percentage = (grid.usage / max) * 100;


      if (percentage <= 20) {
        grids[0].push(grid.gridId);
      } else if (percentage <= 40) {
        grids[1].push(grid.gridId);
      } else if (percentage <= 60) {
        grids[2].push(grid.gridId);
      } else if (percentage <= 80) {
        grids[3].push(grid.gridId);
      } else {
        grids[4].push(grid.gridId);
      }
    })


    console.log(grids)
    function generateQuery(bracket, index) {
      const ids = bracket.map(gridId => gridId).join("', '");
      return `grid_id IN ('${ids}')`;
    }
    
    const queries = grids.map((bracket, index) => generateQuery(bracket, index));
    
    queries.forEach(query => console.log(query));

    /*hexNight1.definitionExpression = queries[0]
    hexNight1.visible = true
    hexNight2.definitionExpression = queries[0]
    hexNight2.visible = true
    hexNight3.definitionExpression = queries[0]
    hexNight3.visible = true
    hexNight4.definitionExpression = queries[0]
    hexNight4.visible = true*/
    let query = hexNight5.createQuery();
    query.where = queries[4];
    let features = hexNight5.queryFeatures(query)
    hexNight5.visible = true
  }
  
  

  function waitForElm(selector) {
    return new Promise((resolve) => {
      if (document.querySelector(selector)) {
        return resolve(document.querySelectorAll(selector));
      }

      const observer = new MutationObserver((mutations) => {
        if (document.querySelector(selector)) {
          resolve(document.querySelectorAll(selector));
          observer.disconnect();
        }
      });

      observer.observe(document.body, {
        childList: true,
        subtree: true,
      });
    });
  }

  const mergeArrays = (arr1, arr2) => {
    return arr1.reduce((acc, b) => {
      const idx = acc.findIndex(item => item.gridId === b.gridId); //look for the acc has the same id while iterating array1
      if (idx > -1) { // if found need to merge with value of array2
        acc[idx] = Object.assign(b, acc[idx]);
        return acc;
      }
      return [...acc]; //if we don't find anything so "b" is an unique entry
    }, arr2); //inital values of reduce is from array2
  
  }

  function toggleContextLayerVisibility(layerId) {
    mapContext.state.dataContextLayers.forEach((layer) => {
      if (layerId === layer.layerId) {
        layer.visible = !layer.visible;
      }
    });
  }

  function toggleContextLayerVisibilityURL(layerURL) {
    mapContext.state.dataContextLayers.forEach((layer) => {
      if (layerURL === layer.url) {
        layer.visible = !layer.visible;
      }
    });
  }

  function addGeometryToData(gridArray, geometryArray){

    geometryArray.forEach(elementGeo => {
      gridArray.forEach(elementGrid => {
        if (elementGrid.gridId === elementGeo.attributes.grid_id) {
          elementGrid.geometry = elementGeo.geometry
        }
      });
    });

    return gridArray
  }

  async function getGridGeometry(gridArray) {

    let queryWhere = "grid_id IN ('"
    let query = hexGrid.createQuery();
    let gridGeometryObjArray = []
    let i = 0
    gridArray.forEach(async (gridObj) => {
      queryWhere += gridObj.gridId + "'"
      i++
      if (i === 500){
        queryWhere += ")"
        i = 0;
        query = queryWhere
        let requestGrids = await hexGrid.queryFeatures(query)
        gridGeometryObjArray = [...gridGeometryObjArray, ...addGeometryToData(gridArray , requestGrids.features)]
        queryWhere = "grid_id IN ('"

        
      } else {
        queryWhere += ",'"
      }
    });
    queryWhere = queryWhere.slice(0,-2)
    queryWhere += ")"
    query.where = queryWhere


    let requestGrids = await hexGrid.queryFeatures(query)

    gridGeometryObjArray = [...gridGeometryObjArray, ...addGeometryToData(gridArray , requestGrids.features)]

    return gridGeometryObjArray
  }

  async function calculateGrids(gridResultArray, gridUserIdArray) {
    let gridDataArray = []
    gridResultArray.forEach(grid => {
      let gridObj = {
        gridId: grid.attributes.grid_id,
        usage: 0,
        object: grid,
        geometry: null
      }

      gridUserIdArray.forEach(user => {
        if (grid.attributes.f_sarasas1.includes(user)) {
          gridObj.usage++
        }
        if (grid.attributes.f_kiekis > 500) {
          if (grid.attributes.f_sarasas2.includes(user)) {
            gridObj.usage++
          }
        }
        if (grid.attributes.f_kiekis > 1000) {
          if (grid.attributes.f_sarasas3.includes(user)) {
            gridObj.usage++
          }
        }
        if (grid.attributes.f_kiekis > 1500) {
          if (grid.attributes.f_sarasas4.includes(user)) {
            gridObj.usage++
          }
        }
      });

      gridDataArray.push(gridObj)
    });
    
    gridDataArray = [...await getGridGeometry(gridDataArray)]

    return gridDataArray
  }

  function nightColors() {
    return [
      [87, 186, 250, 0.4],
      [61, 128, 205, 0.4],
      [101, 84, 174, 0.4],
      [63, 54, 158, 0.4],
      [46, 0, 93, 0.4]
    ]
  }

  function dayColors() {
    return [
      [255, 255, 178, 0.4],
      [254, 204, 92, 0.4],
      [253, 141, 60, 0.4],
      [240, 59, 32, 0.4],
      [189, 0, 38, 0.4]
    ]
  }

  function addGraphicPolygon(geometry, usage = 0, maxUsage = 0, gridId, clicked = 1) {
    let fillColor = [ 255, 0, 0, 0.2 ]
    let outlineColor = [ 178, 178, 178, 0.9 ]
    let oulineWidth = 0.7
    let fillColorArr
    if (gridSelected === 1) {
      fillColorArr = nightColors()
    }
    if (gridSelected === 0) {
      fillColorArr = dayColors()
    }
    fillColor = fillColorArr[4]
    if (usage <= maxUsage * 0.80) {
      fillColor = fillColorArr[3]
    }
    if (usage <= maxUsage * 0.60) {
      fillColor = fillColorArr[2]
    } 
    if (usage <= maxUsage * 0.40) {
      fillColor = fillColorArr[1]
    }
    if (usage <= maxUsage * 0.20) {
      fillColor = fillColorArr[0]
    }
    if (clicked === 1) {  
      if (mapContext.state.gridClickData.graphic.attributes.grid_id === gridId){
        fillColor = [ 255, 255, 255, 0.2 ]
        outlineColor = [ 178, 178, 178, 0.9 ]
        oulineWidth = 0.7
      }
    }
    
    let symbol = {
      type: "simple-fill",  
      color: fillColor,
      style: "solid",
      outline: { 
        color: outlineColor,
        width: oulineWidth
      }
    };

    let graphic = new Graphic(geometry, symbol);
    //mapContext.state.mapView.graphics.add(graphic);
    graphicLayer.add(graphic)
  }



  function addGraphicText(geometry, text, visibility = true) {
    let textSymbol = {
      type: "text",  
      color: "black",
      haloColor: [ 255,255,255, 0.8],
      haloSize: 1,
      text: text,
      xoffset: 0,
      yoffset: 0,
      font: { 
        size: 18,
        weight: "bold"
      }
    };

    let graphic = new Graphic(geometry, textSymbol);
    graphic.visible = visibility

    //mapContext.state.mapView.graphics.add(graphic);
    graphicLayer.add(graphic)
  }

  waitForElm(".SidebarGridCard").then((button) => {
    button.forEach((element) => {
      element.addEventListener("click", function () {
        button.forEach((nav) => nav.classList.remove("Active"));
        this.classList.add("Active");
      });
    });
  });

  waitForElm(".ButtonColor").then((button) => {
    button.forEach((element) => {
      element.addEventListener("click", function () {
        button.forEach((nav) => nav.classList.remove("Active"));
        this.classList.add("Active");
      });
    });
  });

  useEffect(() => {
    if (gridData.length === 0 ){
      return
    }
    //mapContext.state.mapView.graphics.removeAll();
    graphicLayer.removeAll()

    let symbol = {
      type: "simple-line",  // autocasts as new SimpleLineSymbol()
      color: [232,76,49, 1],
      width: "4px",
      style: "solid"
    };
    var graphic = new Graphic(mapContext.state.extentGeometry, symbol);
    //mapContext.state.mapView.graphics.add(graphic);
    graphicLayer.add(graphic)

    let gridTextVisibility = true

    if (mapContext.state.mapView.zoom < 14) {
      gridTextVisibility = false
    }

    let maxGridValue = Math.max(...gridData.map(singleGrid => singleGrid.usage))

    gridData.forEach(grid => {
      addGraphicText(grid.geometry.centroid, grid.usage, gridTextVisibility)
        addGraphicPolygon(grid.geometry, grid.usage, maxGridValue, grid.gridId, 1)

      
    });
    if (!gridData.find((grid) => grid.gridId === mapContext.state.gridClickData.graphic.attributes.grid_id)){
      addGraphicPolygon(mapContext.state.gridClickData.graphic.geometry, 0, maxGridValue, mapContext.state.gridClickData.graphic.attributes.grid_id, 1)
      //addGraphicText(mapContext.state.gridClickData.graphic.geometry.centroid, "0")
    }
    
    mapContext.dispatch({
      layerLoading: 0,
    });
  },[gridData])

  useEffect(() => {
    console.log("LayerLOADING " + mapContext.state.layerLoading)
  },[mapContext.state.layerLoading])

  useEffect(() =>{
    if (initialRender === 1) {
      return
    }
    setInitialRender(1)


    let hexGraphicLayer = new GraphicsLayer();
    mapContext.state.mapView.map.add(hexGraphicLayer)
    setGraphicLayer(hexGraphicLayer)
    
    


    let uniqueDates = [];
          //console.log("UniqueValues")
          uniqueValues({
            layer: dayLayer,
            field: "datetime",
          }).then(function (response) {
            // prints each unique value and the count of features containing that value
            console.log(response)
            let infos = response.uniqueValueInfos;
            let availableDatesLocal = [];
            let parsedDates = [];
            infos.forEach(function (info) {
              //console.log((info.value))
              //console.log( Math.floor(info.value/1000/60/60/24))
              //info.value = Math.floor((info.value) / 1000 / 60 / 60 / 24) * 24 * 60 * 60 * 1000;
              //info.value = info.value
              //console.log("date:" + info.value);
              //let addDate = new Date(info.value)
    
              if (!availableDatesLocal.includes(info.value)) {
                //let addDate = new Date(info.value)
                //console.log(date)
                //let splitDate = info.value.split('/')
                let addDate = new Date(info.value);
                parsedDates.push(addDate);
                availableDatesLocal.push(info.value);
                //setAvailableDates(availableDatesLocal)
                //console.log(availableDatesLocal)
              }
            });
          setCalendarDates(parsedDates);
          });
    
    let query = hexGrid.createQuery()
    query.where = "1=1"

    // Padaryt klonus kiekvienai spalvai ir pridet definition expressionus
    /*
    let clone  = hexGrid.clone()
    clone.renderer = {
      type: "simple",
      symbol: {
          type: "simple-fill",
          color: [
            255, 0, 0, 0.2
          ],
          outline: {
              type: "simple-line",
              color: [
                  110,
                  110,
                  110,
                  255
              ],
              width: 0.7,
              style: "solid"
          },
          style: "solid"
      }
    }
    let clone1  = hexGrid.clone()
    let clone2  = hexGrid.clone()
    let clone3  = hexGrid.clone()
    let clone4  = hexGrid.clone()
    let clone5  = hexGrid.clone()
    let clone6  = hexGrid.clone()
    let clone7  = hexGrid.clone()
    let clone8  = hexGrid.clone()
    let clone9  = hexGrid.clone()
    mapContext.state.mapView.map.add(clone)
    mapContext.state.mapView.map.add(clone1)
    mapContext.state.mapView.map.add(clone2)
    mapContext.state.mapView.map.add(clone3)
    mapContext.state.mapView.map.add(clone4)
    mapContext.state.mapView.map.add(clone5)
    mapContext.state.mapView.map.add(clone6)
    mapContext.state.mapView.map.add(clone7)
    mapContext.state.mapView.map.add(clone8)
    mapContext.state.mapView.map.add(clone9)
    */
    
    queryFeaturesOverLimit2k(hexGrid, query).then((results) => {
      
      setGridObjArr(results)
      
    })

  },[initialRender])

  useEffect(() => {
    //mapContext.state.mapView.zoom >= 14
    console.log(mapContext.state.mapView.graphics)
    console.log(graphicLayer)
    console.log(mapContext.state.mapView.zoom)
    console.log(gridTextVisible)
    if (gridTextVisible === true && mapContext.state.mapView.zoom < 14){
      //mapContext.state.mapView.graphics.items.forEach(element => {
        graphicLayer.graphics.items.forEach(element => {
        if (element.symbol.type === "text") {
          element.visible = false
        }
      });
      setGridTextVisible(false)
    }
    if (gridTextVisible === false && mapContext.state.mapView.zoom >= 14) {
      //mapContext.state.mapView.graphics.items.forEach(element => {
        graphicLayer.graphics.items.forEach(element => {
        if (element.symbol.type === "text") {
          element.visible = true
        }
      });
      setGridTextVisible(true)
    }
    
  },[mapContext.state.mapView.zoom])

  useEffect(() => {
    
    if (gridSelected === null || mapContext.state.dateRange === null || gridObjArr.length === 0 || mapContext.state.gridClickData !== null) {
      return
    }
    //mapContext.state.mapView.graphics.removeAll();
    graphicLayer.removeAll();
    let symbol = {
      type: "simple-line",  // autocasts as new SimpleLineSymbol()
      color: [232,76,49, 1],
      width: "4px",
      style: "solid"
    };
    var graphic = new Graphic(mapContext.state.extentGeometry, symbol);
    //mapContext.state.mapView.graphics.add(graphic);
    graphicLayer.add(graphic)

    mapContext.dispatch({
      layerLoading: 2,
    });
    console.log(gridObjArr)
    console.log(gridObjArr.length)
    let queryTable
    if (gridSelected === 1) {
      queryTable = nightLayer
    } else if (gridSelected === 0) {
      queryTable = dayLayer
    }
    let query = queryTable.createQuery()
    let date = mapContext.state.dateRange
    query.where = 'datetime' + date.slice(8,42) + ' and datetime' + date.slice(55)
    queryFeaturesOverLimit2k(queryTable, query).then((results) => {
      console.log("Grid Results")
      //console.log(results)
      //let gridArr = results.attributes
      
      var outputObj = results.reduce((finalObj, curObj) => {
        if (!finalObj[curObj.attributes.grid_id]) finalObj[curObj.attributes.grid_id] = curObj.attributes.f_kiekis  //<--- adding new user if do not exist in the object
        else finalObj[curObj.attributes.grid_id] += curObj.attributes.f_kiekis; // <---- if already present, the add the sum
        return finalObj;
      }, {})
      
      var output = Object.entries(outputObj).map((obj) => ({
          gridId: obj[0], //<--- key of object as userId
          usage: obj[1]  //<--- value of object as sum
      }));

      console.log(output)

      let geometryGrids = gridObjArr.map((element) => {return {geometry: element.geometry, gridId: element.attributes.grid_id}})

      console.log(geometryGrids)
      
      // Subraizyti pagala output.gridId ir gridObjArr geometrija
      let mergedArrayGrid = mergeArrays(geometryGrids, output)
      console.log(mergedArrayGrid)

      
      let itemsProccessed = 0

      let gridTextVisibility = true

      if (mapContext.state.mapView.zoom < 14) {
        gridTextVisibility = false
      }

      let maxGridValue = Math.max(...mergedArrayGrid.map(singleGrid => singleGrid.usage))
      
      //addGraphicLayerPolygon(mergedArrayGrid, maxGridValue)
      mergedArrayGrid.forEach(grid => {    
        addGraphicText(grid.geometry.centroid, grid.usage, gridTextVisibility)
          addGraphicPolygon(grid.geometry, grid.usage, maxGridValue, grid.gridId, 0)
          //addGraphicLayerPolygon(grid.usage, maxGridValue, grid.gridId)

        itemsProccessed++
        if (itemsProccessed === mergedArrayGrid.length) {
          console.log("Finished processing")
          mapContext.dispatch({
            layerLoading: 0,
          });
        }
      });

    })


  },[gridObjArr, mapContext.state.dateRange, gridSelected])

  useEffect(() => {
    if (gridSelected === null || mapContext.state.dateRange === null || mapContext.state.gridClickData === null || mapContext.state.gridClickData.graphic.attributes.grid_id === undefined || 
      mapContext.state.gridClickData.graphic.attributes.grid_id === null) {
      mapContext.dispatch({
        gridClickData: 0
      });
      return
    }

    console.log("START LOADING")
    mapContext.dispatch({
      layerLoading: 2,
    });
    setGridData([])

    let queryLayerClicked
    let queryLayerResults
    if (gridSelected === 1) {
      queryLayerClicked = dayLayer
      queryLayerResults = nightLayer
    }
    if (gridSelected === 0) {
      queryLayerClicked = nightLayer
      queryLayerResults = dayLayer
    }
    //Pridet date kai bus sutvarkytas
    let queryClikedGridIdsWhere = "grid_id = '" + mapContext.state.gridClickData.graphic.attributes.grid_id +"' and " + mapContext.state.dateRange
    let query = queryLayerClicked.createQuery();
    query.where = queryClikedGridIdsWhere
    queryFeaturesOverLimit2k(queryLayerClicked, query).then((results) => {
      console.log(results)
      let gridUserIdArray = []
      let element = results[0]
      //results.forEach(element => {
        gridUserIdArray = [... element.attributes.f_sarasas1.split(','), ...gridUserIdArray]
      
      if (results[0].attributes.f_kiekis > 500) {
        results.forEach(element => {
          gridUserIdArray = [... element.attributes.f_sarasas2.split(','), ...gridUserIdArray]
        });
      }
      if (results[0].attributes.f_kiekis > 1000) {
        results.forEach(element => {
          gridUserIdArray = [... element.attributes.f_sarasas3.split(','), ...gridUserIdArray]
        });
      }
      if (results[0].attributes.f_kiekis > 1500) {
        results.forEach(element => {
          gridUserIdArray = [... element.attributes.f_sarasas4.split(','), ...gridUserIdArray]
        });
      }

      console.log(gridUserIdArray)
      //gridUserArray = results[0].attributes.f_sarasas.split(',')
      let queryUsersQueryArray = []
      let queryUserGridIdsWhere = ''
      let i = 0
      let num = 1
      let date = mapContext.state.dateRange
      gridUserIdArray.forEach(element => {
        queryUserGridIdsWhere += "f_sarasas"+ num + " LIKE '%" + element +"%' or "
        i++

        if (i === 500) {
          queryUserGridIdsWhere = queryUserGridIdsWhere.slice(0, -3)  + ' and datetime' + date.slice(8,42) + ' and datetime' + date.slice(55)
          queryUsersQueryArray.push(queryUserGridIdsWhere)
          queryUserGridIdsWhere = ''
          i = 0
          num++;
        }
      });
      
     
      queryUserGridIdsWhere = queryUserGridIdsWhere.slice(0, -3) + ' and datetime' + date.slice(8,42) + ' and datetime' + date.slice(55)
      queryUsersQueryArray.push(queryUserGridIdsWhere)


      //console.log(queryUsersQueryArray)
      recursvieQueryGridElement(queryUsersQueryArray, queryLayerResults, [], gridUserIdArray)
      
    })
    
    function recursvieQueryGridElement(queryArray, queryLayerResults, gridDataLocal, gridUserIdArray) {
      if (queryArray.length === 0) {
        setGridData(gridDataLocal)
      }
      console.log(queryArray) 
      let queryUser = queryLayerResults.createQuery();
      queryUser.where= queryArray[0]
      console.log(queryArray[0])
      queryFeaturesOverLimit2k(queryLayerResults, queryUser).then(async (results) => {
        gridDataLocal = [...gridDataLocal, ...await calculateGrids(results, gridUserIdArray)]
          queryArray.shift()
          //console.log(queryArrRemoved)
          console.log(queryArray)
          recursvieQueryGridElement(queryArray, queryLayerResults, gridDataLocal, gridUserIdArray)
      })
    }
    

  }, [mapContext.state.gridClickData, gridSelected])

  useEffect(() => {
    if (gridSelected !== null) {
      mapContext.dispatch({
        compareTask: 2,
      });
      mapContext.dispatch({
        selectedStreetArray: [1],
      });
    }
    setGridData([])
  }, [gridSelected]);

  useEffect(() => {
    //mapContext.state.mapView.graphics.removeAll();
    graphicLayer.removeAll();
    graphicLayer.destroy();

    let hexGraphicLayer = new GraphicsLayer();
    mapContext.state.mapView.map.add(hexGraphicLayer)
    setGraphicLayer(hexGraphicLayer)

    if (typeof window.gc === 'function') {
      window.gc();
      console.log('Garbage collection triggered.');
    } else {
      console.log('Garbage collection not supported.');
    }

    mapContext.dispatch({
      selectedStreetArray: [],
    });
    mapContext.dispatch({
      compareDate: 0,
    });
    mapContext.dispatch({
      dateRange: 0,
    });
    mapContext.dispatch({
      calendarResetDates: 0,
    });
    setGridSelected(null);

    let elems = document.querySelectorAll(".GridSelection");

    [].forEach.call(elems, function (el) {
      el.classList.remove("Active");
    });
  }, [mapContext.state.clearFlag]);

  useEffect(() => {
    console.log(gridToggleArr)
    if (gridSidebarSelection === 1) {
      
      for (let i = 0; i< gridToggleArr.length; i++){
        if (gridToggleArr[i] === true) {
          let str = "gridToggle" + i
          document.getElementById(str).click();
        }
      }
    }
  },[gridSidebarSelection])

  return (
    <>
      <div
        id={"CalendarSidebarBtn"}
        className={"SidebarGridCard Active"}
        onClick={() => {
          setGridSidebarSelection(0);
        }}
      >
        Kalendorius
      </div>
      <div
        id={"ContextSidebarBtn"}
        className={"SidebarGridCard"}
        onClick={() => {
          setGridSidebarSelection(1);
        }}
      >
        Kontekstiniai duomenys
      </div>
      <div id={"SidebarGrid"} className={"MenuColor"}>
        {gridSidebarSelection === 0 ? (
          <Calendar
            token={token}
            monthWeekDay={false}
            dispatcher={0}
            headerText={"Pasirinkite laikotarpį"}
            colorScheme={""}
            inlineBool={true}
            existingStartDate={mapContext.state.calendarResetDates === null ? null : mapContext.state.calendarResetDates[0]}
            existingEndDate={mapContext.state.calendarResetDates === null ? null : mapContext.state.calendarResetDates[1]}
            dataSource={calendarDates}
            canSelectRange = {false}
          ></Calendar>
        ) : (
          <>
            <table className="ToggleTable">
              <tr className={"ToggleTableRow"}>
                <td className={"ToggleTableText"}>{"JAR objektai"}</td>
                <td>
                  <label class="switch">
                    <input
                      type="checkbox"
                      id={"gridToggle0"}
                      onClick={(e) => {
                        if ( e.clientX === 0 && e.clientY === 0){
                          return
                        }
                        let arr = gridToggleArr
                        arr[0] = !arr[0]
                        setGridToggleArr(arr)
                        toggleContextLayerVisibilityURL("https://opencity.vplanas.lt/server/rest/services/Hosted/Administraciniai_pastatai_su_100_ir_daugiau_darbuotoj%C5%B3/FeatureServer");
                        
                      }}
                    />
                    <span class="slider round"></span>
                  </label>
                </td>
              </tr>
              <tr className={"ToggleTableRow"}>
                <td className={"ToggleTableText"}>{"Ugdymo įstaigos"}</td>
                <td>
                  <label class="switch">
                    <input
                      type="checkbox"
                      id={"gridToggle1"}
                      onClick={(e) => {
                        if ( e.clientX === 0 && e.clientY === 0){
                          return
                        }
                        let arr = gridToggleArr
                        arr[1] = !arr[1]
                        setGridToggleArr(arr)
                        toggleContextLayerVisibility(30);
                      }}
                    />
                    <span class="slider round"></span>
                  </label>
                </td>
              </tr>
              <tr className={"ToggleTableRow"}>
                <td className={"ToggleTableText"}>{"Sveikatos priežiūros įstaigos"}</td>
                <td>
                  <label class="switch">
                    <input
                      type="checkbox"
                      id={"gridToggle2"}
                      onClick={(e) => {
                        if ( e.clientX === 0 && e.clientY === 0){
                          return
                        }
                        let arr = gridToggleArr
                        arr[2] = !arr[2]
                        setGridToggleArr(arr)
                        toggleContextLayerVisibility(28);
                      }}
                    />
                    <span class="slider round"></span>
                  </label>
                </td>
              </tr>
              <tr className={"ToggleTableRow"}>
                <td className={"ToggleTableText"}>{"VT stotelės"}</td>
                <td>
                  <label class="switch">
                    <input
                      type="checkbox"
                      id={"gridToggle3"}
                      onClick={(e) => {
                        if ( e.clientX === 0 && e.clientY === 0){
                          return
                        }
                        let arr = gridToggleArr
                        arr[3] = !arr[3]
                        setGridToggleArr(arr)
                        toggleContextLayerVisibilityURL("https://services1.arcgis.com/vVI5TNykiYD9EhM5/ArcGIS/rest/services/Stotel%c4%97s/FeatureServer");
                      }}
                    />
                    <span class="slider round" ></span>
                  </label>
                </td>
              </tr>
              <tr className={"ToggleTableRow"}>
                <td className={"ToggleTableText"}>{"VT maršrutai"}</td>
                <td>
                  <label class="switch">
                    <input
                      type="checkbox"
                      id={"gridToggle4"}
                      onClick={(e) => {
                        if ( e.clientX === 0 && e.clientY === 0){
                          return
                        }
                        let arr = gridToggleArr
                        arr[4] = !arr[4]
                        setGridToggleArr(arr)
                        toggleContextLayerVisibilityURL("https://arcgis.sisp.lt/arcgis/rest/services/VT/VT_marsutu_trasos/FeatureServer");
                      }}
                    />
                    <span class="slider round"></span>
                  </label>
                </td>
              </tr>
              <tr className={"ToggleTableRow"}>
                <td className={"ToggleTableText"}>{"Juodosios dėmės"}</td>
                <td>
                  <label class="switch">
                    <input
                      type="checkbox"
                      id={"gridToggle5"}
                      onClick={(e) => {
                        if ( e.clientX === 0 && e.clientY === 0){
                          return
                        }
                        let arr = gridToggleArr
                        arr[5] = !arr[5]
                        setGridToggleArr(arr)
                        toggleContextLayerVisibilityURL("https://gis.vplanas.lt/arcgis/rest/services/Interaktyvus_zemelapis/Transportas/MapServer");  
                        console.log(mapContext.state.dataContextLayers)                   
                      }}
                    />
                    <span class="slider round"></span>
                  </label>
                </td>
              </tr>
              <tr className={"ToggleTableRow"}>
                <td className={"ToggleTableText"}>{"Parkomatai"}</td>
                <td>
                  <label class="switch">
                    <input
                      type="checkbox"
                      id={"gridToggle6"}
                      onClick={(e) => {
                        if ( e.clientX === 0 && e.clientY === 0){
                          return
                        }
                        let arr = gridToggleArr
                        arr[6] = !arr[6]
                        setGridToggleArr(arr)
                        toggleContextLayerVisibilityURL("https://services1.arcgis.com/vVI5TNykiYD9EhM5/ArcGIS/rest/services/Parkomatai_2024_05_21/FeatureServer");  
                        console.log(mapContext.state.dataContextLayers)                   
                      }}
                    />
                    <span class="slider round"></span>
                  </label>
                </td>
              </tr>
              <tr className={"ToggleTableRow"}>
                <td className={"ToggleTableText"}>{"Dviračių saugyklos"}</td>
                <td>
                  <label class="switch">
                    <input
                      type="checkbox"
                      id={"gridToggle7"}
                      onClick={(e) => {
                        if ( e.clientX === 0 && e.clientY === 0){
                          return
                        }
                        let arr = gridToggleArr
                        arr[7] = !arr[7]
                        setGridToggleArr(arr)
                        toggleContextLayerVisibilityURL("https://services1.arcgis.com/vVI5TNykiYD9EhM5/ArcGIS/rest/services/Tikslios_saugykl%c5%b3_vietos/FeatureServer");  
                        console.log(mapContext.state.dataContextLayers)                   
                      }}
                    />
                    <span class="slider round"></span>
                  </label>
                </td>
              </tr>
            </table>
          </>
        )}
      </div>
      <div id={"MenuGrid"} className={"MenuColor"}>
        <div
          id={"DayGrid"}
          className={"ButtonColor GridSelection"}
          onClick={() => {
            setGridSelected(0);
          }}
        >
          <img
            className={"GridIconImg"}
            src={gridSelected === 0 ? dayActive : dayInactive}
            alt="day"
          ></img>
        </div>
        <div
          id={"NightGrid"}
          className={"ButtonColor GridSelection"}
          onClick={() => {
            setGridSelected(1);
          }}
        >
          <img
            className={"GridIconImg"}
            src={gridSelected === 1 ? nightActive : nightInactive}
            alt="night"
          ></img>
        </div>
        
      </div>
    </>
  );
}
