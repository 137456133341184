import { MapContext } from "../MapContext";
import { useContext } from "react";
//https://developers.arcgis.com/javascript/latest/api-reference/esri-rest-support-StatisticDefinition.html

function getMinMax(mapFeatureLayer) {
    //const mapContext = useContext(MapContext);

    let populationChangeDefinition = {
        onStatisticField: "sum_total",  // service field for 2015 population
        outStatisticFieldName: "max_value",
        statisticType: "max"
      }
      let query = mapFeatureLayer.createQuery();
      query.outStatistics = [ populationChangeDefinition ];
      mapFeatureLayer.queryFeatures(query).then(function(response){
          let stats = response.features[0].attributes;
          console.log("Average change:", stats.max_value);
          //MapContext.dispatch({
          //  maxValue: stats.max_value
          //});
          return stats.max_value
    });
}

export async function queryFeaturesOverLimit(mapFeatureLayer, query) {
    //let totalCount = await mapFeatureLayer.queryFeatureCount(query)
    //console.log(totalCount)
    let i = true
    let counter = 0 
    let featuresArr = []
    while (i === true){
        let index =  {num:5000 , start: (counter*5000)}
        let tempQuer = query
        let indexedquery = Object.assign(tempQuer, index)
        let results =  await mapFeatureLayer.queryFeatures(indexedquery)
        
        featuresArr = featuresArr.concat(results.features)
        //console.log(results.features)
        if (results.features.length < 5000) {
            return featuresArr
        } 
        counter++;
    }
}


export async function queryFeaturesOverLimit2k(mapFeatureLayer, query) {
    //let totalCount = await mapFeatureLayer.queryFeatureCount(query)
    //console.log(totalCount)
    let i = true
    let counter = 0 
    let featuresArr = []
    while (i === true){
        let index =  {num:2000 , start: (counter*2000)}
        let tempQuer = query
        let indexedquery = Object.assign(tempQuer, index)
        let results =  await mapFeatureLayer.queryFeatures(indexedquery)
        
        featuresArr = featuresArr.concat(results.features)
        //console.log(results.features)
        if (results.features.length < 2000) {
            return featuresArr
        } 
        counter++;
    }
}


export function toISOLocal(d) {
    var z  = n =>  ('0' + n).slice(-2);
    var zz = n => ('00' + n).slice(-3);
    var off = d.getTimezoneOffset();
    var sign = off > 0? '-' : '+';
    off = Math.abs(off);
  
    return d.getFullYear() + '-'
           + z(d.getMonth()+1) + '-' +
           z(d.getDate()) + 'T' +
           z(d.getHours()) + ':'  + 
           z(d.getMinutes()) + ':' +
           z(d.getSeconds()) + '.' +
           zz(d.getMilliseconds()) +
           sign + z(off/60|0) + ':' + z(off%60); 
}


export function checkOneDayApartAndTransform(inputString) {
    // Task 1: Check if timestamps are one day apart
    function isOneDayApart(str) {
      if (!str) return false; // Handle null input
  
      const dateRegex = /timestamp'(\d{2}\/\d{2}\/\d{4})/g;
      const matches = [...str.matchAll(dateRegex)]; // Extract all date matches
  
      if (matches.length < 2) return false; // Ensure two dates are found
  
      const date1 = new Date(matches[0][1]);
      const date2 = new Date(matches[1][1]);
  
      const diffInDays = Math.abs(date2 - date1) / (1000 * 60 * 60 * 24);
      return diffInDays === 1; // Return true if exactly one day apart
    }
  
    // Task 2: Transform 'datetime' to 'pubmillis'
    function transformString(str) {
      return str ? str.replaceAll('datetime', 'pubmillis') : null;
    }
  
    // Perform both actions and return results
    return {
      isOneDayApart: isOneDayApart(inputString),
      transformedString: transformString(inputString),
    };
  }