import React, { useEffect, useRef, useContext, useState, forwardRef } from "react";
import "../widgets/widgetcss/styles.css";
import { MapContext } from "../../../../MapContext";
import DatePicker, {registerLocale} from "react-datepicker";
import "../../../../Judumas.css"
import { queryFeaturesOverLimit } from "../../../../helpers/helper";
import lt from "date-fns/locale/lt"
import Graphic from "@arcgis/core/Graphic";
import Chart, { LogarithmicScale } from "chart.js/auto";
import  Calendar  from "./Calendar.js";
import SketchViewModel from "@arcgis/core/widgets/Sketch/SketchViewModel.js";
import GraphicsLayer from "@arcgis/core/layers/GraphicsLayer.js";
import * as geometryEngineAsync from "@arcgis/core/geometry/geometryEngineAsync.js";
import { operatorBikeData } from "../../../../Services/serviceLayers";
import excelExport  from "./excelExport";
import ExcelExport from "./excelExport";
import ContextLayers from "./ContextLayers.js";

registerLocale("lt", lt); 

const chartAreaBorder = {
  id: 'chartAreaBorder',
  beforeDraw(chart, args, options) {
    const {ctx, chartArea: {left, top, width, height}} = chart;
    //console.log(left, top, width, height)
    ctx.save();
    ctx.strokeStyle = options.borderColor;
    ctx.lineWidth = options.borderWidth;
    ctx.setLineDash(options.borderDash || []);
    ctx.lineDashOffset = options.borderDashOffset;
    ctx.strokeRect(left, (top + height), width, 0);
    ctx.restore();
  }
};

/* TODO:
    Remove unselected streets from array
    Add graphic for all geometries not just data[0]
*/

export default function CompareSidebar({ data, token }) {

  const mapContext = useContext(MapContext);
  const [chartType, setchartType] = useState("linear");
  const [diagramData, setDiagramData] = useState([null,null,null])
  const [initialRender, setInitialRender] = useState(0)
  const [sketchViewState, setSketchViewState] = useState(null)
  const [size, setSize] = useState(false)

  var diagram = useRef(null);
  var bubbleDiagram = useRef(null);
  var monthDiagram = useRef(null);

  

  const dispatchTask = (task) => {
    //Reiks perkelt Kitur
    console.log("map visible")
    mapContext.state.featureLayer.visible = true;
    console.log(mapContext.state.featureLayer)
    if (task !==3)
    {
      for (let i = 0; i < mapContext.state.mapView.layerViews.items.length; i++){
        if (mapContext.state.mapView.layerViews.items[i].layer.layerId === 0) {
          /*mapContext.state.mapView.layerViews.items[i].filter = {
            where: "highway IN ('motorway',  'trunk',  'primary',  'secondary',  'tertiary',  'unclassified',  'residential',  'service',  'motorway_link',  'trunk_link',  'primary_link',  'secondary_link',  'motorway_junction', 'living_street', 'tertiary_link', 'living_street')"
          };
          console.log( "mapContext.state.mapView.layerViews.items[i].filter Removed")
            console.log( mapContext.state.mapView.layerViews.items[i].filter)*/
        }
      }
    }
    

    if (mapContext.state.compareTask % 10 === task % 10 && Math.floor(task / 10) === 0) {
      return
    }
    mapContext.dispatch({
      compareTask: task,
    });
  }

  function createQuery(idArray, compareDate = 0 ) {
    let queryWhere = "parent_global_id IN ('"
    idArray.forEach(element => {

      console.log(element, typeof element)
      if (element !== undefined && element !== null){
        queryWhere += element + "','"
      }   
    })
    queryWhere = queryWhere.slice(0,-2)
    if (compareDate === 0) {
      queryWhere += ") and " + mapContext.state.dateRange;
    } else {
      queryWhere += ") and " + mapContext.state.compareDate;
    }
   

    var query = mapContext.state.dataFeatureLayer.createQuery();
    query.where = queryWhere;

    return query 
  }

  async function queryFeaturesOverLimitWrapper(context, query) {
    let result = await queryFeaturesOverLimit(context, query)
    console.log(result)
    return result
  } 

  const sleep = ms => new Promise(r => setTimeout(r, ms));

  // action 0 - add 1 - subtract
  function processQueryResults(diagramDataLocal, features, action) {
    let proccesedCount = 0

    features.forEach((element) => {
      proccesedCount++

      const options = { hour: "numeric" };
     
      if (action === 0){
        console.log("ADD")
        if (!diagramDataLocal.hasOwnProperty(new Date(element.attributes.datetime).toLocaleDateString("en-US", options))) {
          diagramDataLocal[new Date(element.attributes.datetime).toLocaleDateString("en-US", options)] = 0;
        }
        // Prideti kelias atkarpas
        diagramDataLocal[new Date(element.attributes.datetime).toLocaleDateString("en-US", options)] += element.attributes.usage;
      } else if (action === 1) {
        console.log("REMOVE")
        diagramDataLocal[new Date(element.attributes.datetime).toLocaleDateString("en-US", options)] -= element.attributes.usage;
      }
      
      if (proccesedCount == features.length) {
        mapContext.dispatch({
          sideBarRequestPending: 0,
        });
      }
    });
    return diagramDataLocal
  }

  function addToSelectedStreets (streetsArr, length) {
    let currentStreets
    if (mapContext.state.selectedStreetArray.length > 0 ) {
      currentStreets = mapContext.state.selectedStreetArray
    } else {
      currentStreets = [[],[]]
    }
    console.log("addToSelectedStreets START")
    console.log(length)
    //console.log(mapContext.state.dateRange)
    //console.log(mapContext.state.mapView.graphics)
    console.log(currentStreets)
    console.log(currentStreets[length])
    console.log(streetsArr)
    console.log("addToSelectedStreets END")
    let filtered =  streetsArr.concat(currentStreets[length].filter((item) => streetsArr.indexOf(item) < 0))
    if (length === 1) {
      mapContext.dispatch({
      
        selectedStreetArray: [ currentStreets[0], filtered]
      });
    }
    if (length === 0) {
      mapContext.dispatch({
      
        selectedStreetArray: [filtered, currentStreets[1] ]
      });
    }
    if (length === 2) {
      mapContext.dispatch({
      
        selectedStreetArray: [filtered]
      });
    }

    
  }

  function collectGeometry(data) {


  }

  function changeTask(taskNo) {
    mapContext.dispatch({
      contextDataVisible: false,
    });
    if (taskNo === 3){
      mapContext.dispatch({
        multiSelect: true,
      });
      for (let i = 0; i < mapContext.state.mapView.layerViews.items.length; i++){
        if (mapContext.state.mapView.layerViews.items[i].layer.layerId === 0) {
          /*mapContext.state.mapView.layerViews.items[i].filter = {
            where: "highway IN ('track', 'path', 'cycleway', 'footway', 'motorway',  'trunk',  'primary',  'secondary',  'tertiary',  'unclassified',  'residential',  'service',  'motorway_link',  'trunk_link',  'primary_link',  'secondary_link',  'motorway_junction', 'living_street', 'tertiary_link', 'living_street')"
          };
          console.log( "mapContext.state.mapView.layerViews.items[i].filter")
          console.log( mapContext.state.mapView.layerViews.items[i].filter)*/
        }
      }
  
    } else {
      mapContext.dispatch({
        multiSelect: false,
      });
    }
    mapContext.dispatch({
      clearFlag: 1,
    });
    mapContext.dispatch({
      sideBarRequestPending: 0,
    });
    dispatchTask(taskNo)
  }

  function addGraphic(color_, geometry, streedIds, action) {
    let symbol = {
      type: "simple-fill", 
      color: color_,
      style: "solid",
      outline: {
        color: color_,
        width: 6,
      },
    };
    let attributes = {
      lengthTaskNr: action,
      selectedStreetIds: streedIds,
    };
    var graphic = new Graphic(geometry, symbol, attributes);
    mapContext.state.mapView.graphics.add(graphic);
  }

  function graphicAndProcessDataTimeCompare(data, addOrRemove) {
    var diagramDataLocalDate1 = {};
    var diagramDataLocalDate2 = {};
    var changeFlag = false;

    if (diagramData[2] !== null && diagramData[2] !== undefined) {
      diagramDataLocalDate2 = diagramData[2];
    }
    if (diagramData[1] !== null && diagramData[1] !== undefined) {
      diagramDataLocalDate1 = diagramData[1];
    }
    if (diagramData[0] !== null && diagramData[0] !== undefined) {
      changeFlag = !diagramData[0];
    }

    let clickedIds = []
    data.results.forEach(element => {
      clickedIds.push(element.graphic.attributes.GlobalID)
    });

    if (addOrRemove === 1) {
      
      let tempSelectedStreets = Array.from(mapContext.state.selectedStreetArray[1])

      tempSelectedStreets = tempSelectedStreets.filter( ( el ) => !clickedIds.includes( el ) );


      mapContext.dispatch({
        
        selectedStreetArray: [[], tempSelectedStreets ]
      });
    } else {

      addToSelectedStreets(clickedIds, 1)
    }
    let query = createQuery(clickedIds)
    console.log("Q1")
    console.log(query.where)
    
    queryFeaturesOverLimitWrapper(mapContext.state.dataFeatureLayer, query).then((features) => {
      if (features.length === 0) {
        mapContext.dispatch({
          sideBarRequestPending: 0,
        });
        return
      }
      let proccesedFeatures = processQueryResults(diagramDataLocalDate1, features, addOrRemove)
            // addOrRemove 0 - add, 1 - remove
      let queryDate2 = createQuery(clickedIds, 1) 
      console.log("Q2")
      console.log(queryDate2.where)
      queryFeaturesOverLimitWrapper(mapContext.state.dataFeatureLayer, queryDate2).then((features) => {
        if (features.length === 0) {
          mapContext.dispatch({
            sideBarRequestPending: 0,
          });
          return
        }
        let proccesedFeaturesDate2 = processQueryResults(diagramDataLocalDate2, features, addOrRemove)
              // addOrRemove 0 - add, 1 - remove
        console.log("SETData")
        console.log(changeFlag)
        console.log(proccesedFeatures)
        console.log(proccesedFeaturesDate2)
        if (addOrRemove === 1) {
          mapContext.state.mapView.graphics.remove(data.results[0].graphic);
        } else {
          addGraphic([255, 255, 0, 6], data.results[0].graphic.geometry ,clickedIds, 3)
        }
        setDiagramData([changeFlag, proccesedFeatures, proccesedFeaturesDate2])        
      })
    })
  }

  function graphicAndProcessedData(data, subtaskLocal, streetState, addOrRemove) {
    var diagramDataLocal = {};
    var changeFlag = false;
    

    if (diagramData[2] !== null && diagramData[2] !== undefined && subtaskLocal === 2) {
      diagramDataLocal = diagramData[2];
    }
    if (diagramData[1] !== null && diagramData[1] !== undefined && subtaskLocal === 1) {
      diagramDataLocal = diagramData[1];
    }
    if (diagramData[0] !== null && diagramData[0] !== undefined) {
      changeFlag = !diagramData[0];
    }
    console.log("DataLoc", diagramDataLocal)

    let clickedIds = []
    data.results.forEach(element => {
      clickedIds.push(element.graphic.attributes.GlobalID)
    });
    console.log(clickedIds)

    let selectedArrayIndex = (subtaskLocal - 1)
    if (addOrRemove === 1) {
      
      let tempSelectedStreets = Array.from(mapContext.state.selectedStreetArray[selectedArrayIndex])
      let copyIndex = 1 - selectedArrayIndex
      let copiedStreets = Array.from(mapContext.state.selectedStreetArray[copyIndex])

      tempSelectedStreets = tempSelectedStreets.filter( ( el ) => !clickedIds.includes( el ) );


      if (selectedArrayIndex === 1) {
        mapContext.dispatch({
        
          selectedStreetArray: [copiedStreets, tempSelectedStreets ]
        });
      }
      if (selectedArrayIndex === 0) {
        mapContext.dispatch({
        
          selectedStreetArray: [tempSelectedStreets, copiedStreets ]
        });
      }
    } else {
      addToSelectedStreets(clickedIds, selectedArrayIndex)
    }

    

    let query = createQuery(clickedIds)
    console.log("QUERY ", query)
    queryFeaturesOverLimitWrapper(mapContext.state.dataFeatureLayer, query).then((features) => {
      if (features.length === 0) {
        mapContext.dispatch({
          sideBarRequestPending: 0,
        });
        return
      }
      let proccesedFeatures = processQueryResults(diagramDataLocal, features, addOrRemove)
      console.log(streetState)
      // addOrRemove 0 - add, 1 - remove
      if (addOrRemove === 1) {
      // streetState 1 - graphic already exists, 0 - graphic doesn't exist yet
        if (streetState === 1){
          if (subtaskLocal === 1){
            setDiagramData([changeFlag, proccesedFeatures, diagramData[2]])
            mapContext.state.mapView.graphics.remove(data.results[0].graphic);
            addGraphic([133, 188, 25, 6], data.results[0].graphic.geometry ,clickedIds, 2)
          }
          if (subtaskLocal === 2) {
            setDiagramData([changeFlag, diagramData[1], proccesedFeatures])
            mapContext.state.mapView.graphics.remove(data.results[0].graphic);
            addGraphic([85, 255, 255, 6], data.results[0].graphic.geometry ,clickedIds, 1)
          }
          return
        } else {
          if (subtaskLocal === 1){
            setDiagramData([changeFlag, proccesedFeatures, diagramData[2]])
            mapContext.state.mapView.graphics.remove(data.results[0].graphic);          
          }
          if (subtaskLocal === 2) {
            setDiagramData([changeFlag, diagramData[1], proccesedFeatures])
            mapContext.state.mapView.graphics.remove(data.results[0].graphic);          
          }
          return
        }
      }
      if (streetState === 1 ) {
        if (subtaskLocal === 1){
          setDiagramData([changeFlag, proccesedFeatures, diagramData[2]])
          mapContext.state.mapView.graphics.remove(data.results[0].graphic);
          addGraphic([255, 255, 0, 6], data.results[0].graphic.geometry ,clickedIds, 3)
        }
        if (subtaskLocal === 2) {
          setDiagramData([changeFlag, diagramData[1], proccesedFeatures])
          mapContext.state.mapView.graphics.remove(data.results[0].graphic);
          addGraphic([255, 255, 0, 6], data.results[0].graphic.geometry ,clickedIds, 3)
        }
        return
      } else {
        if (subtaskLocal === 1){
          setDiagramData([changeFlag, proccesedFeatures, diagramData[2]])
          addGraphic([85, 255, 255, 6], data.results[0].graphic.geometry ,clickedIds, 1)
        }
        if (subtaskLocal === 2) {
          setDiagramData([changeFlag, diagramData[1], proccesedFeatures])
          addGraphic([133, 188, 25, 6], data.results[0].graphic.geometry ,clickedIds, 2)
        }
        return
      }
    })
  }

  function waitForElm(selector) {
    return new Promise((resolve) => {
      if (document.querySelector(selector)) {
        return resolve(document.querySelectorAll(selector));
      }

      const observer = new MutationObserver((mutations) => {
        if (document.querySelector(selector)) {
          resolve(document.querySelectorAll(selector));
          observer.disconnect();
        }
      });

      observer.observe(document.body, {
        childList: true,
        subtree: true,
      });
    });
  }

  function sketchRect() {
    if (sketchViewState === null) {
      console.log("sketchViewState Retrun")
      return
    }
    
    sketchViewState.create("rectangle");
    console.log(sketchViewState)
    sketchViewState.on("create", async (event) => {
      if (event.state === "complete") {
        mapContext.dispatch({
          sideBarRequestPending: 1,
        });
        console.log(mapContext.state.mapView.map.layers.items)
        const geometries = mapContext.state.mapView.map.layers.items[0].graphics.map(function (graphic) {
          console.log(graphic.geometry)
          return graphic.geometry;
        });
        const queryGeometry = await geometryEngineAsync.union(geometries.toArray());
        console.log("queryGeometry");
        let queryResults =  selectFeatures(queryGeometry);
        console.log(await queryResults);
      }
    });
  }

function selectFeatures(geometry) {
    if (mapContext.state.featureLayer) {
      // create a query and set its geometry parameter to the
      // rectangle that was drawn on the view
      const query = {
        geometry: geometry,
        outFields: ["*"],
        where: "highway IN ('motorway',  'trunk',  'primary',  'secondary',  'tertiary',  'unclassified',  'residential',  'service',  'motorway_link',  'trunk_link',  'primary_link',  'secondary_link',  'motorway_junction', 'living_street', 'living_street')"
      };

      // query graphics from the csv layer view. Geometry set for the query
      // can be polygon for point features and only intersecting geometries are returned
      mapContext.state.featureLayer
        .queryFeatures(query)
        .then(async (results) =>  {
          console.log("Geometry results")
          console.log(results)

          var diagramDataLocal = [false,{},{},{},{}]
          var diagramDataLocal1 = {};
          var diagramDataLocal2 = {};
          var diagramDataLocal3 = {};
          var diagramDataLocal4 = {};
          var changeFlag = false;
          
          if (diagramData[4] !== null && diagramData[4] !== undefined) {
            diagramDataLocal[4] = diagramData[4];
          }
          if (diagramData[3] !== null && diagramData[3] !== undefined) {
            diagramDataLocal[3] = diagramData[3];
          }
          if (diagramData[2] !== null && diagramData[2] !== undefined) {
            diagramDataLocal[2] = diagramData[2];
          }
          if (diagramData[1] !== null && diagramData[1] !== undefined) {
            diagramDataLocal[1] = diagramData[1];
          }
          if (diagramData[0] !== null && diagramData[0] !== undefined) {
            changeFlag = !diagramData[0];
          }

          let selectedIds = []
          results.features.forEach(element => {
            selectedIds.push(element.attributes.GlobalID)
            console.log(element.attributes.GlobalID)
          });
          addToSelectedStreets(selectedIds, 1)
          let query = createQuery(selectedIds)

          let proccesedFeatures = [changeFlag]
          

          for (let i = 0; i < mapContext.state.compareLayers.length; i++) {
            console.log("MULTI START")
            console.log(mapContext.state.compareLayers.length)
            console.log(i)
            
            let diagData = await queryFeaturesOverLimitWrapper(mapContext.state.compareLayers[i], query)
            if (diagData.length === 0) {
              console.log("RET")
              mapContext.dispatch({
                sideBarRequestPending: 0,
              });
              return
            }

            console.log(diagData)
            proccesedFeatures.push(processQueryResults(diagramDataLocal[i+1], diagData, 0))
            /*if (i === 0) {
              proccesedFeatures = processQueryResults(diagramDataLocal1, diagData, 0)
            }
            if (i === 1) {
              proccesedFeatures2 = processQueryResults(diagramDataLocal2, diagData, 0)
            }*/
            
          }
          console.log(proccesedFeatures)
          setDiagramData(proccesedFeatures)
          
          console.log(proccesedFeatures)
          console.log("MULTI END")
          return
          queryFeaturesOverLimitWrapper(mapContext.state.dataFeatureLayer, query).then((features) => {
            if (features.length === 0) {
              mapContext.dispatch({
                sideBarRequestPending: 0,
              });
              return
            }
            let proccesedFeatures = processQueryResults(diagramDataLocal1, features, 0)
            //setDiagramData([changeFlag, proccesedFeatures, diagramData[2]])

            queryFeaturesOverLimitWrapper(mapContext.state.compareLayers[1], query).then((features) => {
              if (features.length === 0) {
                mapContext.dispatch({
                  sideBarRequestPending: 0,
                });
                return
              }
              let proccesedFeatures2 = processQueryResults(diagramDataLocal2, features, 0)
              setDiagramData([changeFlag, proccesedFeatures, proccesedFeatures2])

            // addOrRemove 0 - add, 1 - remove

            // streetState 1 - graphic already exists, 0 - graphic doesn't exist yet
              
                mapContext.dispatch({
                  sideBarRequestPending: 0,
                });
                console.log(proccesedFeatures)
                return
              
            
              })
          })
          
        })
        .catch();
    }
  }

  function selectCompareLayer() {
    mapContext.dispatch({
      compareLayer: operatorBikeData,
    });
  }

  function showContextData( bool) {
    mapContext.dispatch({
      contextDataVisible: bool,
    });
  }

  function changeSize() {
    if (size === false ){
      
      document.getElementById('AnalyticsSidebar').style.width = 44 + 'vw'
      document.getElementById('HideSidebarBtn').style.right = 47.5 + 'vw'
      setSize(true)
    } else {
      document.getElementById('AnalyticsSidebar').style.width = 22 + 'vw'
      document.getElementById('HideSidebarBtn').style.right= 25.5 + 'vw'
      setSize(false)
    }
    let diagData = []
    for (let i = 0 ; i < diagramData.length ; i ++){
      if (i === 0 ) {
        diagData.push(!diagramData[i])
      } else {
        diagData.push(diagramData[i])
      }
      
    }
    setDiagramData(diagData)

  }

  function drawDiagMulti() {
      let totalData = [];
      let totalDataA1 = 0;
      let totalDataA2 = 0;
      let datasetLabel = [];
      for (let i = 0; i < 24; i++) {
        datasetLabel.push(i + ":00");
      }

      let dataSet = []
      let dataSetA1 = [];
      for (let i = 0; i < 24; i++) {
        dataSetA1.push(0);
      }
      /*
      let dataSetA2 = [];
      for (let i = 0; i < 24; i++) {
        dataSetA2.push(0);
      }*/

      let weekDayUsage = []
      let weekDayUsageA1 = [];
      for (let i = 0; i < 7; i++) {
        weekDayUsageA1.push({ x: (i + 1) * 100, y: 25, r: 0 });
      }

      /*
      let weekDayUsageA2 = [];
      for (let i = 0; i < 7; i++) {
        weekDayUsageA2.push({ x: (i + 1) * 100, y: 25, r: 0 });
      }*/
      let monthUsage = [];
      let monthUsageA1 = [];
      let tempClone = [];
      for (let i = 0; i < 12; i++) {
        monthUsageA1.push(0);
      }
      for (let i = 1; i <= mapContext.state.compareLayers.length; i++) {
        dataSet.push(structuredClone(dataSetA1))
        tempClone = structuredClone(weekDayUsageA1)
        for (let j= 0; j< 7; j++){
          tempClone[j].y = tempClone[j].y * i
        }
        weekDayUsage.push(structuredClone(tempClone))
        monthUsage.push(structuredClone(monthUsageA1))
        totalData.push(0)
      }
      /*
      let monthUsageA2 = [];
      for (let i = 0; i < 12; i++) {
        monthUsageA2.push(0);
      }
      */
      let monthLabels = ["Sau", "Vas", "Kov", "Bal", "Geg", "Bir", "Lie", "Rgp", "Rgs", "Spa", "Lap", "Gru"];

      for (let i = 1; i <= mapContext.state.compareLayers.length; i++) {
        console.log("DATA PROCESS START")
        console.log(i)
        console.log(diagramData[i])
        for (let j = 0; j <Object.values(diagramData[i]).length; j++) {
          //First graph
          let key = Object.keys(diagramData[i])[j];
          //console.log(key)
          let char = String.fromCharCode(8239)
          let splitKeyOut = Object.keys(diagramData[i])[j].split(" ");
          let splitKey
          if (splitKeyOut.length === 3) {
            splitKey = Object.keys(diagramData[i])[j].split(" ");
          } else {
            let splitKeyInner = splitKeyOut[1].split(char);
            splitKey = [splitKeyOut[0], splitKeyInner[0],  splitKeyInner[1]]
          }
          
          //console.log(splitKey)

          let hour = parseInt(splitKey[1]);
          if (splitKey[2].includes("PM") && hour != 12) {
            hour += 12;
          } else if (splitKey[2].includes("AM") && hour == 12) {
            hour -= 12;
          }
          dataSet[i-1][hour] += diagramData[i][key];
          totalData[i-1] += diagramData[i][key];
          //Second graph
          let weekDay = new Date(splitKey[0].substring(0, splitKey[0].length - 1)).getDay();
          if (weekDay == 0) {
            weekDay = 6;
          } else {
            weekDay -= 1;
          }
          weekDayUsage[i-1][weekDay].r += diagramData[i][key];

          // Third graph Dataset
        
          let month = new Date(splitKey[0].substring(0, splitKey[0].length - 1)).getMonth();
          monthUsage[i-1][month] += diagramData[i][key];
          
        }
        console.log(dataSet)
        console.log(weekDayUsage)
        console.log(monthUsage)
        console.log("DATA PROCESS END")
      }
      /*
      for (let j = 0; j < Object.values(diagramData[i]).length; j++) {
        //First graph
        let key = Object.keys(diagramData[i])[j];
        //console.log(key)
        let char = String.fromCharCode(8239)
        let splitKeyOut = Object.keys(diagramData[i])[j].split(" ");
        let splitKey
        if (splitKeyOut.length === 3) {
          splitKey = Object.keys(diagramData[i])[j].split(" ");
        } else {
          let splitKeyInner = splitKeyOut[1].split(char);
          splitKey = [splitKeyOut[0], splitKeyInner[0],  splitKeyInner[1]]
        }
        
        //console.log(splitKey)

        let hour = parseInt(splitKey[1]);
        if (splitKey[2].includes("PM") && hour != 12) {
          hour += 12;
        } else if (splitKey[2].includes("AM") && hour == 12) {
          hour -= 12;
        }
        dataSet[i-1][hour] += diagramData[i][key];
        totalDataA1 += diagramData[i][key];
        //Second graph
        let weekDay = new Date(splitKey[0].substring(0, splitKey[0].length - 1)).getDay();
        if (weekDay == 0) {
          weekDay = 6;
        } else {
          weekDay -= 1;
        }
        weekDayUsageA1[weekDay].r += diagramData[i][key];

        // Third graph Dataset
       
        let month = new Date(splitKey[0].substring(0, splitKey[0].length - 1)).getMonth();
        monthUsageA1[month] += diagramData[i][key];
      }*/
      /*
      for (let i = 0; i < Object.values(diagramData[2]).length; i++) {
        //First graph
        let key = Object.keys(diagramData[2])[i];
        //console.log(key)
        let char = String.fromCharCode(8239)
        let splitKeyOut = Object.keys(diagramData[2])[i].split(" ");
        let splitKey
        if (splitKeyOut.length === 3) {
          splitKey = Object.keys(diagramData[2])[i].split(" ");
        } else {
          let splitKeyInner = splitKeyOut[1].split(char);
          splitKey = [splitKeyOut[0], splitKeyInner[0],  splitKeyInner[1]]
        }
        
        //console.log(splitKey)

        let hour = parseInt(splitKey[1]);
        if (splitKey[2].includes("PM") && hour != 12) {
          hour += 12;
        } else if (splitKey[2].includes("AM") && hour == 12) {
          hour -= 12;
        }
        dataSetA2[hour] += diagramData[2][key];
        totalDataA2 += diagramData[2][key];
        //Second graph
        let weekDay = new Date(splitKey[0].substring(0, splitKey[0].length - 1)).getDay();
        if (weekDay == 0) {
          weekDay = 6;
        } else {
          weekDay -= 1;
        }
        weekDayUsageA2[weekDay].r += diagramData[2][key];

        // Third graph Dataset
       
        let month = new Date(splitKey[0].substring(0, splitKey[0].length - 1)).getMonth();
        monthUsageA2[month] += diagramData[2][key];
      }
*/
      let weekDayUsageValuesPercent = [];
      for (let i = 1; i <= mapContext.state.compareLayers.length; i++) {
        weekDayUsageValuesPercent.push(structuredClone(weekDayUsage[i-1]));
        
        for (let j = 0; j < 7; j++) {
          weekDayUsageValuesPercent[i-1][j].r = (weekDayUsageValuesPercent[i-1][j].r / totalData[i-1]).toFixed(4) * 100;
          weekDayUsage[i-1][j].r = (weekDayUsage[i-1][j].r / totalData[i-1]).toFixed(4) * 15;
          //weekDayUsage[i-1][j].r = (weekDayUsage[i-1][j].r / totalData[i-1]).toFixed(4) * 35;
          /*if (weekDayUsage[i-1][j].r > 10 + 2.5 * (4 - mapContext.state.compareLayers.length) ) {
            weekDayUsage[i-1][j].r = 10 + 2.5 * (4 - mapContext.state.compareLayers.length)
          }*/
          if (weekDayUsage[i-1][j].r > 12.5 ) {
            weekDayUsage[i-1][j].r = 12.5
          }

        }
      }
      /*
      let weekDayUsageValuesPercentA1 = structuredClone(weekDayUsageA1) ;
      let weekDayUsageValuesPercentA2 = structuredClone(weekDayUsageA2) ;
      
      for (let i = 0; i < 7; i++) {
        weekDayUsageValuesPercentA1[i].r = (weekDayUsageValuesPercentA1[i].r / totalDataA1).toFixed(4) * 100;
        weekDayUsageA1[i].r = (weekDayUsageA1[i].r / totalDataA1).toFixed(4) * 35;
        if (weekDayUsageA1[i].r > 25) {
          weekDayUsageA1[i].r = 25
        }

        weekDayUsageValuesPercentA2[i].r = (weekDayUsageValuesPercentA2[i].r / totalDataA2).toFixed(4) * 100;
        weekDayUsageA2[i].r = (weekDayUsageA2[i].r / totalDataA2).toFixed(4) * 35;
        if (weekDayUsageA2[i].r > 25) {
          weekDayUsageA2[i].r = 25
        }
      }
      */

      const labels = ["   P", "A", "T", "K", "Pn", "Š", "S"];
      //console.log(weekDayUsage)


      
      let colours = ["rgb(138, 188, 0)","rgb(65, 143, 67)","rgb(20, 156, 143)","rgb(31, 95, 91)"]

      let dataSetsHour = []
      let dataSetWeek = []
      let dataSetMonth = []

      //LABELS

      //let labelsTr = ["automobilis", "dviratis", "autobusas", "pestysis"]
      //let transportSymbol = ["D ", "A ", "VT ", "P "]
      let labelsTr = []
      let transportSymbol = []
      
      for (let i = 1; i <= mapContext.state.compareLayers.length; i++) {
        console.log(mapContext.state.compareLayers[i])
        if (mapContext.state.compareLayers[i-1].layerId === 101) {
          labelsTr.push("Automobilis")
          transportSymbol.push("A")
        }
        if (mapContext.state.compareLayers[i-1].layerId === 102) {
          labelsTr.push("Dviratis")
          transportSymbol.push("D")
        }
        if (mapContext.state.compareLayers[i-1].layerId === 104) {
          labelsTr.push("Viešasis transportas")
          transportSymbol.push("VT")
        }
        if (mapContext.state.compareLayers[i-1].layerId === 103) {
          labelsTr.push("Pestysis")
          transportSymbol.push("P")
        }
      }

      //LABELS END

      let sizeCof = size ? 2 : 1
      for (let i = 1; i <= mapContext.state.compareLayers.length; i++) {
        let dataSetHourSingle = {
          label: labelsTr[i-1],
          backgroundColor: colours[i-1],
          //data: Object.values(data[2]),
          data: dataSet[i-1],
          borderWidth: 1,
          barThickness: 12 * sizeCof / mapContext.state.compareLayers.length,
        }
        dataSetsHour.push(dataSetHourSingle)

        let dataSetWeekSingle = {           
          backgroundColor: colours[i-1],
          data: weekDayUsage[i-1],
          radius: 1,           
        }
        dataSetWeek.push(dataSetWeekSingle)

        let dataSetMonthSingle =  {
          backgroundColor: colours[i-1],
          
          label: labelsTr[i-1],
          //data: Object.values(data[2]),
          data: monthUsage[i-1],
          borderWidth: 1,
          barThickness: 6 * sizeCof,
        }
        dataSetMonth.push(dataSetMonthSingle)
      }


      
      var myChart = new Chart(diagram.current, {
        type: "bar",
        data: {
          labels: datasetLabel,
          //labels: Object.keys(data[2]),
          datasets: dataSetsHour,
        },
        
        options: {
          plugins: {
            legend: {
              display: false,
            },
          },
          maintainAspectRatio: false,
          scales: {
            y: {
              type: chartType,
              grid: { color: "#EFEFEF" },
              ticks: {
                font: {
                  weight: 600,
                  family: 'Poppins'
                },
                color:  "#EFEFEF"
              },
              beginAtZero: true,
            },
            x: {
              ticks:{
                font: {
                  weight: 600,
                  family: 'Poppins'
                },
                color: "#EFEFEF"
              }
            }
          },
        },
      });

      let yAxisTixcks = []
      for (let i = 1; i <= mapContext.state.compareLayers.length; i++) {
        yAxisTixcks.push(30 + (i -1) * 25)
      }
      let yAxisMax = yAxisTixcks[yAxisTixcks.length - 1] + 20
      
      document.getElementById('analyticsChartWeekContainer').style.height = (150 + (75 * (mapContext.state.compareLayers.length -2) ) ) + 'px'
      var secondChart = new Chart(bubbleDiagram.current, {
        type: "bubble",
        data: {
          datasets: dataSetWeek,
        },
        options: {
          maintainAspectRatio: false,
          events: [],
          plugins: {
            chartAreaBorder: {
              borderColor: 'white',
              borderWidth: 2
            },

            legend: {
              display: false,
            },
          },
          scales: {
            y: {
              display: true,
              grid: {
                display: false
              },
              border: {
                display: false
              },
              min: 0,
              max: yAxisMax,
              
              afterBuildTicks: (axis) => (axis.ticks = yAxisTixcks.map((vx) => ({ value: vx }))),
              
              ticks: {
                font: {
                  size: 12,
                  weight: 600,
                  family: 'Poppins'
                },
                maxRotation: 90,
                minRotation: 90,
                color: "#EFEFEF",
                callback: function (val, index) {
                  // Hide every 2nd tick label

                    if (index === 0 ) {
                      return[transportSymbol[index]]
                    } else if (index === 1) {
                      return[transportSymbol[index]]
                    } else if (index === 2) {
                      return[transportSymbol[index]]
                    } else if (index === 3) {
                      return[transportSymbol[index]]
                    }

                  
                  
                }
              }
              
            },
            x: {
              border: {
                display: true
              },
              grid: {
                display: false
              },
              display: true,
              min: 0,
              max: 800,
              afterBuildTicks: (axis) => (axis.ticks = [100, 200, 300, 400, 500, 600, 700].map((v) => ({ value: v }))),
              ticks: {
                font: {
                  weight: 600,
                  family: 'Poppins'
                },
                
                maxRotation: 0,
                minRotation: 0,
                color: "#EFEFEF",
                // For a category axis, the val is the index so the lookup via getLabelForValue is needed
                callback: function (val, index) {
                  // Hide every 2nd tick label
                  let retArr = []
                  
                  
                  
                  for (let i = 1; i <= mapContext.state.compareLayers.length; i++) {
                    let innerArr = []
                    for (let j = 0; j < 7; j++) {
                      if (j === 0) {
                        innerArr.push(transportSymbol[i-1] + " " + weekDayUsageValuesPercent[i-1][j].r.toFixed(0) + "%") 
                      } else {
                        innerArr.push(weekDayUsageValuesPercent[i-1][j].r.toFixed(0) + "%") 
                      }
                    }
                    retArr.push(innerArr)
                  }
                  console.log(retArr)
                  if (mapContext.state.compareLayers.length === 4) {
                    return [labels[index], retArr[0][index], retArr[1][index], retArr[2][index], retArr[3][index]];
                  } else if (mapContext.state.compareLayers.length === 3) {
                    return [labels[index], retArr[0][index], retArr[1][index], retArr[2][index]];
                  } else {
                    return [labels[index], retArr[0][index], retArr[1][index]];
                  }
                  
               
                },
              },
              
            },
          },
        },
        plugins: [chartAreaBorder]
      });
      
     
      var monthChart = new Chart(monthDiagram.current, {
        type: "bar",
        data: {
          labels: monthLabels,
          //labels: Object.keys(data[2]),
          datasets: dataSetMonth,
        },
        options: {
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false,
            },
          },
          scales: {
            y: {
              type: chartType,
              grid: { color: "#EFEFEF" },
              ticks:{
                font: {
                  weight: 600,
                  family: 'Poppins'
                },
                color: "#EFEFEF"
              },
              beginAtZero: true,
            },
            
            x:{
              
              ticks:{
                font: {
                  weight: 600,
                  family: 'Poppins'
                },
                color: "#EFEFEF"
              }
            }
          },
        },
      });
    
      mapContext.dispatch({
        sideBarRequestPending: 0,
      });
    return [myChart,secondChart,monthChart] /*() => {
      myChart.destroy();
      secondChart.destroy();
      monthChart.destroy(); 
    };*/
  }
    

  

  waitForElm('.ClearBtn').then((button) => {
    button.forEach(element => {
        element.addEventListener("click",function(){
          button.forEach(nav => nav.classList.remove("Active"))
          this.classList.add("Active")
        })
      })
  });

  waitForElm('.GreyCard').then((button) => {
    button.forEach(element => {
        element.addEventListener("click",function(){
          button.forEach(nav => nav.classList.remove("Active"))
          this.classList.add("Active")
        })
      })
  });



  useEffect(() => {
    if ( mapContext.state.clearFlag === 1 ){
      mapContext.state.mapView.map.layers.items[0].graphics.removeAll()
      setDiagramData([null,null,null])
    }
    if (mapContext.state.compareTask === 2) {
      mapContext.dispatch({
        compareDate: 0,
      })
      mapContext.dispatch({
        dateRange: 0,
      })
    } else {
      mapContext.dispatch({
        dateRange: 0,
      })
    }
  }, [mapContext.state.clearFlag])

  useEffect(() => {
    console.log(data)
    if (initialRender === 0) {
      return
    }
    
    //"datetime >= timestamp'04/29/2023 23:59:59' and datetime < timestamp'05/30/2023 23:59:59'"
    /*CASES - add a1, on 0
              add a2 on 0
              remove a1 from a1
              remove a2 from a2
              add a1 on a2
              add a2 on a1
              remove a1 from a3
              remove a2 from a3
              mapContext.state.mapView.graphics.remove(response.results[0].graphic);
              */
    if (data === null || data.results.length < 2 ||  mapContext.state.compareTask < 10 || mapContext.state.dateRange == null) {
      if (mapContext.state.compareTask !== 2 || mapContext.state.compareDate === null) {
        return
      } else {
        mapContext.dispatch({
          sideBarRequestPending: 1,
        });
        if (data.results[0].layer === null) {
          graphicAndProcessDataTimeCompare(data, 1)
        } else {
          graphicAndProcessDataTimeCompare(data, 0)
        }
        return;
      }
      
    } 

    mapContext.dispatch({
      sideBarRequestPending: 1,
    });

    var subtaskLocal = Math.floor(mapContext.state.compareTask / 10)
    if (data.results[0].layer === null){
      if (data.results[0].graphic.attributes.lengthTaskNr === 2 && subtaskLocal === 1){
        graphicAndProcessedData(data, subtaskLocal, 1, 0)
      } else if (data.results[0].graphic.attributes.lengthTaskNr === 1 && subtaskLocal === 2) {
        graphicAndProcessedData(data, subtaskLocal, 1, 0)
      } else if (data.results[0].graphic.attributes.lengthTaskNr === 3) {
        graphicAndProcessedData(data, subtaskLocal, 1, 1)
      } else if (data.results[0].graphic.attributes.lengthTaskNr === subtaskLocal) {
        graphicAndProcessedData(data, subtaskLocal, 0, 1)
      }
    } else {
      graphicAndProcessedData(data, subtaskLocal, 0, 0)
    }
  },[data])

  useEffect(() => {
    console.log(mapContext.state.selectedStreetArray)
  },[mapContext.state.selectedStreetArray])

  useEffect(() => {
    if (initialRender === 0) {
      return
    }
    if (diagramData[1] === null || diagramData[2] === null || mapContext.state.compareTask !== 2) {
      return
    }

    let l1Query
    let l2Query
    console.log(mapContext.state.selectedStreetArray[0])
    if (mapContext.state.selectedStreetArray.length > 1) {
      l1Query = createQuery(mapContext.state.selectedStreetArray[0])
      l2Query = createQuery(mapContext.state.selectedStreetArray[0], 1)
      console.log(l1Query.where)
      console.log(l2Query.where)
   } else {
     return;
   }

   let diagramLocalDateChange = []

   queryFeaturesOverLimitWrapper(mapContext.state.dataFeatureLayer, l1Query).then((features) => {
    if (features.length === 0) {

      mapContext.dispatch({
        sideBarRequestPending: 0,
      });
      return
    }

    console.log("firstQ", features)
     let firstDiag = processQueryResults([], features, 0)
     console.log("firstDiag", firstDiag)

     queryFeaturesOverLimitWrapper(mapContext.state.dataFeatureLayer, l2Query).then((features) => {
      if (features.length === 0) {

        mapContext.dispatch({
          sideBarRequestPending: 0,
        });
        return
      }
      console.log("2Q", features)
       let secondDiag = processQueryResults([], features, 0)
       let flag
       console.log("secondDiag", secondDiag)
       if (diagramData[0] !== null && diagramData[0] !== undefined) {
         flag = !diagramData[0];
       } else {
          flag = false
       }
       console.log("Diagram after date change" ,firstDiag, secondDiag, flag)
       setDiagramData([flag, firstDiag, secondDiag])

     })


   })


  },[mapContext.state.dateRange, mapContext.state.compareDate])

  useEffect(() => {
    if (initialRender === 0) {
      return
    }
    // Sudet street array ui i global idus grafai ruost
    // Nieko nekeist graphic'e
    if (diagramData[1] === null || diagramData[2] === null || mapContext.state.compareTask === 2) {
      return
    }

    console.log(diagramData)
    let a1Query
    let a2Query
    if (mapContext.state.selectedStreetArray.length > 1) {
       a1Query = createQuery(mapContext.state.selectedStreetArray[0])
       a2Query = createQuery(mapContext.state.selectedStreetArray[1])
       console.log(a1Query)
       console.log(a2Query)
    } else {
      return;
    }

    let diagramLocalDateChange = []

    queryFeaturesOverLimitWrapper(mapContext.state.dataFeatureLayer, a1Query).then((features) => {
      if (features.length === 0) {

        mapContext.dispatch({
          sideBarRequestPending: 0,
        });
        return
      }
      let firstDiag = processQueryResults(diagramLocalDateChange, features, 0)
      console.log("firstDiag", firstDiag)

      queryFeaturesOverLimitWrapper(mapContext.state.dataFeatureLayer, a2Query).then((features) => {
        if (features.length === 0) {

          mapContext.dispatch({
            sideBarRequestPending: 0,
          });
          return
        }
        diagramLocalDateChange = []
        let secondDiag = processQueryResults(diagramLocalDateChange, features, 0)
        let flag
        console.log("secondDiag", secondDiag)
        if (diagramData[0] !== null && diagramData[0] !== undefined) {
          flag = !diagramData[0];
        } else {
           flag = false
        }
        console.log("Diagram after date change" ,firstDiag, secondDiag, flag)
        setDiagramData([flag, firstDiag, secondDiag])

      })


    })
   
    

  }, [mapContext.state.dateRange])

  useEffect(() => {
    console.log(diagramData)
  },[diagramData])

  useEffect(() => {
    if (initialRender === 0) {
      return
    }
    console.log("Redraw")
    console.log(diagramData)
    if (diagramData[2] == null || diagramData[1] == null) {
      return;
    } else {
      if (mapContext.state.compareTask === 3) {
        let charts  = drawDiagMulti()
        return () => {
          charts[0].destroy();
          charts[1].destroy();
          charts[2].destroy();
        }
      }

      let totalDataA1 = 0;
      let totalDataA2 = 0;
      let datasetLabel = [];
      for (let i = 0; i < 24; i++) {
        datasetLabel.push(i + ":00");
      }

      let dataSetA1 = [];
      for (let i = 0; i < 24; i++) {
        dataSetA1.push(0);
      }

      let dataSetA2 = [];
      for (let i = 0; i < 24; i++) {
        dataSetA2.push(0);
      }

      let weekDayUsageA1 = [];
      for (let i = 0; i < 7; i++) {
        weekDayUsageA1.push({ x: (i + 1) * 100, y: 75, r: 0 });
      }
      let weekDayUsageA2 = [];
      for (let i = 0; i < 7; i++) {
        weekDayUsageA2.push({ x: (i + 1) * 100, y: 25, r: 0 });
      }

      let monthUsageA1 = [];
      for (let i = 0; i < 12; i++) {
        monthUsageA1.push(0);
      }

      let monthUsageA2 = [];
      for (let i = 0; i < 12; i++) {
        monthUsageA2.push(0);
      }
      let monthLabels = ["Sau", "Vas", "Kov", "Bal", "Geg", "Bir", "Lie", "Rgp", "Rgs", "Spa", "Lap", "Gru"];

      for (let i = 0; i < Object.values(diagramData[1]).length; i++) {
        //First graph
        let key = Object.keys(diagramData[1])[i];
        //console.log(key)
        let char = String.fromCharCode(8239)
        let splitKeyOut = Object.keys(diagramData[1])[i].split(" ");
        let splitKey
        if (splitKeyOut.length === 3) {
          splitKey = Object.keys(diagramData[1])[i].split(" ");
        } else {
          let splitKeyInner = splitKeyOut[1].split(char);
          splitKey = [splitKeyOut[0], splitKeyInner[0],  splitKeyInner[1]]
        }
        
        //console.log(splitKey)

        let hour = parseInt(splitKey[1]);
        if (splitKey[2].includes("PM") && hour != 12) {
          hour += 12;
        } else if (splitKey[2].includes("AM") && hour == 12) {
          hour -= 12;
        }
        dataSetA1[hour] += diagramData[1][key];
        totalDataA1 += diagramData[1][key];
        //Second graph
        let weekDay = new Date(splitKey[0].substring(0, splitKey[0].length - 1)).getDay();
        if (weekDay == 0) {
          weekDay = 6;
        } else {
          weekDay -= 1;
        }
        weekDayUsageA1[weekDay].r += diagramData[1][key];

        // Third graph Dataset
       
        let month = new Date(splitKey[0].substring(0, splitKey[0].length - 1)).getMonth();
        monthUsageA1[month] += diagramData[1][key];
      }

      for (let i = 0; i < Object.values(diagramData[2]).length; i++) {
        //First graph
        let key = Object.keys(diagramData[2])[i];
        //console.log(key)
        let char = String.fromCharCode(8239)
        let splitKeyOut = Object.keys(diagramData[2])[i].split(" ");
        let splitKey
        if (splitKeyOut.length === 3) {
          splitKey = Object.keys(diagramData[2])[i].split(" ");
        } else {
          let splitKeyInner = splitKeyOut[1].split(char);
          splitKey = [splitKeyOut[0], splitKeyInner[0],  splitKeyInner[1]]
        }
        
        //console.log(splitKey)

        let hour = parseInt(splitKey[1]);
        if (splitKey[2].includes("PM") && hour != 12) {
          hour += 12;
        } else if (splitKey[2].includes("AM") && hour == 12) {
          hour -= 12;
        }
        dataSetA2[hour] += diagramData[2][key];
        totalDataA2 += diagramData[2][key];
        //Second graph
        let weekDay = new Date(splitKey[0].substring(0, splitKey[0].length - 1)).getDay();
        if (weekDay == 0) {
          weekDay = 6;
        } else {
          weekDay -= 1;
        }
        weekDayUsageA2[weekDay].r += diagramData[2][key];

        // Third graph Dataset
       
        let month = new Date(splitKey[0].substring(0, splitKey[0].length - 1)).getMonth();
        monthUsageA2[month] += diagramData[2][key];
      }

      let weekDayUsageValuesPercentA1 = structuredClone(weekDayUsageA1) ;
      let weekDayUsageValuesPercentA2 = structuredClone(weekDayUsageA2) ;
      for (let i = 0; i < 7; i++) {
        weekDayUsageValuesPercentA1[i].r = (weekDayUsageValuesPercentA1[i].r / totalDataA1).toFixed(4) * 100;
        weekDayUsageA1[i].r = (weekDayUsageA1[i].r / totalDataA1).toFixed(4) * 35;
        if (weekDayUsageA1[i].r > 25) {
          weekDayUsageA1[i].r = 25
        }

        weekDayUsageValuesPercentA2[i].r = (weekDayUsageValuesPercentA2[i].r / totalDataA2).toFixed(4) * 100;
        weekDayUsageA2[i].r = (weekDayUsageA2[i].r / totalDataA2).toFixed(4) * 35;
        if (weekDayUsageA2[i].r > 25) {
          weekDayUsageA2[i].r = 25
        }
      }

      const labels = ["   P", "A", "T", "K", "Pn", "Š", "S"];
      //console.log(weekDayUsage)
      let label1
      let label2
      if (mapContext.state.compareTask === 3) {
        label1 = "automobilis"
        label2 = "dviratis" 
      } 
      else if (mapContext.state.compareTask === 2) {
        label1 = "laikotarpis 1"
        label2 = "laikotarpis 2" 
      } else {
        label1 = "atkarpa 1"
        label2 = "atkarpa 2"
      }
      var myChart = new Chart(diagram.current, {
        type: "bar",
        data: {
          labels: datasetLabel,
          //labels: Object.keys(data[2]),
          datasets: [
            {
              label: label1,
              backgroundColor: "rgb(85, 255, 255)",
              //data: Object.values(data[2]),
              data: dataSetA1,
              borderWidth: 1,
              barThickness: 6,
            },
            {
              label: label2,
              backgroundColor: "rgb(133, 188, 25)",
              //data: Object.values(data[2]),
              data: dataSetA2,
              borderWidth: 1,
              barThickness: 6,
            },
          ],
        },
        
        options: {
          plugins: {
            legend: {
              display: false,
            },
          },
          maintainAspectRatio: false,
          scales: {
            y: {
              type: chartType,
              grid: { color: "#EFEFEF" },
              ticks: {
                font: {
                  weight: 600,
                  family: 'Poppins'
                },
                color:  "#EFEFEF"
              },
              beginAtZero: true,
            },
            x: {
              ticks:{
                font: {
                  weight: 600,
                  family: 'Poppins'
                },
                color: "#EFEFEF"
              }
            }
          },
        },
      });

      
      var secondChart = new Chart(bubbleDiagram.current, {
        type: "bubble",
        data: {
          datasets: [
            {
              
              backgroundColor: "rgb(85, 255, 255)",
              data: weekDayUsageA1,
              radius: 1,
              
            },
            {
              
              backgroundColor: "rgb(133, 188, 25)",
              data: weekDayUsageA2,
              radius: 1,
              
            },
          ],
        },
        options: {
          maintainAspectRatio: false,
          events: [],
          plugins: {
            chartAreaBorder: {
              borderColor: 'white',
              borderWidth: 2
            },

            legend: {
              display: false,
            },
          },
          scales: {
            y: {
              display: true,
              grid: {
                display: false
              },
              border: {
                display: false
              },
              min: 0,
              max: 100,
              
              afterBuildTicks: (axis) => (axis.ticks = [30, 80].map((vx) => ({ value: vx }))),
              
              ticks: {
                font: {
                  size: 12,
                  weight: 600,
                  family: 'Poppins'
                },
                maxRotation: 90,
                minRotation: 90,
                color: "#EFEFEF",
                callback: function (val, index) {
                  // Hide every 2nd tick label
                  if (mapContext.state.compareTask === 3) {
                    if (index === 0 ) {
                      return["D"]
                    } else if (index === 1) {
                      return["A"]
                    }
                  } 
                  else if (mapContext.state.compareTask === 2) {
                    if (index === 0 ) {
                      return["L2"]
                    } else if (index === 1) {
                      return["L1"]
                    }
                  } else {
                    if (index === 0 ) {
                      return["A2"]
                    } else if (index === 1) {
                      return["A1"]
                    }
                  }
                  
                }
              }
              
            },
            x: {
              border: {
                display: true
              },
              grid: {
                display: false
              },
              display: true,
              min: 0,
              max: 800,
              afterBuildTicks: (axis) => (axis.ticks = [100, 200, 300, 400, 500, 600, 700].map((v) => ({ value: v }))),
              ticks: {
                font: {
                  weight: 600,
                  family: 'Poppins'
                },
                
                maxRotation: 0,
                minRotation: 0,
                color: "#EFEFEF",
                // For a category axis, the val is the index so the lookup via getLabelForValue is needed
                callback: function (val, index) {
                  // Hide every 2nd tick label
                  if (index === 0 ) {
                    if (mapContext.state.compareTask === 3 ) {
                      return [labels[index] ,"A " + weekDayUsageValuesPercentA1[index].r.toFixed(0) + "%","D " +  weekDayUsageValuesPercentA2[index].r.toFixed(0) + "%"];
                    } 
                    else if (mapContext.state.compareTask === 2 ) {
                      return [labels[index] ,"L1 " + weekDayUsageValuesPercentA1[index].r.toFixed(0) + "%","L2 " +  weekDayUsageValuesPercentA2[index].r.toFixed(0) + "%"];
                    } else {
                      return [labels[index] ,"A1 " + weekDayUsageValuesPercentA1[index].r.toFixed(0) + "%","A2 " +  weekDayUsageValuesPercentA2[index].r.toFixed(0) + "%"];
                    }
                    
                  } else {
                    return [labels[index] , weekDayUsageValuesPercentA1[index].r.toFixed(0) + "%",  weekDayUsageValuesPercentA2[index].r.toFixed(0) + "%"];
                  }
                  
                },
              },
              
            },
          },
        },
        plugins: [chartAreaBorder]
      });

      var monthChart = new Chart(monthDiagram.current, {
        type: "bar",
        data: {
          labels: monthLabels,
          //labels: Object.keys(data[2]),
          datasets: [
            {
              backgroundColor: "rgb(85, 255, 255)",
              
              label: label1,
              //data: Object.values(data[2]),
              data: monthUsageA1,
              borderWidth: 1,
              barThickness: 6,
            },
            {
              backgroundColor: "rgb(133, 188, 25)",
              
              label: label2,
              //data: Object.values(data[2]),
              data: monthUsageA2,
              borderWidth: 1,
              barThickness: 6,
            },
          ],
        },
        options: {
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false,
            },
          },
          scales: {
            y: {
              type: chartType,
              grid: { color: "#EFEFEF" },
              ticks:{
                font: {
                  weight: 600,
                  family: 'Poppins'
                },
                color: "#EFEFEF"
              },
              beginAtZero: true,
            },
            
            x:{
              
              ticks:{
                font: {
                  weight: 600,
                  family: 'Poppins'
                },
                color: "#EFEFEF"
              }
            }
          },
        },
      });
      {
    }
    }
    mapContext.dispatch({
      sideBarRequestPending: 0,
    });
    return () => {
      myChart.destroy();
      secondChart.destroy();
      monthChart.destroy();
    };
  }, [diagramData[0], diagramData[1], diagramData[2]]);
  
  useEffect(() => {
    if (mapContext.state.compareTask === 1  || mapContext.state.compareTask === 2) {
      setchartType("linear")
      mapContext.dispatch({
        selectedStreetArray: [],
      });
      mapContext.dispatch({
        dateRange: 0,
      });
      mapContext.dispatch({
        compareDate: 0,
      });
      mapContext.state.mapView.graphics.removeAll();
      setDiagramData([null,null,null])
      mapContext.state.mapView.map.layers.items[0].graphics.removeAll()
    } else if (mapContext.state.compareTask === 3)  {
      setchartType("logarithmic")
    }
    console.log("mapContext.state.mapView.map.layers.items[0].graphics")

    console.log(mapContext.state.mapView.map.layers.items[0].graphics.removeAll())
  },[mapContext.state.compareTask])

  useEffect(() => {
    if(mapContext.state.featureLayer.layerId === 0) {
      mapContext.state.featureLayer.visible = true;
      console.log(mapContext.state.featureLayer)
      if (mapContext.state.compareTask!== 3) {
        for (let i = 0; i < mapContext.state.mapView.layerViews.items.length; i++){
          if (mapContext.state.mapView.layerViews.items[i].layer.layerId === 0) {
            /*mapContext.state.mapView.layerViews.items[i].filter = {
              where: "highway IN ('motorway',  'trunk',  'primary',  'secondary',  'tertiary',  'unclassified',  'residential',  'service',  'motorway_link',  'trunk_link',  'primary_link',  'secondary_link',  'motorway_junction', 'living_street', 'tertiary_link', 'living_street')"
            };
            console.log( "mapContext.state.mapView.layerViews.items[i].filter Removed")
            console.log( mapContext.state.mapView.layerViews.items[i].filter)*/
          }
        }
      }
      
    }
  },[mapContext.state.featureLayer])
  

  useEffect(() => {
    mapContext.dispatch({
      selectedStreetArray: [],
    });
    mapContext.dispatch({
      dateRange: 0,
    });
    mapContext.state.mapView.graphics.removeAll();
    setDiagramData([null,null,null])
    console.log("A")
    //let layerGraphics = new GraphicsLayer();
    //console.log(mapContext.state.mapView.map)
    //mapContext.state.mapView.map.add(layerGraphics)
    //console.log(layerGraphics)

    const polygonSymbol1 = {
      type: "simple-fill", // autocasts as new SimpleFillSymbol()
      color: [ 85, 255, 255, 0.33 ],
      outline: {
        // autocasts as new SimpleLineSymbol()
        color: "#55ffff",
        width: 3
      }
    };



    let sketchVM1 = new SketchViewModel({
      layer: mapContext.state.mapView.map.layers.items[0],
      view: mapContext.state.mapView,
      polygonSymbol: polygonSymbol1,
    });



    console.log("sketchVM")
    //console.log(sketchVM)
    console.log(mapContext.state)
    setSketchViewState(sketchVM1)
    setInitialRender(1);
    console.log(mapContext.state.mapView.map.layers.items)
    changeTask(1);
  },[])

  return (
    <>
    <div className="GreyCardWrapper" id="CompareCardWrapper">
      <button className="GreyCard LeftmostCard Active" onClick={() => {changeTask(1)}}>Atkarpa</button>
      <button className="GreyCard" onClick={() => {changeTask(2)}}>Laikas</button>
      <button className="GreyCard" onClick={() => {changeTask(3)}}>Teritorija</button>
      <button className="GreyCard" id="GreyCardContext" onClick={() => {showContextData(true)}}>Kontekstiniai Duomenys</button>
    </div>
    { mapContext.state.contextDataVisible === false ?
    <div className="Sidebar" id="AnalyticsSidebar">
      
      
      {mapContext.state.compareTask === 2 ? 
      <>
      <Calendar token={token} monthWeekDay= {false} dispatcher={0} headerText={"Pasirinkite laikotarpį"} colorScheme={"Blue Inliner"} inlineBool={false}></Calendar>
      </>
      : 
      <Calendar token={token} monthWeekDay= {false} dispatcher={0} headerText={"Pasirinkite laikotarpį"} colorScheme={""} inlineBool={true}></Calendar>
      }
      <br/>
      
      { (mapContext.state.compareTask % 10) === 1 ? <>
        <button className="ClearBtn LengthBtn MarginBtn" onClick={() => {dispatchTask(11)}}>Atkarpa 1</button>
        <button className="ClearBtn LengthBtn MarginBtn" onClick={() => {dispatchTask(21)}}>Atkarpa 2</button>
        <br/>
      </> : (mapContext.state.compareTask % 10) === 2  ? <>
      <Calendar token={token} monthWeekDay= {false} dispatcher={1} headerText={"Pasirinkite laikotarpį palyginimui"} colorScheme={mapContext.state.compareTask === 2 ? " Inliner" : ""} inlineBool={mapContext.state.compareTask === 2 ? false : true}></Calendar>
      <br></br>
      </> : <></>}

      {
        (mapContext.state.compareTask === 3 && mapContext.state.compareLayers.length >=2 && mapContext.state.dateRange !== null) ?
        <>
        <button className="ClearBtn LengthBtn MarginBtn" id="Teritory1" onClick={() => {sketchRect()}}>Teritorija</button>
        <br/>
        </> 
        :
        <></>
      }

      { mapContext.state.sideBarRequestPending === 1  ? (
        <div class="loader loaderCentered"></div>
      ) 
        : (mapContext.state.dateRange === null && mapContext.state.compareTask === 3) ? <>Pasirinkite data palyginimui</>
        : (mapContext.state.compareLayers.length <2 && mapContext.state.compareTask === 3) ? <>Pasirinkite sluoksni palyginimui</> :  (diagramData[1] === null && diagramData[2] === null && mapContext.state.compareTask === 3) ? <>Pasirinkite teritorija</>
        : (mapContext.state.dateRange === null && mapContext.state.compareTask === 2) ? <>Pasirinkite pirmą datą palyginimui</> 
        : (mapContext.state.compareDate === null && mapContext.state.compareTask === 2) ? <>Pasirinkite antra datą palyginimui</>
        : (diagramData[1] === null && diagramData[2] === null && mapContext.state.compareTask === 2) ? <>Pasirinkite atkarpą</>
        : diagramData[1] === null ? <>Pasirinkite pirmą atkarpą</> :  diagramData[2] === null ? <>Pasirinkite antrą atkarpą</> 
        
        : 
      <>
          <div className="block diagram">
            <canvas id="analyticsChart" ref={diagram}></canvas>
          </div>
          <div className="block diagram" id="analyticsChartWeekContainer">
            <canvas id="analyticsChartWeek" ref={bubbleDiagram}></canvas>
          </div>
          <div className="block diagram">
            <canvas id="analyticsChartMonth" ref={monthDiagram}></canvas>
            
          </div>
          <ExcelExport/>
          <button className="NonActive LengthBtn MarginBtn" id="ChangeSize" onClick={() => {changeSize()}}>{size ? "Sumažinti" : "Padidinti"}</button>
      </>
      }
      <br/>
      
    </div>
    :
    <div id={"SidebarGridSidebar"} className={"MenuColor"}>
        <ContextLayers></ContextLayers>
    </div>
    }
    </>
  );
}
