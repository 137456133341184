import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { createRoot } from 'react-dom/client';
import './index.css';
import Appv2 from './Appv2';
import { MapProvider } from './MapContext';
import Login from './Login';
import Auth from './auth/auth';


const container = document.getElementById('root')
const root = createRoot(container);
root.render(<MapProvider>    <BrowserRouter>
    <Routes>
      <Route element={<Auth/>} path="/"/>
    </Routes>
  </BrowserRouter></MapProvider>);

