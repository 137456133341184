import React, { useContext, useEffect } from "react";
import "../widgets/widgetcss/styles.css";
import { MapContext } from "../../../../MapContext";
import "../../../../Judumas.css"

export default function ZoomAndQuery({customString = "Nėra parinktų gatvių"}) {
  const mapContext = useContext(MapContext);

  function dispatchQuery() {
    if (mapContext.state.dateRange !== null) {
      mapContext.dispatch({
        clearFlag: 1,
      });
      
    } else if (mapContext.state.compareTask === 2) {
      mapContext.dispatch({
        clearFlag: 1,
      });
    }
  }

  function waitForElm(selector) {
    return new Promise((resolve) => {
      if (document.querySelector(selector)) {
        return resolve(document.querySelectorAll(selector));
      }

      const observer = new MutationObserver((mutations) => {
        if (document.querySelector(selector)) {
          resolve(document.querySelectorAll(selector));
          observer.disconnect();
        }
      });

      observer.observe(document.body, {
        childList: true,
        subtree: true,
      });
    });
  }

  useEffect(() => {
    console.log("CLEAR")
    if (mapContext.state.clearFlag === 1) {
      return;
    }
    waitForElm(".ready1").then((button) => {
      button.forEach((nav) => nav.classList.remove("active"));
    });
  }, [mapContext.state.clearFlag]);

  return (
    <div className="Footer">
      <div className="">
        {mapContext.state.selectedStreetArray.length == 0 && mapContext.state.compareDate === null && mapContext.state.dateRange === null ? (
          <button className="ClearBtn Disabled" disabled onClick={() => {}}>
            {customString}
          </button>
        ) : (
          <button
            className="ClearBtn"
            onClick={() => {
              dispatchQuery();
            }}
          >
            Išvalyti pasirinkimą
          </button>
        )}
      </div>
    </div>
  );
}
